package tta.destinigo.talktoastro.feature_winyway_wallet.persentation.recharge_checkout.widget

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.material3.Checkbox
import androidx.compose.material3.CheckboxDefaults
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.drawWithContent
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import tta.destinigo.talktoastro.resources.Res
import org.jetbrains.compose.resources.painterResource
import tta.destinigo.talktoastro.core.local.UserInfoProvider.getReferralCode
import tta.destinigo.talktoastro.core.local.UserInfoProvider.getTotalCoin
import tta.destinigo.talktoastro.core.persentation.showToast
import tta.destinigo.talktoastro.core.theme.primaryColor
import tta.destinigo.talktoastro.resources.icon_logo

@Composable
fun RechargeCheckoutScreen(onCoinClick:(Boolean) ->Unit) {
    val isChecked = remember { mutableStateOf(false) }
    val coin = getTotalCoin()
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .background(Color.White)
            .padding(vertical = 10.dp)
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier
                .fillMaxWidth()
                .padding(start = 10.dp)
        ) {
            Image(
                painter = painterResource(Res.drawable.icon_logo),
                contentDescription = null,
                modifier = Modifier.size(22.dp)
            )
            Spacer(modifier = Modifier.width(4.dp))
            Text(
                text = "TalkToAstro Coins",
                style = MaterialTheme.typography.titleSmall.copy(
                    fontWeight = FontWeight.Bold,
                    color = Color.Black
                )
            )
        }

        Spacer(modifier = Modifier.height(4.dp))

        Row(
            modifier = Modifier
                .fillMaxWidth(),
               // .padding(horizontal = 32.dp),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.SpaceBetween
        ) {
            CheckBoxWithText(
                isChecked = isChecked.value,
                text = "Redeem $coin Coins",
                onCheckedChange = {
                    isChecked.value = !isChecked.value
                    onCoinClick(isChecked.value)
                    //showToast("Check Box Click")
                }
            )
            Column {
                Text(
                    text = "Balance",
                    style = MaterialTheme.typography.bodySmall.copy(
                        color = Color.Black.copy(alpha = 0.5f)
                    )
                )
                Text(
                    text = coin.toString(),
                    style = MaterialTheme.typography.bodyMedium.copy(color = Color.Black),
                    modifier = Modifier.drawWithContent {
                        drawContent()
                        //drawInlineContent("icon", 0.dp)
                    }
                )
            }

        }

        HorizontalDivider(
            modifier = Modifier
                .padding(horizontal = 25.dp)
                //.padding(horizontal = 32.dp)
                .fillMaxWidth(),
            color = Color.Gray.copy(alpha = 0.5f)
        )

        Text(
            text = "You can use 100% of coins to your purchase",
            style = MaterialTheme.typography.bodySmall.copy(
                color = Color.Black.copy(alpha = 0.5f)
            ),
            modifier = Modifier
                .padding(start = 32.dp, top = 8.dp)
        )
    }
}

@Composable
fun CheckBoxWithText(isChecked: Boolean, text: String, onCheckedChange: (Boolean) -> Unit) {
    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = Modifier.padding(start = 10.dp, top = 4.dp)
       // modifier = Modifier.padding(start = 30.dp, top = 4.dp)
    ) {
        Checkbox(
            checked = isChecked,
            onCheckedChange = onCheckedChange,
            colors = CheckboxDefaults.colors(checkedColor = primaryColor)
        )
        Spacer(modifier = Modifier.width(4.dp))
        Text(
            text = text,
            style = MaterialTheme.typography.bodySmall.copy(color = Color.Black)
        )
    }
}
