package tta.destinigo.talktoastro.feature_courses.data.response.coupon

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import tta.destinigo.talktoastro.core.data.CommonResponse

@Serializable
data class ValidateCouponResponse(
    @SerialName("data") val data: ValidateCouponData?,
):CommonResponse()

@Serializable
data class ValidateCouponData(
    @SerialName("cashback") val cashback: String?,
    @SerialName("coupon_id") val couponId: Int?,
    @SerialName("coupon_code") val couponCode: String?
)