package tta.destinigo.talktoastro.feature_common.persentation.more_tab.transaction_history.persentation.widget

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.layout.wrapContentSize
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Divider
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.platform.LocalClipboardManager
import androidx.compose.ui.text.AnnotatedString
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import org.jetbrains.compose.resources.painterResource
import tta.destinigo.talktoastro.core.persentation.CopyableTransactionIdWithIcon
import tta.destinigo.talktoastro.core.persentation.SupportButton
import tta.destinigo.talktoastro.core.theme.Scale
import tta.destinigo.talktoastro.core.theme.background_color
import tta.destinigo.talktoastro.core.theme.initializeCurrency
import tta.destinigo.talktoastro.core.theme.primaryColor
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.transaction_history.persentation.convertToIndianTime
import tta.destinigo.talktoastro.resources.Res
import tta.destinigo.talktoastro.resources.purchase_histor_bag

@Composable
fun CustomOrderDetailsCard(
    orderNumber: String = "Order #1",
    orderDescription: String = "Custom Order Purchase",
    orderDate: String = "00 00 0000",
    customOrderPrice: String = "00",
    billedAmount: String = "00",
    transactionId: String = "00",
    modifier: Modifier = Modifier
) {
    Column(
        modifier = modifier
            .fillMaxWidth()
            .background(Color.White)
            .padding(16.dp),
        verticalArrangement = Arrangement.spacedBy(16.dp)
    ) {
        // Order Summary Card
        Card(
            modifier = Modifier.fillMaxWidth().background(Color.White),
            //elevation = CardDefaults.cardElevation(defaultElevation = 4.dp),
            shape = RoundedCornerShape(16.dp)
        ) {
            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .background(Color.White)
                    .padding(10.dp),
                verticalArrangement = Arrangement.spacedBy(16.dp)
            ) {
                Row(
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.Start
                ) {
                    Box(
                        modifier = Modifier
                            .size(50.dp)
                            .background(
                                color = MaterialTheme.colorScheme.primary.copy(alpha = 0.1f),
                                shape = CircleShape
                            ),
                        contentAlignment = Alignment.Center
                    ) {
                        Icon(
                            painter = painterResource(Res.drawable.purchase_histor_bag),
                            contentDescription = "Order Icon",
                            tint = MaterialTheme.colorScheme.primary,
                            modifier = Modifier.size(28.dp)
                        )
                    }

                    Spacer(modifier = Modifier.width(16.dp))

                    Column {
                        Text(
                            text = orderNumber,
                            style = MaterialTheme.typography.titleMedium,
                            color = MaterialTheme.colorScheme.onSurface
                        )
                        Text(
                            text = orderDescription,
                            style = MaterialTheme.typography.bodySmall,
                            color = MaterialTheme.colorScheme.onSurfaceVariant
                        )
                    }
                }

                Divider(color = MaterialTheme.colorScheme.onSurface.copy(alpha = 0.2f), thickness = 1.dp)

                Column(verticalArrangement = Arrangement.spacedBy(12.dp)) {
                    CustomOrderDetailRow("Custom Order Price", customOrderPrice)
                    CustomOrderDetailRow("Order Date", orderDate)
                    CustomOrderDetailRow("Billed Amount", billedAmount)
                }
            }
        }

        Spacer(modifier = Modifier.height(16.dp))

        // Support Button
        SupportButton(onClick = { /* TODO: Handle Support Action */ })


        // Transaction ID Copy Feature
        val clipboardManager = LocalClipboardManager.current
        CopyableTransactionIdWithIcon(transactionId) { txnId ->
            clipboardManager.setText(AnnotatedString(txnId))
        }

       // Spacer(modifier = Modifier.height(4.dp))

        // Order Date Display
        Text(
            modifier = Modifier.fillMaxWidth(),
            text = orderDate,
            style = MaterialTheme.typography.bodySmall,
            textAlign = TextAlign.Center,
            color = MaterialTheme.colorScheme.onSurfaceVariant
        )
    }
}

//@Composable
//fun CustomOrderDetailRow(label: String, value: String) {
//    Row(
//        modifier = Modifier.fillMaxWidth(),
//        horizontalArrangement = Arrangement.SpaceBetween,
//        verticalAlignment = Alignment.CenterVertically
//    ) {
//        Text(
//            text = label,
//            style = MaterialTheme.typography.bodyMedium,
//            color = MaterialTheme.colorScheme.onSurface
//        )
//
//        if(label.equals("Order Date", true)){
//            Text(
//                text = if (value.equals("null", true) || value.equals("0", ignoreCase = true)) "FREE" else value,
//                style = MaterialTheme.typography.labelMedium.copy(
//                    fontWeight = FontWeight.SemiBold,
//                    fontSize = 12.sp
//                ),
//                color = if (value.equals("null", true) || value.equals("0", ignoreCase = true)) MaterialTheme.colorScheme.error else MaterialTheme.colorScheme.primary,
//                modifier = Modifier
//                    .background(
//                        color = if (value.equals("null", true) || value.equals("0", ignoreCase = true)) MaterialTheme.colorScheme.error.copy(alpha = 0.2f) else Color.Transparent,
//                        shape = RoundedCornerShape(8.dp)
//                    )
//                    .padding(horizontal = 8.dp, vertical = 4.dp)
//            )
//        }else{
//            Text(
//                text = if (value.equals("null", true) || value.equals("0", ignoreCase = true)) "FREE" else "${initializeCurrency()} $value",
//                style = MaterialTheme.typography.labelMedium.copy(
//                    fontWeight = FontWeight.SemiBold,
//                    fontSize = 12.sp
//                ),
//                color = if (value.equals("null", true) || value.equals("0", ignoreCase = true)) MaterialTheme.colorScheme.error else MaterialTheme.colorScheme.primary,
//                modifier = Modifier
//                    .background(
//                        color = if (value.equals("null", true) || value.equals("0", ignoreCase = true)) MaterialTheme.colorScheme.error.copy(alpha = 0.2f) else Color.Transparent,
//                        shape = RoundedCornerShape(8.dp)
//                    )
//                    .padding(horizontal = 8.dp, vertical = 4.dp)
//            )
//        }
//
//
//    }
//}


@Composable
fun CustomOrderDetailRow(label: String, value: String) {
    val isValueNullOrZero = value.equals("null", ignoreCase = true) || value.equals("0", ignoreCase = true)
    val displayText = if (isValueNullOrZero) "FREE" else if (label.equals("Order Date", true)) value else "${initializeCurrency()} $value"
    val textColor = if (isValueNullOrZero) MaterialTheme.colorScheme.error else MaterialTheme.colorScheme.primary
    val backgroundColor = if (isValueNullOrZero) MaterialTheme.colorScheme.error.copy(alpha = 0.2f) else Color.Transparent

    Row(
        modifier = Modifier.fillMaxWidth(),
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically
    ) {
        Text(
            text = label,
            style = MaterialTheme.typography.bodyMedium,
            color = MaterialTheme.colorScheme.onSurface
        )
        Text(
            text = displayText,
            style = MaterialTheme.typography.labelMedium.copy(
                fontWeight = FontWeight.SemiBold,
                fontSize = 12.sp
            ),
            color = textColor,
            modifier = Modifier
                .background(color = backgroundColor, shape = RoundedCornerShape(8.dp))
                .padding(horizontal = 8.dp, vertical = 4.dp)
        )
    }
}



