package tta.destinigo.talktoastro.core.local

import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import tta.destinigo.talktoastro.core.persentation.settings
import tta.destinigo.talktoastro.core.theme.refreshLoginAndMobileCode
import tta.destinigo.talktoastro.feature_custom_order.custom_order_details.widget.CourseScreen
import tta.destinigo.talktoastro.feature_custom_order.custom_order_details.widget.CourseScreenMobile

object UserInfoProvider {

    private const val USER_FULL_NAME = "user_full_name"
    private const val USER_EMAIL = "user_email"
    private const val USER_PHONE = "user_phone"
    private const val USER_IMAGE = "user_image"
    private const val USER_ID = "user_id"
    private const val MOBILE_CODE = "mobile_code"
    private const val REFERRAL_CODE = "referral_code"
    private const val IS_LOGIN = "is_user_login"
    private const val TOTAL_COIN = "total_coin"
    private const val TOTAL_WALLET_MONEY = "total_wallet_money"


    fun setFullName(name: String) {
        settings.putString(USER_FULL_NAME, name)
    }

    fun getFullName(): String {
        return settings.getString(USER_FULL_NAME, "")
    }

    fun setEmail(email: String) {
        settings.putString(USER_EMAIL, email)
    }

    fun getEmail(): String {
        return settings.getString(USER_EMAIL, "")
    }

    fun setPhone(phone: String) {
        settings.putString(USER_PHONE, phone)
    }

    fun getPhone(): String {
        return settings.getString(USER_PHONE, "")
    }

    fun setImage(image: String) {
        settings.putString(USER_IMAGE, image)
    }

    fun getImage(): String {
        return settings.getString(USER_IMAGE, "")

    }

    fun setId(id: String) {
        settings.putString(USER_ID, id)
    }

    fun getId(): String {
        return settings.getString(USER_ID, "")

    }

    fun setMobileCode(code: String) {
        settings.putString(MOBILE_CODE, code)
        refreshLoginAndMobileCode()

    }

    fun getMobileCode(): String {
        return settings.getString(MOBILE_CODE, "").let {
            if (it.startsWith("+")) it else "+$it"
        }
    }

    fun setReferralCode(code: String) {
        settings.putString(REFERRAL_CODE, code)
    }

    fun getReferralCode(): String {
        return settings.getString(REFERRAL_CODE, "")
    }

    fun setIsLogin(isLogin: Boolean) {
        settings.putBoolean(IS_LOGIN, isLogin)
        refreshLoginAndMobileCode()
    }

    fun getIsLogin(): Boolean {
        return settings.getBoolean(IS_LOGIN, false)
    }

    fun setTotalCoin(coin: Int) {
        settings.putInt(TOTAL_COIN, coin)
    }

    fun getTotalCoin(): Int {
        return settings.getInt(TOTAL_COIN, 0)
    }

    fun setTotalWalletMoney(coin: String) {
        settings.putString(TOTAL_WALLET_MONEY, coin)
    }

    fun getTotalWalletMoney(): String {
        return settings.getString(TOTAL_WALLET_MONEY, "ADD")
    }

    fun clearData() {
        return settings.clear()
    }

}


@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun getPlatformType(): String {
    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    if (windowSizeClass == WindowWidthSizeClass.Compact) {
        return "WEB"
    } else {
        return "APP"
    }
}