package tta.destinigo.talktoastro.feature_call_consultation.data.request

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class GetCallDurationRequest(
    @SerialName("expert_id") val expertId: String?,
    @SerialName("type") val callType: String? = null
)
