package tta.destinigo.talktoastro.feature_authentication.data

import co.touchlab.kermit.Logger
import co.touchlab.kermit.NoTagFormatter
import co.touchlab.kermit.loggerConfigInit
import co.touchlab.kermit.platformLogWriter
import io.ktor.client.call.body
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.request.url
import io.ktor.http.ContentType
import io.ktor.http.contentType
import io.ktor.utils.io.errors.IOException
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import tta.destinigo.talktoastro.core.remote.KtorApi
import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.feature_authentication.data.request.RequestOtpRequest
import tta.destinigo.talktoastro.feature_authentication.data.request.VerifyOtpRequest
import tta.destinigo.talktoastro.user_feature_authentication.data.response.RequestOtpData
import tta.destinigo.talktoastro.user_feature_authentication.data.response.RequestOtpResponse
import tta.destinigo.talktoastro.user_feature_authentication.data.response.VerifyOtpResponse
import tta.destinigo.talktoastro.user_feature_authentication.data.response.VerifyOtpResponseData

class AuthApiService : KtorApi() {

    private val storeLogger = Logger(
        loggerConfigInit(platformLogWriter(NoTagFormatter)),
        "StoreApi"
    )

    companion object {
        const val POST_REQUEST_OTP = "request-otp"
        const val POST_VERIFY_OTP = "verify-otp"
        //const val POST_REGISTER = "user/consultant-on-demand"
    }

    private suspend fun requestOtp(request: RequestOtpRequest) = client.post {
        url(POST_REQUEST_OTP)
        contentType(ContentType.Application.Json)
        setBody(request)
    }.body<RequestOtpResponse>()

    private suspend fun validateOtpApi(request: VerifyOtpRequest) = client.post {
        url(POST_VERIFY_OTP)
        contentType(ContentType.Application.Json)
        setBody(request)
    }.body<VerifyOtpResponse>()

//    suspend fun registerApi(request: RegisterRequest) = client.post {
//        url(POST_REGISTER)
//       // contentType(ContentType.Application.Json)
//        setBody(request)
//    }.body<VerifyOtpResponse>()

//    suspend fun registerApi(request: RegisterRequest, authToken: String) = client.post {
//        url(POST_REGISTER)
////        header("Authorization", "Bearer $authToken") // Add the Authorization header
//       // header(HttpHeaders.Authorization, authToken)
//        headers.append(HttpHeaders.Authorization, authToken)
//        contentType(ContentType.Application.Json)
//        Logger.d { "registerApi $authToken" }
//        setBody(request)
//    }.body<VerifyOtpResponse>()


    suspend fun requestLoginOtp(request: RequestOtpRequest): Flow<Resources<RequestOtpData>> {
        return flow {
            emit(Resources.Loading(true))
            try {

                val productResponse = requestOtp(request)
                if (productResponse.status.not()) {
                    return@flow emit(
                        Resources.Error(
                            productResponse.message ?: "API ERROR RESPONSE"
                        )
                    )
                }

                emit(Resources.Success(productResponse.data))

            } catch (apiError: Exception) {
                storeLogger.d { apiError.message.toString() }
                emit(Resources.Error(apiError.message ?: "API ERROR RESPONSE"))
            } catch (io: IOException) {
                storeLogger.d { io.message.toString() }
                emit(Resources.Error(io.message ?: "API ERROR RESPONSE"))
            } finally {
                emit(Resources.Loading(false))
            }
        }
    }


    suspend fun validateOtp(request: VerifyOtpRequest): Flow<Resources<VerifyOtpResponseData>> {

        return flow {
            emit(Resources.Loading(true))
            try {
                val response = validateOtpApi(request)

                if (response.status.not()) {
                    return@flow emit(Resources.Error(response.message))
                }

                emit(Resources.Success(response.data))

            } catch (apiError: Exception) {
                emit(Resources.Error(apiError.message))
            } catch (httpException: IOException) {
                emit(Resources.Error(httpException.message))
            } finally {
                emit(Resources.Loading(false))
            }

        }
    }

//    suspend fun registerUser (request: RegisterRequest): Flow<Resources<VerifyOtpResponseData>> {
//        val authToken = TokenProvider.getNewToken()
//        Logger.d { "fun registerUser $authToken" }
//        return flow {
//            emit(Resources.Loading(true))
//            try {
//                val response = registerApi(request, authToken)
//
//                if (response.status.not()) {
//                    return@flow emit(Resources.Error(response.message))
//                }
//
//                emit(Resources.Success(response.data))
//
//            } catch (apiError: Exception) {
//                emit(Resources.Error(apiError.message))
//            } catch (httpException: IOException) {
//                emit(Resources.Error(httpException.message))
//            } finally {
//                emit(Resources.Loading(false))
//            }
//
//        }
//    }

}