package tta.destinigo.talktoastro.feature_winyway_wallet.persentation.all_coupons_page

import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.AlertDialog
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.ModalBottomSheet
import androidx.compose.material3.SheetState
import androidx.compose.material3.Text
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalDensity
import co.touchlab.kermit.Logger
import kotlinx.coroutines.launch
import tta.destinigo.talktoastro.core.theme.Scale.CORNER_LARGE
import tta.destinigo.talktoastro.feature_winyway_wallet.data.CouponApplyListener

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class, ExperimentalMaterial3Api::class)
@Composable
fun CouponScreenList(
    component: AllCouponComponent,
    listener: CouponApplyListener,
    onDismiss: () -> Unit
) {
    Logger.d { "CouponScreen Start" }

    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    val state by component.allCouponState.collectAsState()
    val sheetState = remember {
        SheetState(
            skipPartiallyExpanded = true,
        )
    }
    val coroutineScope = rememberCoroutineScope()
    val (isDialogVisible, setDialogVisible) = remember { mutableStateOf(false) }

    // Proceed only if we have coupons in the state
    if (state.isNotEmpty()) {
        when (windowSizeClass) {
            WindowWidthSizeClass.Compact -> {
                // Show CouponCard in Bottom Sheet
                ModalBottomSheet(
                    onDismissRequest = { onDismiss.invoke() },
                    shape = RoundedCornerShape(topStart = CORNER_LARGE, topEnd = CORNER_LARGE),
                    modifier = Modifier
                        .fillMaxWidth()
                        .wrapContentHeight(),
                    containerColor = Color.White
                ) {
                    LazyColumn {
                        items(state) { coupon ->
                            CouponCard(
                                data = coupon.coupon,
                                listener = listener,
                                onDismiss = { onDismiss.invoke() }
                            )
                        }
                    }
                }
            }
            else -> {
                // Show dialog for larger window sizes
                if (isDialogVisible) {
                    AlertDialog(
                        containerColor = Color.White,
                        onDismissRequest = {
                            setDialogVisible(false)
                            onDismiss.invoke()
                        },
                        title = { Text(text = "Coupons") },
                        text = {
                            LazyColumn {
                                items(state) { coupon ->
                                    CouponCard(
                                        data = coupon.coupon,
                                        listener = listener,
                                        onDismiss = { setDialogVisible(false) }
                                    )
                                }
                            }
                        },
                        confirmButton = {
//                            TextButton(onClick = {
//                                setDialogVisible(false)
//                                onDismiss.invoke()
//                            }) {
//                                Text("OK")
//                            }
                        },
                        dismissButton = {
//                            TextButton(onClick = {
//                                setDialogVisible(false)
//                                onDismiss.invoke()
//                            }) {
//                                Text("Cancel")
//                            }
                        }
                    )
                }
            }
        }

        // Control the visibility of the dialog or bottom sheet based on window size
        LaunchedEffect(windowSizeClass) {
            if (windowSizeClass == WindowWidthSizeClass.Compact) {
                coroutineScope.launch {
                    sheetState.show()
                }
            } else {
                setDialogVisible(true)
            }
        }
    } else {
        // Handle the case where there are no coupons, if needed
        Text("No coupons available.")
    }
}


