package tta.destinigo.talktoastro.feature_post.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Comment(
    @SerialName("comment") val msg: String?, // hii
    @SerialName("comment_date") val commentDate: String, // 2023-09-23
    @SerialName("comment_time") val commentTime: String, // 22:29:15
    @SerialName("table_id") val tableId: Int, // 169
    @SerialName("user_email") val userEmail: String, // sekhnayan936@gmail.com
    @SerialName("user_id") val userId: Int, // 16988
    @SerialName("user_name") val userName: String, // sekhnayan936@gmail.com
    @SerialName("user_profile") val userProfile: String?
)
