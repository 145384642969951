package tta.destinigo.talktoastro.feature_winyway_wallet.persentation.razorpay_gateway_new

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel
import tta.destinigo.talktoastro.core.razorpay.startPayment

class StartGatewayComponent(
    context: ComponentContext,
    private val paymentGatewayArg: PaymentGatewayArguments,
    private val onRechargeSuccess: (orderId: String, paymentId: String, signature: String) -> Unit, //Required Top-Up Amount
    private val onRechargeFailed: (msg: String) -> Unit, // Required Top-Up Amount,
) : ComponentContext by context {

    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main)

    init {
        lifecycle.doOnStart {
            Logger.d { "PaymentGatewayComponent on start" }
            initRazorpayPayment()
        }

        lifecycle.doOnDestroy {
            Logger.d { "PaymentGatewayComponent on destroy" }
            coroutineScope.cancel() // Cancel all coroutines when the component is destroyed
        }
    }

    private fun initRazorpayPayment() {
        startPayment(
            paymentGatewayArg,
            onPaymentSuccess = { paymentId, orderId, signature ->
                onRechargeSuccess(orderId, paymentId, signature)
            },
            onPaymentFailed = { throwable ->
                Logger.d { "onPaymentFailed ${throwable.message}" }
                // Handle user-canceled payment and log the error
                onRechargeFailed(throwable.message ?: "Unexpected Payment Error")
            }
        )
    }


}