@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package tta.destinigo.talktoastro.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.FontResource

private object CommonMainFont0 {
  public val NotoColorEmoji_Regular: FontResource by 
      lazy { init_NotoColorEmoji_Regular() }

  public val NotoEmoji_Bold: FontResource by 
      lazy { init_NotoEmoji_Bold() }

  public val NotoEmoji_Light: FontResource by 
      lazy { init_NotoEmoji_Light() }

  public val NotoEmoji_Medium: FontResource by 
      lazy { init_NotoEmoji_Medium() }

  public val NotoEmoji_Regular: FontResource by 
      lazy { init_NotoEmoji_Regular() }

  public val NotoEmoji_SemiBold: FontResource by 
      lazy { init_NotoEmoji_SemiBold() }

  public val NotoSansDevanagari_Black: FontResource by 
      lazy { init_NotoSansDevanagari_Black() }

  public val NotoSansDevanagari_Bold: FontResource by 
      lazy { init_NotoSansDevanagari_Bold() }

  public val NotoSansDevanagari_Condensed_Black: FontResource by 
      lazy { init_NotoSansDevanagari_Condensed_Black() }

  public val NotoSansDevanagari_Condensed_Bold: FontResource by 
      lazy { init_NotoSansDevanagari_Condensed_Bold() }

  public val NotoSansDevanagari_Condensed_ExtraBold: FontResource by 
      lazy { init_NotoSansDevanagari_Condensed_ExtraBold() }

  public val NotoSansDevanagari_Condensed_ExtraLight: FontResource by 
      lazy { init_NotoSansDevanagari_Condensed_ExtraLight() }

  public val NotoSansDevanagari_Condensed_Light: FontResource by 
      lazy { init_NotoSansDevanagari_Condensed_Light() }

  public val NotoSansDevanagari_Condensed_Medium: FontResource by 
      lazy { init_NotoSansDevanagari_Condensed_Medium() }

  public val NotoSansDevanagari_Condensed_Regular: FontResource by 
      lazy { init_NotoSansDevanagari_Condensed_Regular() }

  public val NotoSansDevanagari_Condensed_SemiBold: FontResource by 
      lazy { init_NotoSansDevanagari_Condensed_SemiBold() }

  public val NotoSansDevanagari_Condensed_Thin: FontResource by 
      lazy { init_NotoSansDevanagari_Condensed_Thin() }

  public val NotoSansDevanagari_ExtraBold: FontResource by 
      lazy { init_NotoSansDevanagari_ExtraBold() }

  public val NotoSansDevanagari_ExtraCondensed_Black: FontResource by 
      lazy { init_NotoSansDevanagari_ExtraCondensed_Black() }

  public val NotoSansDevanagari_ExtraCondensed_Bold: FontResource by 
      lazy { init_NotoSansDevanagari_ExtraCondensed_Bold() }

  public val NotoSansDevanagari_ExtraCondensed_ExtraBold: FontResource by 
      lazy { init_NotoSansDevanagari_ExtraCondensed_ExtraBold() }

  public val NotoSansDevanagari_ExtraCondensed_ExtraLight: FontResource by 
      lazy { init_NotoSansDevanagari_ExtraCondensed_ExtraLight() }

  public val NotoSansDevanagari_ExtraCondensed_Light: FontResource by 
      lazy { init_NotoSansDevanagari_ExtraCondensed_Light() }

  public val NotoSansDevanagari_ExtraCondensed_Medium: FontResource by 
      lazy { init_NotoSansDevanagari_ExtraCondensed_Medium() }

  public val NotoSansDevanagari_ExtraCondensed_Regular: FontResource by 
      lazy { init_NotoSansDevanagari_ExtraCondensed_Regular() }

  public val NotoSansDevanagari_ExtraCondensed_SemiBold: FontResource by 
      lazy { init_NotoSansDevanagari_ExtraCondensed_SemiBold() }

  public val NotoSansDevanagari_ExtraCondensed_Thin: FontResource by 
      lazy { init_NotoSansDevanagari_ExtraCondensed_Thin() }

  public val NotoSansDevanagari_ExtraLight: FontResource by 
      lazy { init_NotoSansDevanagari_ExtraLight() }

  public val NotoSansDevanagari_Light: FontResource by 
      lazy { init_NotoSansDevanagari_Light() }

  public val NotoSansDevanagari_Medium: FontResource by 
      lazy { init_NotoSansDevanagari_Medium() }

  public val NotoSansDevanagari_Regular: FontResource by 
      lazy { init_NotoSansDevanagari_Regular() }

  public val NotoSansDevanagari_SemiBold: FontResource by 
      lazy { init_NotoSansDevanagari_SemiBold() }

  public val NotoSansDevanagari_SemiCondensed_Black: FontResource by 
      lazy { init_NotoSansDevanagari_SemiCondensed_Black() }

  public val NotoSansDevanagari_SemiCondensed_Bold: FontResource by 
      lazy { init_NotoSansDevanagari_SemiCondensed_Bold() }

  public val NotoSansDevanagari_SemiCondensed_ExtraBold: FontResource by 
      lazy { init_NotoSansDevanagari_SemiCondensed_ExtraBold() }

  public val NotoSansDevanagari_SemiCondensed_ExtraLight: FontResource by 
      lazy { init_NotoSansDevanagari_SemiCondensed_ExtraLight() }

  public val NotoSansDevanagari_SemiCondensed_Light: FontResource by 
      lazy { init_NotoSansDevanagari_SemiCondensed_Light() }

  public val NotoSansDevanagari_SemiCondensed_Medium: FontResource by 
      lazy { init_NotoSansDevanagari_SemiCondensed_Medium() }

  public val NotoSansDevanagari_SemiCondensed_Regular: FontResource by 
      lazy { init_NotoSansDevanagari_SemiCondensed_Regular() }

  public val NotoSansDevanagari_SemiCondensed_SemiBold: FontResource by 
      lazy { init_NotoSansDevanagari_SemiCondensed_SemiBold() }

  public val NotoSansDevanagari_SemiCondensed_Thin: FontResource by 
      lazy { init_NotoSansDevanagari_SemiCondensed_Thin() }

  public val NotoSansDevanagari_Thin: FontResource by 
      lazy { init_NotoSansDevanagari_Thin() }

  public val roboto_black: FontResource by 
      lazy { init_roboto_black() }

  public val roboto_black_italic: FontResource by 
      lazy { init_roboto_black_italic() }

  public val roboto_bold: FontResource by 
      lazy { init_roboto_bold() }

  public val roboto_bold_italic: FontResource by 
      lazy { init_roboto_bold_italic() }

  public val roboto_italic: FontResource by 
      lazy { init_roboto_italic() }

  public val roboto_light: FontResource by 
      lazy { init_roboto_light() }

  public val roboto_light_italic: FontResource by 
      lazy { init_roboto_light_italic() }

  public val roboto_medium: FontResource by 
      lazy { init_roboto_medium() }

  public val roboto_medium_italic: FontResource by 
      lazy { init_roboto_medium_italic() }

  public val roboto_regular: FontResource by 
      lazy { init_roboto_regular() }

  public val roboto_thin: FontResource by 
      lazy { init_roboto_thin() }

  public val roboto_thin_italic: FontResource by 
      lazy { init_roboto_thin_italic() }
}

public val Res.font.NotoColorEmoji_Regular: FontResource
  get() = CommonMainFont0.NotoColorEmoji_Regular

private fun init_NotoColorEmoji_Regular(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:NotoColorEmoji_Regular",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoColorEmoji-Regular.ttf", -1, -1),
    )
)

public val Res.font.NotoEmoji_Bold: FontResource
  get() = CommonMainFont0.NotoEmoji_Bold

private fun init_NotoEmoji_Bold(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:NotoEmoji_Bold",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoEmoji-Bold.ttf", -1, -1),
    )
)

public val Res.font.NotoEmoji_Light: FontResource
  get() = CommonMainFont0.NotoEmoji_Light

private fun init_NotoEmoji_Light(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:NotoEmoji_Light",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoEmoji-Light.ttf", -1, -1),
    )
)

public val Res.font.NotoEmoji_Medium: FontResource
  get() = CommonMainFont0.NotoEmoji_Medium

private fun init_NotoEmoji_Medium(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:NotoEmoji_Medium",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoEmoji-Medium.ttf", -1, -1),
    )
)

public val Res.font.NotoEmoji_Regular: FontResource
  get() = CommonMainFont0.NotoEmoji_Regular

private fun init_NotoEmoji_Regular(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:NotoEmoji_Regular",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoEmoji-Regular.ttf", -1, -1),
    )
)

public val Res.font.NotoEmoji_SemiBold: FontResource
  get() = CommonMainFont0.NotoEmoji_SemiBold

private fun init_NotoEmoji_SemiBold(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:NotoEmoji_SemiBold",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoEmoji-SemiBold.ttf", -1, -1),
    )
)

public val Res.font.NotoSansDevanagari_Black: FontResource
  get() = CommonMainFont0.NotoSansDevanagari_Black

private fun init_NotoSansDevanagari_Black(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:NotoSansDevanagari_Black",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoSansDevanagari-Black.ttf", -1, -1),
    )
)

public val Res.font.NotoSansDevanagari_Bold: FontResource
  get() = CommonMainFont0.NotoSansDevanagari_Bold

private fun init_NotoSansDevanagari_Bold(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:NotoSansDevanagari_Bold",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoSansDevanagari-Bold.ttf", -1, -1),
    )
)

public val Res.font.NotoSansDevanagari_Condensed_Black: FontResource
  get() = CommonMainFont0.NotoSansDevanagari_Condensed_Black

private fun init_NotoSansDevanagari_Condensed_Black(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:NotoSansDevanagari_Condensed_Black",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoSansDevanagari_Condensed-Black.ttf", -1, -1),
    )
)

public val Res.font.NotoSansDevanagari_Condensed_Bold: FontResource
  get() = CommonMainFont0.NotoSansDevanagari_Condensed_Bold

private fun init_NotoSansDevanagari_Condensed_Bold(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:NotoSansDevanagari_Condensed_Bold",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoSansDevanagari_Condensed-Bold.ttf", -1, -1),
    )
)

public val Res.font.NotoSansDevanagari_Condensed_ExtraBold: FontResource
  get() = CommonMainFont0.NotoSansDevanagari_Condensed_ExtraBold

private fun init_NotoSansDevanagari_Condensed_ExtraBold(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:NotoSansDevanagari_Condensed_ExtraBold",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoSansDevanagari_Condensed-ExtraBold.ttf", -1, -1),
    )
)

public val Res.font.NotoSansDevanagari_Condensed_ExtraLight: FontResource
  get() = CommonMainFont0.NotoSansDevanagari_Condensed_ExtraLight

private fun init_NotoSansDevanagari_Condensed_ExtraLight(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:NotoSansDevanagari_Condensed_ExtraLight",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoSansDevanagari_Condensed-ExtraLight.ttf", -1, -1),
    )
)

public val Res.font.NotoSansDevanagari_Condensed_Light: FontResource
  get() = CommonMainFont0.NotoSansDevanagari_Condensed_Light

private fun init_NotoSansDevanagari_Condensed_Light(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:NotoSansDevanagari_Condensed_Light",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoSansDevanagari_Condensed-Light.ttf", -1, -1),
    )
)

public val Res.font.NotoSansDevanagari_Condensed_Medium: FontResource
  get() = CommonMainFont0.NotoSansDevanagari_Condensed_Medium

private fun init_NotoSansDevanagari_Condensed_Medium(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:NotoSansDevanagari_Condensed_Medium",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoSansDevanagari_Condensed-Medium.ttf", -1, -1),
    )
)

public val Res.font.NotoSansDevanagari_Condensed_Regular: FontResource
  get() = CommonMainFont0.NotoSansDevanagari_Condensed_Regular

private fun init_NotoSansDevanagari_Condensed_Regular(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:NotoSansDevanagari_Condensed_Regular",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoSansDevanagari_Condensed-Regular.ttf", -1, -1),
    )
)

public val Res.font.NotoSansDevanagari_Condensed_SemiBold: FontResource
  get() = CommonMainFont0.NotoSansDevanagari_Condensed_SemiBold

private fun init_NotoSansDevanagari_Condensed_SemiBold(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:NotoSansDevanagari_Condensed_SemiBold",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoSansDevanagari_Condensed-SemiBold.ttf", -1, -1),
    )
)

public val Res.font.NotoSansDevanagari_Condensed_Thin: FontResource
  get() = CommonMainFont0.NotoSansDevanagari_Condensed_Thin

private fun init_NotoSansDevanagari_Condensed_Thin(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:NotoSansDevanagari_Condensed_Thin",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoSansDevanagari_Condensed-Thin.ttf", -1, -1),
    )
)

public val Res.font.NotoSansDevanagari_ExtraBold: FontResource
  get() = CommonMainFont0.NotoSansDevanagari_ExtraBold

private fun init_NotoSansDevanagari_ExtraBold(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:NotoSansDevanagari_ExtraBold",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoSansDevanagari-ExtraBold.ttf", -1, -1),
    )
)

public val Res.font.NotoSansDevanagari_ExtraCondensed_Black: FontResource
  get() = CommonMainFont0.NotoSansDevanagari_ExtraCondensed_Black

private fun init_NotoSansDevanagari_ExtraCondensed_Black(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:NotoSansDevanagari_ExtraCondensed_Black",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoSansDevanagari_ExtraCondensed-Black.ttf", -1, -1),
    )
)

public val Res.font.NotoSansDevanagari_ExtraCondensed_Bold: FontResource
  get() = CommonMainFont0.NotoSansDevanagari_ExtraCondensed_Bold

private fun init_NotoSansDevanagari_ExtraCondensed_Bold(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:NotoSansDevanagari_ExtraCondensed_Bold",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoSansDevanagari_ExtraCondensed-Bold.ttf", -1, -1),
    )
)

public val Res.font.NotoSansDevanagari_ExtraCondensed_ExtraBold: FontResource
  get() = CommonMainFont0.NotoSansDevanagari_ExtraCondensed_ExtraBold

private fun init_NotoSansDevanagari_ExtraCondensed_ExtraBold(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:NotoSansDevanagari_ExtraCondensed_ExtraBold",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoSansDevanagari_ExtraCondensed-ExtraBold.ttf", -1, -1),
    )
)

public val Res.font.NotoSansDevanagari_ExtraCondensed_ExtraLight: FontResource
  get() = CommonMainFont0.NotoSansDevanagari_ExtraCondensed_ExtraLight

private fun init_NotoSansDevanagari_ExtraCondensed_ExtraLight(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:NotoSansDevanagari_ExtraCondensed_ExtraLight",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoSansDevanagari_ExtraCondensed-ExtraLight.ttf", -1, -1),
    )
)

public val Res.font.NotoSansDevanagari_ExtraCondensed_Light: FontResource
  get() = CommonMainFont0.NotoSansDevanagari_ExtraCondensed_Light

private fun init_NotoSansDevanagari_ExtraCondensed_Light(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:NotoSansDevanagari_ExtraCondensed_Light",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoSansDevanagari_ExtraCondensed-Light.ttf", -1, -1),
    )
)

public val Res.font.NotoSansDevanagari_ExtraCondensed_Medium: FontResource
  get() = CommonMainFont0.NotoSansDevanagari_ExtraCondensed_Medium

private fun init_NotoSansDevanagari_ExtraCondensed_Medium(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:NotoSansDevanagari_ExtraCondensed_Medium",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoSansDevanagari_ExtraCondensed-Medium.ttf", -1, -1),
    )
)

public val Res.font.NotoSansDevanagari_ExtraCondensed_Regular: FontResource
  get() = CommonMainFont0.NotoSansDevanagari_ExtraCondensed_Regular

private fun init_NotoSansDevanagari_ExtraCondensed_Regular(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:NotoSansDevanagari_ExtraCondensed_Regular",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoSansDevanagari_ExtraCondensed-Regular.ttf", -1, -1),
    )
)

public val Res.font.NotoSansDevanagari_ExtraCondensed_SemiBold: FontResource
  get() = CommonMainFont0.NotoSansDevanagari_ExtraCondensed_SemiBold

private fun init_NotoSansDevanagari_ExtraCondensed_SemiBold(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:NotoSansDevanagari_ExtraCondensed_SemiBold",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoSansDevanagari_ExtraCondensed-SemiBold.ttf", -1, -1),
    )
)

public val Res.font.NotoSansDevanagari_ExtraCondensed_Thin: FontResource
  get() = CommonMainFont0.NotoSansDevanagari_ExtraCondensed_Thin

private fun init_NotoSansDevanagari_ExtraCondensed_Thin(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:NotoSansDevanagari_ExtraCondensed_Thin",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoSansDevanagari_ExtraCondensed-Thin.ttf", -1, -1),
    )
)

public val Res.font.NotoSansDevanagari_ExtraLight: FontResource
  get() = CommonMainFont0.NotoSansDevanagari_ExtraLight

private fun init_NotoSansDevanagari_ExtraLight(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:NotoSansDevanagari_ExtraLight",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoSansDevanagari-ExtraLight.ttf", -1, -1),
    )
)

public val Res.font.NotoSansDevanagari_Light: FontResource
  get() = CommonMainFont0.NotoSansDevanagari_Light

private fun init_NotoSansDevanagari_Light(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:NotoSansDevanagari_Light",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoSansDevanagari-Light.ttf", -1, -1),
    )
)

public val Res.font.NotoSansDevanagari_Medium: FontResource
  get() = CommonMainFont0.NotoSansDevanagari_Medium

private fun init_NotoSansDevanagari_Medium(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:NotoSansDevanagari_Medium",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoSansDevanagari-Medium.ttf", -1, -1),
    )
)

public val Res.font.NotoSansDevanagari_Regular: FontResource
  get() = CommonMainFont0.NotoSansDevanagari_Regular

private fun init_NotoSansDevanagari_Regular(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:NotoSansDevanagari_Regular",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoSansDevanagari-Regular.ttf", -1, -1),
    )
)

public val Res.font.NotoSansDevanagari_SemiBold: FontResource
  get() = CommonMainFont0.NotoSansDevanagari_SemiBold

private fun init_NotoSansDevanagari_SemiBold(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:NotoSansDevanagari_SemiBold",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoSansDevanagari-SemiBold.ttf", -1, -1),
    )
)

public val Res.font.NotoSansDevanagari_SemiCondensed_Black: FontResource
  get() = CommonMainFont0.NotoSansDevanagari_SemiCondensed_Black

private fun init_NotoSansDevanagari_SemiCondensed_Black(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:NotoSansDevanagari_SemiCondensed_Black",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoSansDevanagari_SemiCondensed-Black.ttf", -1, -1),
    )
)

public val Res.font.NotoSansDevanagari_SemiCondensed_Bold: FontResource
  get() = CommonMainFont0.NotoSansDevanagari_SemiCondensed_Bold

private fun init_NotoSansDevanagari_SemiCondensed_Bold(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:NotoSansDevanagari_SemiCondensed_Bold",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoSansDevanagari_SemiCondensed-Bold.ttf", -1, -1),
    )
)

public val Res.font.NotoSansDevanagari_SemiCondensed_ExtraBold: FontResource
  get() = CommonMainFont0.NotoSansDevanagari_SemiCondensed_ExtraBold

private fun init_NotoSansDevanagari_SemiCondensed_ExtraBold(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:NotoSansDevanagari_SemiCondensed_ExtraBold",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoSansDevanagari_SemiCondensed-ExtraBold.ttf", -1, -1),
    )
)

public val Res.font.NotoSansDevanagari_SemiCondensed_ExtraLight: FontResource
  get() = CommonMainFont0.NotoSansDevanagari_SemiCondensed_ExtraLight

private fun init_NotoSansDevanagari_SemiCondensed_ExtraLight(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:NotoSansDevanagari_SemiCondensed_ExtraLight",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoSansDevanagari_SemiCondensed-ExtraLight.ttf", -1, -1),
    )
)

public val Res.font.NotoSansDevanagari_SemiCondensed_Light: FontResource
  get() = CommonMainFont0.NotoSansDevanagari_SemiCondensed_Light

private fun init_NotoSansDevanagari_SemiCondensed_Light(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:NotoSansDevanagari_SemiCondensed_Light",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoSansDevanagari_SemiCondensed-Light.ttf", -1, -1),
    )
)

public val Res.font.NotoSansDevanagari_SemiCondensed_Medium: FontResource
  get() = CommonMainFont0.NotoSansDevanagari_SemiCondensed_Medium

private fun init_NotoSansDevanagari_SemiCondensed_Medium(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:NotoSansDevanagari_SemiCondensed_Medium",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoSansDevanagari_SemiCondensed-Medium.ttf", -1, -1),
    )
)

public val Res.font.NotoSansDevanagari_SemiCondensed_Regular: FontResource
  get() = CommonMainFont0.NotoSansDevanagari_SemiCondensed_Regular

private fun init_NotoSansDevanagari_SemiCondensed_Regular(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:NotoSansDevanagari_SemiCondensed_Regular",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoSansDevanagari_SemiCondensed-Regular.ttf", -1, -1),
    )
)

public val Res.font.NotoSansDevanagari_SemiCondensed_SemiBold: FontResource
  get() = CommonMainFont0.NotoSansDevanagari_SemiCondensed_SemiBold

private fun init_NotoSansDevanagari_SemiCondensed_SemiBold(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:NotoSansDevanagari_SemiCondensed_SemiBold",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoSansDevanagari_SemiCondensed-SemiBold.ttf", -1, -1),
    )
)

public val Res.font.NotoSansDevanagari_SemiCondensed_Thin: FontResource
  get() = CommonMainFont0.NotoSansDevanagari_SemiCondensed_Thin

private fun init_NotoSansDevanagari_SemiCondensed_Thin(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:NotoSansDevanagari_SemiCondensed_Thin",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoSansDevanagari_SemiCondensed-Thin.ttf", -1, -1),
    )
)

public val Res.font.NotoSansDevanagari_Thin: FontResource
  get() = CommonMainFont0.NotoSansDevanagari_Thin

private fun init_NotoSansDevanagari_Thin(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:NotoSansDevanagari_Thin",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoSansDevanagari-Thin.ttf", -1, -1),
    )
)

public val Res.font.roboto_black: FontResource
  get() = CommonMainFont0.roboto_black

private fun init_roboto_black(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:roboto_black",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/roboto_black.ttf", -1, -1),
    )
)

public val Res.font.roboto_black_italic: FontResource
  get() = CommonMainFont0.roboto_black_italic

private fun init_roboto_black_italic(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:roboto_black_italic",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/roboto_black_italic.ttf", -1, -1),
    )
)

public val Res.font.roboto_bold: FontResource
  get() = CommonMainFont0.roboto_bold

private fun init_roboto_bold(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:roboto_bold",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/roboto_bold.ttf", -1, -1),
    )
)

public val Res.font.roboto_bold_italic: FontResource
  get() = CommonMainFont0.roboto_bold_italic

private fun init_roboto_bold_italic(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:roboto_bold_italic",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/roboto_bold_italic.ttf", -1, -1),
    )
)

public val Res.font.roboto_italic: FontResource
  get() = CommonMainFont0.roboto_italic

private fun init_roboto_italic(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:roboto_italic",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/roboto_italic.ttf", -1, -1),
    )
)

public val Res.font.roboto_light: FontResource
  get() = CommonMainFont0.roboto_light

private fun init_roboto_light(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:roboto_light",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/roboto_light.ttf", -1, -1),
    )
)

public val Res.font.roboto_light_italic: FontResource
  get() = CommonMainFont0.roboto_light_italic

private fun init_roboto_light_italic(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:roboto_light_italic",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/roboto_light_italic.ttf", -1, -1),
    )
)

public val Res.font.roboto_medium: FontResource
  get() = CommonMainFont0.roboto_medium

private fun init_roboto_medium(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:roboto_medium",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/roboto_medium.ttf", -1, -1),
    )
)

public val Res.font.roboto_medium_italic: FontResource
  get() = CommonMainFont0.roboto_medium_italic

private fun init_roboto_medium_italic(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:roboto_medium_italic",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/roboto_medium_italic.ttf", -1, -1),
    )
)

public val Res.font.roboto_regular: FontResource
  get() = CommonMainFont0.roboto_regular

private fun init_roboto_regular(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:roboto_regular",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/roboto_regular.ttf", -1, -1),
    )
)

public val Res.font.roboto_thin: FontResource
  get() = CommonMainFont0.roboto_thin

private fun init_roboto_thin(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:roboto_thin",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/roboto_thin.ttf", -1, -1),
    )
)

public val Res.font.roboto_thin_italic: FontResource
  get() = CommonMainFont0.roboto_thin_italic

private fun init_roboto_thin_italic(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:roboto_thin_italic",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/roboto_thin_italic.ttf", -1, -1),
    )
)
