package tta.destinigo.talktoastro.feature_common.persentation.more_tab.refer_and_earn.widget

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.core.animateFloatAsState
import androidx.compose.animation.core.tween
import androidx.compose.animation.expandVertically
import androidx.compose.animation.fadeIn
import androidx.compose.animation.fadeOut
import androidx.compose.animation.shrinkVertically
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Checkbox
import androidx.compose.material3.CheckboxDefaults
import androidx.compose.material3.Text
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.ui.draw.clip
import androidx.compose.foundation.shape.CornerSize
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Add
import androidx.compose.material.icons.filled.Remove
import androidx.compose.material3.Icon
import androidx.compose.ui.draw.rotate
import tta.destinigo.talktoastro.core.theme.primaryColor

@Composable
fun FAQScreen() {
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .padding(bottom = 24.dp)
            // .background(Color.White)
    ) {
        // Divider View
        Spacer(
            modifier = Modifier
                .fillMaxWidth()
                .height(4.dp)
                .padding(bottom = 8.dp)
                .background(Color(0xFFE0E0E0)) // grey_400
        )

        // Header Title
        Text(
            text = "FAQ",
            fontSize = 16.sp,
            fontWeight = FontWeight.Bold,
            color = Color.Black,
            modifier = Modifier
                .fillMaxWidth()
                .padding(horizontal = 15.dp, vertical = 12.dp),
            textAlign = TextAlign.Start
        )

        // FAQ Items
        FAQItem(
            title = "What is the Winyway Refer and Earn Program ?",
            description = "Through Winyway Refer and Earn Program, You can refer Winyway to your friends and family\n" +
                    "And earn coins for the same and get discounts in consultation booking based on coins value.\n" +
                    "There also rewards coins for highest sharing users every week."
        )
        FAQItem(
            title = "How Can I earn Coins ?",
            description = "You can share Winyway app through whatsapp etc and once your friend signup with Winyway, you will get coins."
        )
        FAQItem(
            title = "How Winyway coins can be used ?",
            description = "Winyway coins can be redeemed during consultation booking time."
        )
        FAQItem(
            title = "How much coins Can I earn ?",
            description = "As much as you wish."
        )
        FAQItem(
            title = "What is the value of coin ?",
            description = "Each coins is equal to 0.1 winyway credit equated to Rs 1"
        )
        FAQItem(
            title = "What is the validity of Winyway coins ?",
            description = "90 days "
        )
    }
}

@Composable
fun FAQItem(title: String, description: String) {
    val expanded = remember { mutableStateOf(false) }
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .padding(horizontal = 15.dp, vertical = 5.dp)
            .clip(RoundedCornerShape(CornerSize(8.dp)))
            .background(Color.White)
    ) {
        // Animated rotation angle
        val rotationAngle: Float by animateFloatAsState(
            targetValue = if (expanded.value) 180f else 0f,
            animationSpec = tween(durationMillis = 300) // Adjust animation duration as needed
        )

        Row(
            modifier = Modifier
                .fillMaxWidth()
                .padding(vertical = 15.dp)
                .background(Color.White),
            verticalAlignment = Alignment.CenterVertically
        ) {
            // Title Text
            Text(
                text = title,
                fontSize = 13.sp,
                fontWeight = FontWeight.SemiBold,
                color = Color.Black,
                modifier = Modifier
                    .weight(1f)
                    .padding(start = 15.dp, end = 8.dp)
            )

            // Animated Toggle Icon
            Icon(
                imageVector = if (expanded.value) Icons.Default.Remove else Icons.Default.Add, // "-" for expanded, "+" for collapsed
                contentDescription = if (expanded.value) "Collapse" else "Expand",
                tint = primaryColor, // Primary color
                modifier = Modifier.padding(end = 10.dp)
                    .size(24.dp)
                    .clickable { expanded.value = !expanded.value }
                    .rotate(rotationAngle) // Apply rotation animation
            )
        }

        AnimatedVisibility(
            visible = expanded.value,
            enter = fadeIn(tween(durationMillis = 300)) + expandVertically(tween(durationMillis = 300)),
            exit = fadeOut(tween(durationMillis = 300)) + shrinkVertically(tween(durationMillis = 300))
        ) {
            // Description Text
            Text(
                text = description,
                fontSize = 13.sp,
                color = Color.Black,
                modifier = Modifier
                    .fillMaxWidth()
                    .background(Color(0xFFF7F7F7))
                    .padding(15.dp)
            )
        }

        // Divider
        Box(
            modifier = Modifier
                .fillMaxWidth()
                .height(1.dp)
                .background(Color.Transparent)
        )
    }
}
