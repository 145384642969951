package tta.destinigo.talktoastro.feature_post.data.response



import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CommentSubmitResponse(
    @SerialName("comment") val comment: String, // testing 2 time
    @SerialName("commentData") val commentData: CommentData,
    @SerialName("comment_date") val commentDate: String, // 2025-01-02
    @SerialName("comment_time") val commentTime: String, // 17:17:39
    @SerialName("message") val message: String, // Commented successfully.
    @SerialName("post_id") val postId: Int, // 655
    @SerialName("responsecode") val responsecode: String, // 200
    @SerialName("status") val status: String, // success
    @SerialName("user_email") val userEmail: String,
    @SerialName("user_id") val userId: Int, // 54000
    @SerialName("user_name") val userName: String, // 11 12 payment testing
    @SerialName("user_profile") val userProfile: String
)