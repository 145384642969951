package tta.destinigo.talktoastro.feature_post.data

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import tta.destinigo.talktoastro.feature_post.data.response.PostPagingData

@Serializable
data class PostPagingResponse(
    @SerialName("Data") val postPagingData: PostPagingData,
    @SerialName("responsecode") val responsecode: String, // 200
    @SerialName("status") val status: String // success
)
