package tta.destinigo.talktoastro.feature_daily_shorts.data

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import tta.destinigo.talktoastro.feature_daily_shorts.data.response.InstaList

@Serializable
data class InstaListResponse(
    @SerialName("status")
    var status: Boolean = false,
    @SerialName("message")
    var message: String? = "Unexpected Error",
    @SerialName("code")
    var code: Int = 0,
    @SerialName("data")
    val data: List<InstaList>
)