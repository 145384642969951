package tta.destinigo.talktoastro.core.extenstion

import androidx.compose.runtime.NoLiveLiterals
import kotlinx.browser.window
import org.w3c.xhr.BLOB
import org.w3c.xhr.XMLHttpRequest
import org.w3c.xhr.XMLHttpRequestResponseType

actual fun openWhatsApp(phoneNumber: String) {
    val url = "https://wa.me/$phoneNumber" // WhatsApp link format
    window.location.href = url // Open WhatsApp in the browser
}

//actual fun openWhatsAppAndRefer(
//    image: String,
//    appUrl: String,
//    website: String,
//    referCode: String
//) {
//    // Encode text
//    val encodedMessage = encodeURIComponent(
//        """
//    🎉 Download the TalkToAstro App now and earn coins to enjoy free consultations! 🌟
//    Use my referral code: ${referCode} during registration to claim your rewards.
//
//    🌐 Visit the website for more: $website
//    📲 Download the app here: https://play.google.com/store/apps/details?id=tta.destinigo.talktoastro&referrer=utm_source%3D${referCode}
//
//
//    Don't miss out on this amazing opportunity! 🚀
//    """.trimIndent()
//    )
//
//
//
//    //Download Winyway App & earn coin and get free consultation, register from my referral link https://play.google.com/store/apps/details?id=${}&referrer=utm_source%3D${getReferralCode()} . My Referral code is ${getReferralCode()}
//    // Construct the WhatsApp Web URL for text
//    val whatsappWebUrl = "https://api.whatsapp.com/send?text=$encodedMessage"
//
//    // Open WhatsApp Web with the pre-filled text message
//    openWhatsAppInNewTab(whatsappWebUrl)
//
//    // Render the image with a download option
//    renderImageWithText(image, appUrl, website, referCode)
//}
//
//private fun encodeURIComponent(value: String): String {
//    return js("encodeURIComponent(value)") as String
//}
//
//private fun openWhatsAppInNewTab(url: String) {
//    // Open a new tab with the WhatsApp Web URL
//    js("window.open(url, '_blank')")
//}
//
//@OptIn(ExperimentalJsExport::class)
//@NoLiveLiterals
//private fun renderImageWithText(image: String, appUrl: String, website: String, referCode: String) {
//    // Create HTML elements
//    val container = js("document.createElement('div')")
//    val imgElement = js("document.createElement('img')")
//    val textElement = js("document.createElement('p')")
//    val downloadLinkElement = js("document.createElement('a')")
//
//    // Set attributes for the image
//    imgElement.src = image
//    imgElement.alt = "Referral Image"
//    imgElement.style = "max-width: 100%; height: auto; margin: 10px 0;"
//
//    // Add text
//    textElement.innerHTML = """
//        Check out this app and use my referral code: $referCode.<br>
//        Download the app here: <a href='$appUrl' target='_blank'>$appUrl</a><br>
//        Visit the website: <a href='$website' target='_blank'>$website</a>
//    """.trimIndent()
//
//    // Add a download link for the image
//    downloadLinkElement.href = image
//    downloadLinkElement.download = "refer_and_earn.png"
//    downloadLinkElement.innerHTML = "Download Image"
//    downloadLinkElement.style = "display: block; margin-top: 10px;"
//
//    // Append elements to the container
//    container.appendChild(textElement)
//    container.appendChild(imgElement)
//    container.appendChild(downloadLinkElement)
//
//    // Append the container to the body
//    js("document.body.appendChild(container)")
//}


private const val HTTP_GET = "GET"
private const val RESPONSE_TYPE_BLOB = "blob"
private const val ERROR_MESSAGE_DOWNLOAD_FAILED = "Failed to download image. Status: "
private const val ERROR_MESSAGE_FETCH = "Error occurred while fetching the image."
private const val IMAGE_DOWNLOAD_TEXT = "Click here to download the referral image"
private const val IMAGE_FILENAME = "refer_and_earn.png"
private const val IMAGE_MIME_TYPE = "image/png"
private const val ANCHOR_STYLE = "display: block; margin-top: 10px; color: blue; text-decoration: underline;"


actual fun openWhatsAppAndRefer(
    image: String,
    appUrl: String,
    website: String,
    referCode: String
) {
    // Prepare the referral message
    val referralMessage = """
        🎉 Download the TalkToAstro App now and earn coins to enjoy free consultations! 🌟
        Use my referral code: $referCode during registration to claim your rewards.

        🌐 Visit the website for more: $website
        📲 Download the app here: https://play.google.com/store/apps/details?id=tta.destinigo.talktoastro&referrer=utm_source%3D$referCode

        Don't miss out on this amazing opportunity! 🚀
        """.trimIndent()

    // Save the image locally and enable sharing
    saveImageAndPrepareShareForWhatsAppReferral(image, referralMessage)
}

private fun saveImageAndPrepareShareForWhatsAppReferral(imageUrl: String, message: String) {
    val xhr = XMLHttpRequest()
    xhr.open("GET", imageUrl, true)
    xhr.responseType = XMLHttpRequestResponseType.BLOB // Set the response type to 'blob'

    xhr.onload = { _ ->
        if (xhr.status == 200.toShort()) {
            val blob = xhr.response
            // Debugging: Log the response
            console.log("Image Blob received: $blob")

            // After the image is loaded, share it
            shareViaWhatsAppWithImageBlob(blob, message)
        } else {
            console.error("Download failed with status: " + xhr.status)
        }
    }

    xhr.onerror = { _ ->
        console.error("Error fetching the image.")
    }

    xhr.send()
}

private fun shareViaWhatsAppWithImageBlob(blob: dynamic, message: String) {
    // Check if navigator.share is available
    if (js("navigator.canShare")) {
        try {
            // Create a new File object with the blob
            val file = js("new File([blob], 'refer_and_earn.png', { type: 'image/png' })")

            // Check if the browser supports sharing files
            if (js("navigator.canShare({ files: [file] })")) {
                // Prepare the options object for sharing
                val options: dynamic = js("({})")
                options.text = message
                options.files = arrayOf(file) // Pass the file array

                // Debugging: Log the options before sharing
                console.log("Sharing with options: ", options)

                // Share the content
                js("navigator.share(options)").then {
                    console.log("Shared successfully!")
                }.catch { error ->
                    console.error("Error sharing: ", error)
                    fallbackToWhatsApp(message, blob)
                }
            } else {
                console.warn("Browser does not support sharing files. Falling back to WhatsApp.")
                fallbackToWhatsApp(message, blob)
            }
        } catch (e: Throwable) {
            console.error("Error preparing file for sharing: ", e)
            fallbackToWhatsApp(message, blob)
        }
    } else {
        console.warn("navigator.share is not available. Falling back to WhatsApp.")
        fallbackToWhatsApp(message, blob)
    }
}

// Fallback to WhatsApp Web
private fun fallbackToWhatsApp(message: String, blob: dynamic) {
    console.log("Falling back to WhatsApp Web")

    // Convert blob to an Object URL
    val imageUrl = js("URL.createObjectURL(blob)")
    val whatsappUrl = "https://wa.me/?text=${encodeURIComponent(message)}"

    // Notify the user
    window.alert("Sharing via WhatsApp. Text will be shared directly, and you'll need to attach the image manually.")

    // Open WhatsApp with the message text
    js("window.open(whatsappUrl, '_blank')")

    // Provide the image URL for manual download
    window.alert("To share the image, download it using this link: $imageUrl")
}


//// Fallback to WhatsApp Web
//private fun fallbackToWhatsAppWeb(message: String, blob: dynamic) {
//    console.log("Falling back to WhatsApp Web")
//
//    // Convert blob to Object URL
//    val imageUrl = js("URL.createObjectURL(blob)")
//    val whatsappUrl = "https://web.whatsapp.com/send?text=${encodeURIComponent(message)}"
//
//    // Debugging: Log URLs
//    console.log("WhatsApp URL: $whatsappUrl")
//    console.log("Image URL: $imageUrl")
//
//    // Display a message to the user to manually share the image
//    window.alert("Please share the image manually: $imageUrl")
//
//    // Open WhatsApp Web for sharing text
//    js("window.open(whatsappUrl, '_blank')")
//}



private fun createUniqueDownloadLinkForImage(blob: dynamic) {
    // Create a URL for the blob and prompt the user to download it
    val fileUri = js("URL.createObjectURL(blob)") as String
    val anchor = js("document.createElement('a')")
    anchor.href = fileUri
   // anchor.download = "refer_and_earn.png"
    anchor.textContent = "Download Image"
    js("document.body.appendChild(anchor)") // Append to the body to make it clickable
    anchor.click() // Trigger the download
    js("document.body.removeChild(anchor)") // Remove the anchor after download
}

private fun openWhatsAppInNewTab(url: String) {
    // Ensure window.open works
    console.log("Attempting to open URL in a new tab: $url")
    js("window.open(url, '_blank')")
}

private fun encodeURIComponent(value: String): String {
    return js("encodeURIComponent(value)") as String
}











