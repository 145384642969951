package tta.destinigo.talktoastro.feature_courses.persentation.course_page.widget.sections

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.rounded.Star
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import coil3.compose.AsyncImage
import tta.destinigo.talktoastro.core.theme.Scale
import tta.destinigo.talktoastro.core.theme.primaryDarkerColor
import tta.destinigo.talktoastro.feature_courses.data.response.courses.Instructors


@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun InstructorsSection(
    modifier: Modifier = Modifier,
    instructors: Instructors
) {

    val windowWidth = calculateWindowSizeClass().widthSizeClass
    val shouldShow = windowWidth == WindowWidthSizeClass.Expanded

    Column(
        modifier = modifier
    ) {

        Spacer(modifier = Modifier.height(12.dp))
        InstructorsProfile(
            modifier = Modifier,
            instructors = instructors,
            shouldShow
        )
        Spacer(modifier = Modifier.height(24.dp))
    }

}


@Composable
fun InstructorsProfile(
    modifier: Modifier = Modifier,
    instructors: Instructors,
    isExpanded: Boolean
) {


    Box(
        modifier = modifier.background(
            color = Color.White,
            shape = RoundedCornerShape(Scale.CORNER_SMALL)
        )
    ) {

        if (isExpanded) {
            Row(
                modifier.padding(24.dp)
            ) {

                Box(
                    modifier = Modifier.weight(1f),
                    contentAlignment = Alignment.Center
                ) {
                    AsyncImage(
                        model = instructors.profileImage,
                        contentDescription = "Profile Image",
                        modifier = Modifier.size(95.dp).clip(CircleShape)
                            .align(Alignment.Center),
                        contentScale = ContentScale.Crop
                    )
                }


                Column(
                    modifier = Modifier.weight(3f)
                ) {

                    //Name of the instructor
                    Text(
                        text = instructors.name ?: "Unknown",
                        style = MaterialTheme.typography.bodyLarge.copy(
                            color = Color.Black,
                            fontWeight = FontWeight.Bold
                        )
                    )

                    //Designation of the instructor
                    Text(
                        text = instructors.profession ?: "Unknown",
                        style = MaterialTheme.typography.bodyMedium.copy(
                            color = Color.Gray,
                            fontWeight = FontWeight.SemiBold
                        )
                    )

                    Spacer(Modifier.height(8.dp))

                    if (isExpanded) {
                        // Rating and Reviews
                        Row {

                            //Experience
                            TextWithStartIconOne(
                                modifier = Modifier,
                                icon = Icons.Rounded.Star,
                                text = instructors.experience ?: ""
                            )

                            Spacer(Modifier.width(8.dp))

                            //Students
                            TextWithStartIconOne(
                                modifier = Modifier,
                                icon = Icons.Rounded.Star,
                                text = instructors.students ?: ""
                            )

                            Spacer(Modifier.width(8.dp))

                            //Ratings
                            TextWithStartIconOne(
                                modifier = Modifier,
                                icon = Icons.Rounded.Star,
                                text = instructors.reviews ?: ""
                            )

                        }
                    } else {
                        // Rating and Reviews
                        TextWithStartIconOne(
                            modifier = Modifier,
                            icon = Icons.Rounded.Star,
                            text = instructors.experience ?: ""
                        )

                        Spacer(Modifier.height(4.dp))

                        //Students
                        TextWithStartIconOne(
                            modifier = Modifier,
                            icon = Icons.Rounded.Star,
                            text = instructors.students ?: ""
                        )

                        Spacer(Modifier.height(4.dp))

                        //Ratings
                        TextWithStartIconOne(
                            modifier = Modifier,
                            icon = Icons.Rounded.Star,
                            text = instructors.reviews ?: ""
                        )
                    }

                    Spacer(Modifier.height(8.dp))

                    // Profile Description
                    Text(
                        text = instructors.bio ?: "",
                        style = MaterialTheme.typography.bodyMedium.copy(
                            color = Color.Gray,
                            fontWeight = FontWeight.SemiBold
                        ),
                    )

                }

            }
        } else {

            Column(
                modifier = Modifier.padding(16.dp)
            ) {

                Box(
                    contentAlignment = Alignment.Center
                ) {
                    AsyncImage(
                        model = instructors.profileImage,
                        contentDescription = "Profile Image",
                        modifier = Modifier.size(75.dp).clip(CircleShape)
                            .align(Alignment.Center),
                        contentScale = ContentScale.Crop
                    )
                }

                Spacer(Modifier.height(8.dp))

                //Name of the instructor
                Text(
                    text = instructors.name ?: "Unknown",
                    style = MaterialTheme.typography.bodyLarge.copy(
                        color = Color.Black,
                        fontWeight = FontWeight.Bold
                    )
                )

                //Designation of the instructor
                Text(
                    text = instructors.profession ?: "Unknown",
                    style = MaterialTheme.typography.bodyMedium.copy(
                        color = Color.Gray,
                        fontWeight = FontWeight.SemiBold
                    )
                )

                Spacer(Modifier.height(2.dp))

                if (isExpanded) {
                    // Rating and Reviews
                    Row {

                        //Experience
                        TextWithStartIconOne(
                            modifier = Modifier,
                            icon = Icons.Rounded.Star,
                            text = instructors.experience ?: ""
                        )

                        Spacer(Modifier.width(8.dp))

                        //Students
                        TextWithStartIconOne(
                            modifier = Modifier,
                            icon = Icons.Rounded.Star,
                            text = instructors.students ?: ""
                        )

                        Spacer(Modifier.width(8.dp))

                        //Ratings
                        TextWithStartIconOne(
                            modifier = Modifier,
                            icon = Icons.Rounded.Star,
                            text = instructors.reviews ?: ""
                        )

                    }
                } else {
                    // Rating and Reviews
                    TextWithStartIconOne(
                        modifier = Modifier,
                        icon = Icons.Rounded.Star,
                        text = instructors.experience ?: ""
                    )

                    Spacer(Modifier.height(4.dp))

                    //Students
                    TextWithStartIconOne(
                        modifier = Modifier,
                        icon = Icons.Rounded.Star,
                        text = instructors.students ?: ""
                    )

                    Spacer(Modifier.height(4.dp))

                    //Ratings
                    TextWithStartIconOne(
                        modifier = Modifier,
                        icon = Icons.Rounded.Star,
                        text = instructors.reviews ?: ""
                    )
                }

                Spacer(Modifier.height(8.dp))

                // Profile Description
                Text(
                    text = instructors.bio ?: "",
                    style = MaterialTheme.typography.bodyMedium.copy(
                        color = Color.Gray,
                        fontWeight = FontWeight.SemiBold
                    ),
                )

            }

        }


    }
}

@Composable
fun TextWithStartIconOne(
    modifier: Modifier,
    text: String,
    horizontalArrangement: Arrangement.Horizontal = Arrangement.Start,
    icon: ImageVector,
    iconModifier: Modifier = Modifier.size(Scale.GLOBAL_ICON_SIZE_SMALL),
    textStyle: TextStyle = TextStyle(
        fontSize = 15.sp,
        fontWeight = FontWeight.SemiBold,
        color = MaterialTheme.colorScheme.onSurface
    )
) {
    Row(
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = horizontalArrangement,
        modifier = modifier
    ) {
        Icon(
            imageVector = icon,
            contentDescription = null,
            tint = primaryDarkerColor,
            modifier = iconModifier
        )

        Spacer(modifier = Modifier.width(4.dp))

        Text(
            text = text,
            style = textStyle,
        )
    }
}