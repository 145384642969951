package tta.destinigo.talktoastro.feature_chat_consultation_new.domain

import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.feature_chat_consultation.data.request.GetChatDurationRequest
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.response.chat_duration.ChatDurationData
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.api.ChatConsultationApi
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow

class ChatConsultationInfoRepo(
    private val api: ChatConsultationApi
) {

    fun getChatConsultationInfo(chatDurationRequest: GetChatDurationRequest,isEmergency:Boolean): Flow<Resources<ChatDurationData>> {
        return flow {
            emit(Resources.Loading())
            val response = api.getChatDurationInfo(chatDurationRequest,isEmergency)
            emit(response)
            emit(Resources.Loading(false))
        }
    }

}