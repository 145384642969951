package tta.destinigo.talktoastro.feature_courses.core

import kotlinx.browser.document
import org.w3c.dom.HTMLScriptElement

actual fun injectFacebookEvent() {
    injectFacebookPixel()
}


fun injectFacebookPixel() {
    // Create the script element
    val scriptElement = document.createElement("script") as HTMLScriptElement
    scriptElement.async = true
    scriptElement.text = """
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '1657793731440511');
        fbq('track', 'PageView');
    """.trimIndent()

    // Append the script to the <head> or <body>
    document.head?.appendChild(scriptElement)

    // Optional: Add noscript fallback (usually not necessary for KMM projects)
    val noscriptFallback = """
        <noscript>
            <img height="1" width="1" style="display:none"
            src="https://www.facebook.com/tr?id=1657793731440511&ev=PageView&noscript=1"/>
        </noscript>
    """.trimIndent()
    document.body?.insertAdjacentHTML("beforeend", noscriptFallback)
}