package tta.destinigo.talktoastro.feature_expert_consultation.presentation.expert_detail_page.widget

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.AlertDialog
import androidx.compose.material3.Button
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import org.jetbrains.compose.resources.painterResource
import tta.destinigo.talktoastro.core.persentation.showToast
import tta.destinigo.talktoastro.core.theme.Scale
import tta.destinigo.talktoastro.core.theme.initializeCurrencyName
import tta.destinigo.talktoastro.feature_call_consultation.persentation.call_starter.CallHostPage
import tta.destinigo.talktoastro.feature_common.data.request.RequestConsultant
import tta.destinigo.talktoastro.feature_expert_consultation.presentation.expert_detail_page.ExpertDetailsPageComponent
import tta.destinigo.talktoastro.feature_expert_consultation.presentation.expert_detail_page.ExpertDetailsPageEvent
import tta.destinigo.talktoastro.resources.Res
import tta.destinigo.talktoastro.resources.emergency_icon
import kotlin.math.truncate

@Composable
fun EmergencyAssistanceBox(
    emergencyPrice: String?,
    EmergencyCallAblable: Boolean,
    expertData: RequestConsultant,
    component: ExpertDetailsPageComponent,
    onLoginClick: () -> Unit,
    onChatConsultation: (RequestConsultant) -> Unit,
    onCallConsultation: (RequestConsultant) -> Unit
) {
    val emg_buttonClic = remember { mutableStateOf(false) }
    val emg_buttonAfterClick = remember { mutableStateOf(false) }
    Box(
        modifier = Modifier.fillMaxWidth().border(
            border = BorderStroke(width = 1.dp, color = Color.Black),
            RoundedCornerShape(Scale.CORNER_SMALL)
        )
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .padding(16.dp)
        ) {
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(start = 16.dp)
            ) {
                Icon(
                    painter = painterResource(Res.drawable.emergency_icon), // Replace with your icon resource
                    contentDescription = "Emergency Icon",
                    modifier = Modifier.size(24.dp), // Adjust size as needed
                    tint = Color.Red // Adjust color as needed
                )
                Spacer(modifier = Modifier.width(8.dp)) // Add space between icon and text
                Text(
                    text = "Need emergency assistance?",
                    color = Color.Black,
                    fontSize = 15.sp,
                    fontWeight = FontWeight.Bold
                )
            }


            Row(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.SpaceBetween,
                verticalAlignment = Alignment.CenterVertically
            ) {
                Text(
                    text = "You can now connect with offline experts in urgent situation ${initializeCurrencyName()} ${emergencyPrice} . However, please note that in rare cases, experts may be unable to answer calls or chats due to unavoidable circumstances.", // Replace with string resource
                    color = Color.Gray,
                    fontSize = 16.sp,
                    maxLines = 4,
                    fontWeight = FontWeight.SemiBold,
                    overflow = TextOverflow.Ellipsis,
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(top = 8.dp)
                        .weight(1f)
                    // .visibility(visible = true) // Can be controlled based on condition
                )

                Button(
                    onClick = {
                        emg_buttonClic.value = true
                    },
                    modifier = Modifier
                        .padding(top = 8.dp),
                    shape = RoundedCornerShape(Scale.CORNER_SMALL)
                ) {
                    Text(
                        "Connect",
                        fontWeight = FontWeight.SemiBold,
                        fontSize = 16.sp
                    )
                }

                if (emg_buttonClic.value) {
                    AlertDialog(
                        containerColor = Color.White,
                        onDismissRequest = {
                            emg_buttonClic.value = false
                        },
                        text = {
                            EmergencyAssistanceDialog(onChatClick = {
                                if(EmergencyCallAblable){
                                    onChatConsultation.invoke(expertData)
                                    emg_buttonClic.value = false
                                }else{
                                    showToast("Expert ${expertData.full_name} unable to accept emergency constellation requests at this time")
                                }
                            },
                                onCloseClick = { emg_buttonClic.value = false },
                                onCallClick = {
                                    if(EmergencyCallAblable){
                                        emg_buttonAfterClick.value = true
                                        emg_buttonClic.value = false
                                        //showToast("Expert Available")
                                    }else{
                                        showToast("Expert ${expertData.full_name} unable to accept emergency constellation requests at this time")
                                    }
                                })
                        },
                        shape = RoundedCornerShape(Scale.CORNER_SMALL),
                        confirmButton = {},
                        dismissButton = {}
                    )
                }

                if (emg_buttonAfterClick.value){
                    CallHostPage(
                        isEmergency = true,
                        callDetails = expertData,
                        onLoginClicked = {
                            onLoginClick.invoke()
                        },
                        onDismiss = {
                           emg_buttonAfterClick.value = false
                        },
                        onStartCallConsultation = { onCallConsultation.invoke(expertData) },
                        context = component,
                        onNavigateRechargeCheckOut = { rechargeData ->
                            component.event(ExpertDetailsPageEvent.onRechargeRequired(rechargeData))
                        },
                        onVoIpCallStart = { duration, expertInfo ->
                            component.event(
                                ExpertDetailsPageEvent.OnVoIPCallConsultationStart(
                                    duration = duration,
                                    expertInfo = expertInfo
                                )
                            )
                        },
                        onNavigateCallWaitingPage = { CommonExpertInfo, onClose ->
                            //onNavigateCallWaitingPage.invoke(CommonExpertInfo,onClose)
                        }
                    )
                }
            }
        }
    }
}
