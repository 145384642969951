package tta.destinigo.talktoastro.feature_courses.domain

import tta.destinigo.talktoastro.core.date_time.formatDateDayOfWeek
import tta.destinigo.talktoastro.core.date_time.formatPrice
import tta.destinigo.talktoastro.core.date_time.formatReviewCount
import tta.destinigo.talktoastro.feature_courses.data.response.courses.CoursesData
import tta.destinigo.talktoastro.feature_courses.persentation.courses_page.CourseModel

fun CoursesData.toCourseModel(): CourseModel {
    return CourseModel(
        title = title,
        date = formatDateDayOfWeek(date),
        durationDays = "$durationDays Days",
        price = "₹${formatPrice(price)}",
        originalPrice = "₹${formatPrice(originalPrice)}",
        categoryType = categoryType,
        slug = slug,
        rating = rating,
        reviewsCount = "(${formatReviewCount(reviewsCount)} Students)",
        imagePath = imagePath,
    )
}