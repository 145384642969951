package tta.destinigo.talktoastro.core.razorpay

import tta.destinigo.talktoastro.feature_winyway_wallet.persentation.razorpay_gateway_new.PaymentGatewayArguments

actual fun startPayment(
    paymentGatewayArguments: PaymentGatewayArguments,
    onPaymentSuccess: (String, String, String) -> Unit,
    onPaymentFailed: (Throwable) -> Unit
) {
    injectRazorpayForm(paymentGatewayArguments, onPaymentSuccess, onPaymentFailed)
}