package tta.destinigo.talktoastro.feature_daily_shorts


import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.PlayArrow
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import coil3.compose.AsyncImage
import tta.destinigo.talktoastro.core.persentation.PlatformSpecificVideoView
import tta.destinigo.talktoastro.core.theme.Scale
import tta.destinigo.talktoastro.core.theme.primaryColor
import tta.destinigo.talktoastro.feature_common.persentation.home_tab.HomeScreenTabComponent
import tta.destinigo.talktoastro.feature_daily_shorts.data.response.InstaList
import tta.destinigo.talktoastro.feature_daily_shorts.data.response.Shorts
import tta.destinigo.talktoastro.feature_post.data.response.PostDataNew

@Composable
fun VideoCardList(modifier: Modifier, component: HomeScreenTabComponent, onSeeAllClick: () -> Unit, onReadPostClick: (PostDataNew) -> Unit) {
    Box(
        modifier = Modifier.fillMaxWidth(),
        contentAlignment = Alignment.Center
    ) {
        Column(
            modifier = modifier.padding(horizontal = 16.dp)
        ) {
            Row(
                modifier = Modifier,
                horizontalArrangement = Arrangement.SpaceBetween,
                verticalAlignment = Alignment.CenterVertically
            ) {
                Text(
                    text = "Daily Shorts",
                    textAlign = TextAlign.Start,
                    style = MaterialTheme.typography.titleMedium,
                    modifier = Modifier.padding(bottom = 16.dp)
                )
                Spacer(modifier = Modifier.weight(1f))
                Text(
                    text = "See All",
                    color = primaryColor,
                    style = MaterialTheme.typography.titleMedium,
                    textAlign = TextAlign.End,
                    modifier = Modifier.padding(bottom = 16.dp)
                        .clickable { onSeeAllClick.invoke() }
                )
            }

            LazyRow(
                modifier = Modifier.fillMaxWidth(),
                contentPadding = PaddingValues(horizontal = 8.dp),
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                items(component.dalyShorts.value.size) { inx ->
                    val data = component.dalyShorts.value[inx]
                    VideoCard(data)
                    Spacer(modifier = Modifier.width(10.dp))
                }

//                item {
//                    state.forEach { post ->
//                        BlogCard(post, onReadPostClick = { clickPost ->
//                            onReadPostClick.invoke(clickPost)
//                        })
//                        Spacer(modifier = Modifier.width(10.dp))
//                    }
//                }
            }
        }
    }
}


//@Composable
//fun VideoCard() {
//    Card(
//        modifier = Modifier
//            .width(150.dp)
//            .heightIn(min = 250.dp, max = 250.dp),
//        shape = RoundedCornerShape(Scale.CORNER_SMALL),
//        elevation = CardDefaults.cardElevation(Scale.ELEVATION_SMALL)
//    ) {
//        Box(modifier = Modifier.fillMaxSize()) {
//            val instagramScript = """
//                <iframe width="560" height="315" src="https://www.youtube.com/embed/OZYhWz2iwTQ?si=cKwQyPS-badrBsUn"
//                title="YouTube video player" frameborder="0"
//                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//                referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
//            """
//            val thumbnail = "https://live.winyway.com/backend/instagramReels/1735285032_reels.jpg"
//
////            // Call the function to render video with thumbnail as background
//            PlatformSpecificVideoView(instagramScript, thumbnail, Modifier)
//            AsyncImage(
//                model = thumbnail,
//                contentDescription = "Video Thumbnail",
//                modifier = Modifier.fillMaxSize(),
//                contentScale = ContentScale.Crop
//            )
//
//            PlatformSpecificVideoView(
//                instagramScript = "https://www.youtube.com/embed/OZYhWz2iwTQ?si=cKwQyPS-badrBsUn",
//                thumbnail = thumbnail,
//                modifier = Modifier
//            )
//        }
//    }
//}


@Composable
fun VideoCard(dalyShorts: InstaList) {
    var isVideoPlaying by remember { mutableStateOf(false) }

    Card(
        modifier = Modifier
            .width(150.dp)
            .heightIn(min = 250.dp, max = 250.dp),
        shape = RoundedCornerShape(Scale.CORNER_SMALL),
        elevation = CardDefaults.cardElevation(Scale.ELEVATION_SMALL)
    ) {
        Box(modifier = Modifier.fillMaxSize()) {
            val data = parseEmbedCode(insta = dalyShorts)

            // Prepare video and thumbnail URLs
            //val videoUrl = "https://www.youtube.com/embed/${data.videoId}?autoplay=1&controls=1"
           // val videoUrl = "https://www.youtube.com/embed/${data.videoId}?autoplay=1&mute=1"  // Autoplay with mute
            val thumbnailUrl = "https://img.youtube.com/vi/${data.videoId}/hqdefault.jpg"

            if (isVideoPlaying) {
                // Render the video when playing
                PlatformSpecificVideoView(
                    instagramScript = data.videoId.toString(),
                    thumbnail = thumbnailUrl,
                    modifier = Modifier.fillMaxSize()
                )
            } else {
                // Show the thumbnail with a play button overlay
                Box(
                    modifier = Modifier
                        .fillMaxSize()
                        .clickable {
                            isVideoPlaying = true // Start playing the video on click
                        }
                ) {
                    AsyncImage(
                        model = data.thumbnail,
                        contentDescription = "Video Thumbnail",
                        modifier = Modifier.fillMaxSize(),
                        contentScale = ContentScale.Crop
                    )
                    // Play button overlay
                    Box(
                        modifier = Modifier
                            .align(Alignment.Center)
                            .size(50.dp)
                            .background(Color.Black.copy(alpha = 0.6f), CircleShape)
                    ) {
                        Icon(
                            imageVector = Icons.Default.PlayArrow,
                            contentDescription = "Play Video",
                            tint = Color.White,
                            modifier = Modifier.align(Alignment.Center)
                        )
                    }
                }
            }
        }
    }
}


fun parseEmbedCode(insta: InstaList): Shorts {
    insta.embedCode?.let { embedCode ->
        // Extract video ID
        val videoId = embedCode.substringAfter("embed/").substringBefore("?")
        // Extract title
        val title = embedCode.substringAfter("title=\"").substringBefore("\"")
        // Extract src
        val src = embedCode.substringAfter("src=\"").substringBefore("\"")
        // Extract allow attributes
        val allow = embedCode.substringAfter("allow=\"").substringBefore("\"")
        // Extract referrerpolicy
        val referrerPolicy = embedCode.substringAfter("referrerpolicy=\"").substringBefore("\"")
        // Extract allowfullscreen (true if found, otherwise false)
        val allowFullscreen = embedCode.contains("allowfullscreen")

        // Return a Shorts object with the extracted data
        return Shorts(
            title = title,
            videoId = videoId,
            thumbnail = insta.thumbnail,
            expertName = insta.expertName,
            expertImage = insta.profileImage
        )
    }

    // Return a default Shorts object if embedCode is null
    return Shorts(
        title = null,
        videoId = null,
        thumbnail = insta.thumbnail,
        expertName = insta.expertName,
        expertImage = insta.profileImage
    )
}


//@Composable
//fun VideoCard() {
//    Card(
//        modifier = Modifier
//            .width(150.dp)
//            .heightIn(min = 250.dp, max = 250.dp),
//        shape = RoundedCornerShape(Scale.CORNER_SMALL),
//        elevation = CardDefaults.cardElevation(Scale.ELEVATION_SMALL)
//    ) {
//        Box(modifier = Modifier.fillMaxSize()) {
//
//            val instagramScript = """
//    <iframe width="560" height="315" src="https://www.youtube.com/embed/OZYhWz2iwTQ?si=cKwQyPS-badrBsUn"
//    title="YouTube video player" frameborder="0"
//    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//    referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
//"""
//
//            val thumbnail = "https://live.winyway.com/backend/instagramReels/1735285032_reels.jpg"
//
//// Call the function
//        PlatformSpecificVideoView(instagramScript, thumbnail, Modifier)
            // Background Image
//            Image(
//                painter = painterResource(Res.drawable.post_banner),
//                contentDescription = null,
//                contentScale = ContentScale.Crop,
//                modifier = Modifier.fillMaxSize()
//            )
//
//            // Transparent Overlay
//            Box(
//                modifier = Modifier
//                    .fillMaxSize()
//                    .background(color = Color.Black.copy(alpha = 0.5f))
//            )
//
//            Column(
//                modifier = Modifier
//                    .fillMaxSize()
//                    .padding(8.dp),
//                verticalArrangement = Arrangement.SpaceBetween
//            ) {
//                // Expert Information Row
//                Row(
//                    verticalAlignment = Alignment.CenterVertically
//                ) {
//                    Image(
//                        imageVector = Icons.Default.Person,
//                        contentDescription = null,
//                        modifier = Modifier
//                            .size(22.dp)
//                            .clip(CircleShape)
//                            .background(color = Color.Gray)
//                    )
//
//                    Spacer(modifier = Modifier.width(6.dp))
//
//                    Text(
//                        text = "By - Astrologer Deepak",
//                       // style = FontStyle.Italic,
//                        color = Color.White,
//                        maxLines = 1,
//                        overflow = TextOverflow.Ellipsis
//                    )
//                }
//
//                // Title Text
//                Text(
//                    text = "This is bold text to show title of video",
//                    //style = androidx.compose.material.MaterialTheme.typography.body1,
//                    color = Color.White,
//                    maxLines = 2,
//                    overflow = TextOverflow.Ellipsis
//                )
//            }
//       }
//    }
//}


//@Composable
//fun VideoCardWithLink(
//    videoData: InstaList,
//    //webContentLoader: WebContentLoader,
//    modifier: Modifier = Modifier
//) {
//    Div(attrs = {
//        style {
//            width(126.px)
//            height(224.px)
//            borderRadius(8.px)
//            boxShadow(0.px, 2.px, 4.px, rgba(0, 0, 0, 0.2))
//        }
//    }) {
//        // Load Instagram Script
//        webContentLoader.LoadContent(videoData.instagramScript)
//
//        // Title Text
//        Div(attrs = {
//            style {
//                color("white")
//                fontSize(16.px)
//                overflow("hidden")
//                textOverflow("ellipsis")
//                display("-webkit-box")
//                property("-webkit-line-clamp", "2")
//                property("-webkit-box-orient", "vertical")
//            }
//        }) {
//            Text(videoData.title)
//        }
//    }
//}

