package tta.destinigo.talktoastro.feature_courses.di

import org.koin.dsl.module
import tta.destinigo.talktoastro.feature_courses.data.CoursesApi
import tta.destinigo.talktoastro.feature_courses.domain.CourseRepo
import tta.destinigo.talktoastro.feature_courses.domain.CoursesRepo
import tta.destinigo.talktoastro.feature_courses.domain.LandingPageRepo

val landingPageModel = module {
    single { CoursesApi() }
    factory { LandingPageRepo(get()) }
    factory { CoursesRepo(get()) }
    factory { CourseRepo(get()) }
}