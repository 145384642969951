package tta.destinigo.talktoastro.feature_post.domain

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.feature_post.data.post_api_service.PostApiService
import tta.destinigo.talktoastro.feature_post.data.request.GetAllCommentRequest
import tta.destinigo.talktoastro.feature_post.data.request.LikeRequest
import tta.destinigo.talktoastro.feature_post.data.request.PostRequest
import tta.destinigo.talktoastro.feature_post.data.request.SubmitCommentResponse
import tta.destinigo.talktoastro.feature_post.data.response.Comment
import tta.destinigo.talktoastro.feature_post.data.response.PostDetailsResponseData
import tta.destinigo.talktoastro.feature_post.data.response.PostLikeData
import tta.destinigo.talktoastro.feature_post.data.response.PostPagingData

class PostListRepo(private val api: PostApiService) {
    fun getPostListData(request: PostRequest): Flow<Resources<PostPagingData>> {
        return flow {
            emit(Resources.Loading(true))
            val response = api.requestGetAllPost(request)
            emit(response)
            emit(Resources.Loading(false))

        }
    }

    fun addLick(request: LikeRequest): Flow<Resources<PostLikeData>> {
        return flow {
            emit(Resources.Loading(true))
            val response = api.addLick(request)
            emit(response)
            emit(Resources.Loading(false))

        }
    }

    fun postDetails(id: String): Flow<Resources<PostDetailsResponseData>> {
        return flow {
            emit(Resources.Loading(true))
            val response = api.getPostDetails(id)
            emit(response)
            emit(Resources.Loading(false))

        }
    }

    fun getAllCommentRepo(id: String): Flow<Resources<List<Comment>>> {
        return flow {
            emit(Resources.Loading(true))
            val response = api.getAllComment(id)
            emit(response)
            emit(Resources.Loading(false))

        }
    }

    fun submitPostComment(request: SubmitCommentResponse): Flow<Resources<Boolean>> {
        return flow {
            emit(Resources.Loading(true))
            val response = api.submitComment(request)
            emit(response)
            emit(Resources.Loading(false))

        }
    }

    //submitShare

    fun addShare(request: GetAllCommentRequest): Flow<Resources<PostLikeData>> {
        return flow {
            emit(Resources.Loading(true))
            val response = api.submitShare(request = request)
            emit(response)
            emit(Resources.Loading(false))
        }
    }

}