package tta.destinigo.talktoastro.feature_call_consultation.persentation.call_starter

import androidx.compose.runtime.Composable
import com.arkivanov.decompose.ComponentContext
import tta.destinigo.talktoastro.core.data.CommonExpertInfo
import tta.destinigo.talktoastro.core.local.UserInfoProvider
import tta.destinigo.talktoastro.core.persentation.LoginDialog
import tta.destinigo.talktoastro.feature_call_consultation.persentation.call_bottom_sheet_info.CallConsultationBottomSheet
import tta.destinigo.talktoastro.feature_call_consultation.persentation.call_bottom_sheet_info.CallConsultationComponent
import tta.destinigo.talktoastro.feature_common.data.request.RequestConsultant
import tta.destinigo.talktoastro.feature_winyway_wallet.persentation.recharge_pack.data.response.RechargeData

@Composable
fun CallHostPage(
    callDetails: RequestConsultant,
    isEmergency :Boolean = false,
    onLoginClicked: () -> Unit,
    onDismiss: () -> Unit,
    onStartCallConsultation: () -> Unit,
    context: ComponentContext,
    onNavigateRechargeCheckOut: (RechargeData?) -> Unit,
    onVoIpCallStart: (String, CommonExpertInfo) -> Unit,
    onNavigateCallWaitingPage: (CommonExpertInfo,()->Unit) -> Unit
) {

    val isUserLogin = UserInfoProvider.getIsLogin()
    if (isUserLogin.not()) {
        LoginDialog(onLoginClicked = {
            onLoginClicked.invoke()
        }, onSignUpClicked = {
            onLoginClicked.invoke()
        }, onDismiss = {
            onDismiss.invoke()
        })
        return
    }

    CallConsultationBottomSheet(CallConsultationComponent(context = context,
        _isEmergency = isEmergency,
        _expertId = callDetails.id.toString(),
        _expertName = callDetails.full_name.toString(),
        _expertImage = callDetails.profile_image_url ?: callDetails.profile_image!!,
        _expertSkills = callDetails.skills.toString(),
        onClose = {
            onDismiss.invoke()
        },
        onNavigateRechargeCheckOut = { rechargeData ->
            onNavigateRechargeCheckOut.invoke(rechargeData)
        },
        onLetsConnectButtonClick = {
            onStartCallConsultation.invoke()
        },
        onNavigateChoseRechargePack = {
            onNavigateRechargeCheckOut.invoke(null)
        },
        onVoIpCallStart = {
            onVoIpCallStart.invoke(
                it, CommonExpertInfo(
                    expertId = callDetails.id.toString(),
                    expertName = callDetails.full_name.toString(),
                    expertProfileImageUrl = callDetails.profile_image_url.toString(),
                    expertSkills = callDetails.skills.toString()
                )
            )
        },
        onNavigateCallWaitingPage = {  _expertId,_expertName,_expertImage,_expertSkills,onClose ->
            onNavigateCallWaitingPage.invoke(CommonExpertInfo(expertId = _expertId, expertName = _expertName,expertProfileImageUrl = _expertImage, expertSkills = _expertSkills),onClose)
        }

    ))

}