package tta.destinigo.talktoastro.feature_call_consultation.persentation.call_bottom_sheet_info
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Modifier
import co.touchlab.kermit.Logger
import tta.destinigo.talktoastro.core.persentation.LoadingProgressDialog
import tta.destinigo.talktoastro.feature_call_consultation.persentation.call_starter.CallInfoSuccessBottomSheet
import tta.destinigo.talktoastro.feature_call_consultation.persentation.call_bottomsheet.widget.LowOnFreeCallConsultation
import tta.destinigo.talktoastro.feature_call_consultation.persentation.expert_busy.ErrorBottomSheetCallConsultation
import tta.destinigo.talktoastro.feature_call_consultation.persentation.widget.CallRechargeNowDialog
import tta.destinigo.talktoastro.feature_winyway_wallet.persentation.recharge_pack.data.response.RechargeData


@Composable
fun CallConsultationBottomSheet(component: CallConsultationComponent) {
    val state by component.state.collectAsState()
   // val context = LocalContext.current

    when (val currentState = state) {

        is CallInfoBottomSheetState.Loading -> {
            if (currentState.isLoading) {
                LoadingProgressDialog { }
            }
        }

        is CallInfoBottomSheetState.NormalCallLowBalance -> {
           Logger.d("Call Low Balance State")
            CallRechargeNowDialog(
                rechargeData = currentState.rechargeData,
                onDismissRequest = {
                    component.onClosePage()
                },
                data = currentState.data,
                onRechargeSelected = { selectedAmount ->
                    Logger.d("selected amount - $selectedAmount")
                    component.event(CallConsultationEvent.onNavigateRechargeCheckOut(selectedAmount))
                }
            )
        }

        is CallInfoBottomSheetState.CallInfoSuccess -> {
            CallInfoSuccessBottomSheet(
                consultationComponent = component,
                callDurationData = currentState.data,
                onDismiss = {
                    component.onClosePage()
                }
            )
        }

        is CallInfoBottomSheetState.Error -> {
            ErrorBottomSheetCallConsultation(msg = currentState.msg, onDismiss = { component.onClosePage()},component)
        }

        is CallInfoBottomSheetState.FreeCallLowBalance -> {

            val planData = RechargeData(
                amount = currentState.data.plan_amount,
                id = currentState.data.planId,
                planName = currentState.data.plan_name,
                null
            )

            LowOnFreeCallConsultation(
                modifier = Modifier,
                expertName = component.expertName,
                expertSkills = component.expertSkills,
                expertImage = component.expertImage,
                callDurationData = currentState.data,
                onSubmit = {
                    component.event(CallConsultationEvent.onNavigateRechargeCheckOut(planData))
                },
                onDismiss = {
                    component.onClosePage()
                }
            )
        }
    }
}
