package tta.destinigo.talktoastro.feature_courses.persentation.course_page.widget.dailog

import androidx.compose.foundation.layout.size
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Report
import androidx.compose.material3.AlertDialog
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import tta.destinigo.talktoastro.core.persentation.CommonButton
import tta.destinigo.talktoastro.core.theme.primaryDarkerColor

@Composable
fun FailureBuyDialog(
    errorMsg:String,
    onDismiss: () -> Unit
) {

    AlertDialog(
        onDismissRequest = {
            onDismiss.invoke()
        },
        icon = {
            Icon(
                imageVector = Icons.Default.Report,
                contentDescription = "Success Icon",
                tint = Color.Red,
                modifier = Modifier.size(48.dp)
            )
        },
        title = {
            Text(
                text = "Enrollment Failed !",
                style = MaterialTheme.typography.titleMedium,
                fontWeight = FontWeight.Bold
            )
        },
        text = {
            Text(
                text = errorMsg,
                style = MaterialTheme.typography.bodyMedium,
                textAlign = TextAlign.Center
            )
        },
        confirmButton = {
            CommonButton(
                onClick = {
                    onDismiss.invoke()
                },
                buttonText = "Ok",
                modifier = Modifier,
                buttonColor = primaryDarkerColor
            )
        }
    )

}
