package tta.destinigo.talktoastro.feature_common.persentation.more_tab.refer_and_earn.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ReferEarnWeeklyWinnersResponse(
    @SerialName("status")val status:Boolean = false,
    @SerialName("message")val msg:String = "Unknown Error",
    @SerialName("code")val code:Int? = 0,
    @SerialName("data") val referEarnData: ReferEarnData?,
)

@Serializable
data class ReferEarnData(
    @SerialName("refer_code") val referCode: String?,
    @SerialName("topuser") val weeklyWinners: List<WeeklyWinners>?
)

@Serializable
data class WeeklyWinners(
    @SerialName("id")
    val id: Int?,
    @SerialName("image")
    val image: String?,
    @SerialName("name")
    val name: String?,
    @SerialName("coin")
    val coin: Int?,
    @SerialName("balance")
    val balance: Int?
)
