package tta.destinigo.talktoastro.feature_courses.persentation.course_page.widget.registration_form

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Cancel
import androidx.compose.material.icons.filled.ClearAll
import androidx.compose.material.icons.filled.Discount
import androidx.compose.material.icons.filled.Email
import androidx.compose.material.icons.filled.Person
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.OutlinedTextFieldDefaults
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.drawBehind
import androidx.compose.ui.geometry.CornerRadius
import androidx.compose.ui.geometry.Rect
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathEffect
import androidx.compose.ui.graphics.drawscope.Stroke
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import tta.destinigo.talktoastro.core.local.UserInfoProvider
import tta.destinigo.talktoastro.core.persentation.CommonLoadingButton
import tta.destinigo.talktoastro.core.persentation.MobileWithCountryCodeEditTextSingle
import tta.destinigo.talktoastro.core.theme.GreenDark20
import tta.destinigo.talktoastro.core.theme.Scale
import tta.destinigo.talktoastro.core.theme.green_success_color_light
import tta.destinigo.talktoastro.core.theme.primaryDarkerColor
import tta.destinigo.talktoastro.core.theme.white
import tta.destinigo.talktoastro.feature_courses.persentation.course_page.widget.dailog.CouponDialog
import tta.destinigo.talktoastro.feature_courses.persentation.course_page.CourseComponent
import tta.destinigo.talktoastro.feature_courses.persentation.course_page.CourseInfo
import tta.destinigo.talktoastro.feature_courses.persentation.course_page.RegistrationEvent
import tta.destinigo.talktoastro.feature_courses.persentation.course_page.widget.dailog.FailureBuyDialog
import tta.destinigo.talktoastro.feature_courses.persentation.course_page.widget.dailog.SuccessBuyDialog
import tta.destinigo.talktoastro.feature_courses.persentation.course_page.widget.dailog.SuccessDialog

@Composable
fun RegistrationForm(
    course: CourseInfo,
    modifier: Modifier = Modifier,
    onClickSubmit: (name: String, email: String, mobile: String, countryCode: String) -> Unit,
    onClickEnroll: (name: String, email: String, mobile: String, countryCode: String, couponCode: String?) -> Unit,
    component: CourseComponent
) {

    // State for email input and error
    var email by remember { mutableStateOf("") }
    var name by remember { mutableStateOf("") }
    var mobile by remember { mutableStateOf("") }
    var selectedCountryCode by remember { mutableStateOf("+91") }
    var emailError by remember { mutableStateOf<String?>(null) }
    var nameError by remember { mutableStateOf<String?>(null) }
    var mobileError by remember { mutableStateOf<String?>(null) }
    var showCouponDialog by remember { mutableStateOf(false) }


    fun setInitialDetail() {

        fun getCountryCode(): String {
            val mobileCode = UserInfoProvider.getMobileCode().trim()
            return if (mobileCode.equals("+", true)) "Country" else mobileCode
        }

        email = UserInfoProvider.getEmail()
        name = UserInfoProvider.getFullName()
        mobile = UserInfoProvider.getPhone()
        selectedCountryCode = getCountryCode()
    }

    if (UserInfoProvider.getIsLogin()) setInitialDetail()

    val formSubmitEvent by component.event.collectAsState()
    val formBuyEvent by component.eventBuy.collectAsState()

    if (formSubmitEvent is RegistrationEvent.OnSuccess) {
        SuccessDialog {
            name = ""
            email = ""
            mobile = ""
            emailError = null
            nameError = null
            mobileError = null
            component.setForEventInitial()
        }
    }

    if (formBuyEvent is RegistrationEvent.OnSuccess) {
        SuccessBuyDialog(
            onDismiss = {
                name = ""
                email = ""
                mobile = ""
                emailError = null
                nameError = null
                mobileError = null
                component.setForBuyEventInitial()
                component.onCancelCoupon(course)
            }
        )
    }

    if (formSubmitEvent is RegistrationEvent.OnError) {
        val msg = (formSubmitEvent as RegistrationEvent.OnError).msg
        FailureBuyDialog(errorMsg = msg) {
            component.setForEventInitial()
        }
    }

    if (formBuyEvent is RegistrationEvent.OnError) {
        val msg = (formBuyEvent as RegistrationEvent.OnError).msg
        FailureBuyDialog(errorMsg = msg) {
            component.setForBuyEventInitial()
        }
    }

    if (showCouponDialog) {
        CouponDialog(
            component = component,
            onApplyCoupon = {
                component.validateCoupon(
                    course = course,
                    coupon = it
                )
            },
            onDismiss = {
                showCouponDialog = false
                component.setCouponNull()
            }
        )
    }

    Surface(
        shape = RoundedCornerShape(Scale.CORNER_SMALL),
        color = primaryDarkerColor,
        border = BorderStroke(
            width = 1.dp, color = primaryDarkerColor
        ),
        modifier = modifier
    ) {
        Column(
            modifier = Modifier.background(Color.White)
        ) {
            // Header
            Text(
                text = "REGISTRATION FORM",
                color = Color.White,
                fontWeight = FontWeight.Bold,
                textAlign = TextAlign.Center,
                style = MaterialTheme.typography.bodySmall.copy(fontSize = 16.sp),
                modifier = Modifier.fillMaxWidth().background(primaryDarkerColor)
                    .padding(vertical = 16.dp)
            )

            Column(
                modifier = Modifier.padding(24.dp)
            ) {

                // Name Field
                OutlinedTextField(
                    value = name,
                    onValueChange = {
                        name = it
                        nameError = null
                    },
                    label = { Text("Enter your name") },
                    placeholder = { Text("e.g., John Doe") },
                    singleLine = true,
                    isError = nameError != null,
                    leadingIcon = {
                        Icon(Icons.Default.Person, contentDescription = "Name Icon")
                    },
                    trailingIcon = {
                        if (name.trim().isNotEmpty()) {
                            IconButton(onClick = {
                                name = ""
                            }) {
                                Icon(Icons.Default.ClearAll, contentDescription = "Check Icon")
                            }
                        }
                    },
                    supportingText = {
                        if (nameError != null) {
                            Text(
                                text = nameError!!,
                                color = MaterialTheme.colorScheme.error,
                                style = MaterialTheme.typography.bodySmall
                            )
                        }
                    },
                    modifier = Modifier.fillMaxWidth(),
                    colors = OutlinedTextFieldDefaults.colors(
                        focusedContainerColor = Color.Transparent,
                        unfocusedContainerColor = Color.Transparent,
                        focusedBorderColor = Color(0xFF112B60),
                        unfocusedBorderColor = Color.Gray,
                    )
                )

                Spacer(modifier = Modifier.height(8.dp))

                // Email Field with Validation
                OutlinedTextField(
                    value = email,
                    onValueChange = {
                        email = it
                        emailError = validateEmail(it)
                    },
                    singleLine = true,
                    label = { Text("Enter your email") },
                    placeholder = { Text("e.g., john.doe@example.com") },
                    isError = emailError != null,
                    leadingIcon = {
                        Icon(Icons.Default.Email, contentDescription = "Email Icon")
                    },
                    trailingIcon = {
                        if (email.trim().isNotEmpty()) {
                            IconButton(onClick = {
                                email = ""
                            }) {
                                Icon(Icons.Default.ClearAll, contentDescription = "Clear Icon")
                            }
                        }
                    },
                    supportingText = {
                        if (emailError != null) {
                            Text(
                                text = emailError!!,
                                color = MaterialTheme.colorScheme.error,
                                style = MaterialTheme.typography.bodySmall
                            )
                        } else {
                            Text(
                                text = "We will not share your email with anyone.",
                                style = MaterialTheme.typography.bodySmall.copy(color = Color.Gray)
                            )
                        }
                    },
                    modifier = Modifier.fillMaxWidth(),
                    colors = OutlinedTextFieldDefaults.colors(
                        focusedContainerColor = Color.Transparent,
                        unfocusedContainerColor = Color.Transparent,
                        focusedBorderColor = if (emailError != null) MaterialTheme.colorScheme.error else Color(
                            0xFF112B60
                        ),
                        unfocusedBorderColor = if (emailError != null) MaterialTheme.colorScheme.error else Color.Gray,
                    )
                )

                Spacer(modifier = Modifier.height(8.dp))

                // Whatsapp Mobile Number Field
                MobileWithCountryCodeEditTextSingle(
                    modifier = Modifier,
                    selectedCountry = selectedCountryCode,
                    mobileText = mobile,
                    label = {
                        Text("WhatsApp Number")
                    },
                    placeHolder = {
                        Text("e.g., 9876543210")
                    },
                    supportingText = {
                        if (mobileError != null) {
                            Text(
                                text = mobileError!!,
                                color = MaterialTheme.colorScheme.error,
                                style = MaterialTheme.typography.bodySmall
                            )
                        } else {
                            Text(
                                text = "WhatsApp is required for instant updates.",
                                style = MaterialTheme.typography.bodySmall.copy(color = Color.Gray)
                            )
                        }
                    },
                    onMobileChange = { enter ->
                        if (enter.all { it.isDigit() }) {
                            mobile = enter
                        }
                        mobileError = null
                    },
                    onCountryCodeChange = {
                        selectedCountryCode = it
                        mobileError = null
                    },
                    isError = mobileError != null
                )

                Spacer(modifier = Modifier.height(16.dp))

                if (course.couponApplied != null) {
                    AppliedCouponButton(
                        text = course.couponApplied.toString()
                    ) {
                        component.onCancelCoupon(course)
                    }
                } else {
                    ApplyCouponButton {
                        showCouponDialog = true
                    }
                }


                Spacer(modifier = Modifier.height(16.dp))


                // Summary Section
                Text(
                    text = "Summary",
                    style = MaterialTheme.typography.titleMedium,
                    fontWeight = FontWeight.Bold,
                    modifier = Modifier.padding(bottom = 8.dp)
                )
                SummaryRow("Actual Price", course.originalPrice, isHighlight = false)
                SummaryRow("Offer Discount", "- ${course.discountPrice}", isHighlight = false)
                if (course.couponApplied != null) {
                    SummaryRow(
                        "Coupon Apply",
                        "- ${course.couponAppliedDiscount}",
                        isHighlight = true
                    )
                }
                SummaryRow("Savings", course.savingPrice.toString(), isHighlight = false)
                SummaryRow("Gst (18%)", "+ ${course.gstAmount.toString()}", isHighlight = false)
                SummaryRow("Final Price", course.gstPercentage.toString(), isHighlight = true)

                Spacer(modifier = Modifier.height(16.dp))

                // Enroll Buttons
                CommonLoadingButton(
                    text = "Enroll Now @ ${course.gstPercentage}",
                    onClick = {

                        if (nameError != null || name.trim().isEmpty()) {
                            // Proceed with valid email
                            nameError = "Please enter Name."
                        }

                        if (emailError != null || email.trim().isEmpty()) {
                            // Proceed with valid email
                            emailError = "Please enter a valid email."
                        }

                        if (mobileError != null || mobile.trim().isEmpty()) {
                            // Proceed with valid email
                            mobileError = "Please enter a valid mobile."
                            return@CommonLoadingButton
                        }

                        if (selectedCountryCode.trim()
                                .isEmpty() || selectedCountryCode.equals("Country", true)
                        ) {
                            // Proceed with valid email
                            mobileError = "Please Select Country Code"
                            return@CommonLoadingButton
                        }

                        if (name.trim().isNotEmpty() && email.trim().isNotEmpty() && mobile.trim()
                                .isNotEmpty()
                        ) {
                            onClickEnroll.invoke(
                                name, email, mobile, selectedCountryCode, course.couponApplied
                            )
                        }
                    },
                    modifier = Modifier.fillMaxWidth(),
                    buttonColors = ButtonDefaults.buttonColors(
                        containerColor = primaryDarkerColor,
                        contentColor = white,
                    ),
                    isLoading = (formBuyEvent is RegistrationEvent.IsLoading)
                )

                Spacer(modifier = Modifier.height(16.dp))

                // Enroll Buttons
                CommonLoadingButton(
                    text = "Enroll Now",
                    onClick = {

                        if (nameError != null || name.trim().isEmpty()) {
                            // Proceed with valid email
                            nameError = "Please enter Name."
                        }

                        if (emailError != null || email.trim().isEmpty()) {
                            // Proceed with valid email
                            emailError = "Please enter a valid email."
                        }

                        if (mobileError != null || mobile.trim().isEmpty()) {
                            // Proceed with valid email
                            mobileError = "Please enter a valid mobile."
                            return@CommonLoadingButton
                        }

                        if (selectedCountryCode.trim()
                                .isEmpty() || selectedCountryCode.equals("Country", true)
                        ) {
                            // Proceed with valid email
                            mobileError = "Please Select Country Code"
                            return@CommonLoadingButton
                        }

                        if (name.trim().isNotEmpty() && email.trim().isNotEmpty() && mobile.trim()
                                .isNotEmpty()
                        ) {
                            onClickSubmit.invoke(
                                name, email, mobile, selectedCountryCode
                            )
                        }
                    },
                    modifier = Modifier.fillMaxWidth(),
                    buttonColors = ButtonDefaults.buttonColors(
                        containerColor = primaryDarkerColor,
                        contentColor = white,
                    ),
                    isLoading = (formSubmitEvent is RegistrationEvent.IsLoading)
                )

                Spacer(modifier = Modifier.height(4.dp))
            }
        }
    }
}

@Composable
fun AppliedCouponButton(
    text: String = "Apply Coupon",
    onClick: () -> Unit
) {
    Box(
        modifier = Modifier.fillMaxWidth()
            .dottedBorder(
                strokeWidth = 1.dp,
                color = Color.Black.copy(alpha = 0.8f),
                cornerRadius = Scale.CORNER_SMALL
            )
            .background(
                color = green_success_color_light.copy(alpha = 0.5f),
                shape = RoundedCornerShape(Scale.CORNER_SMALL)
            ),
    ) {
        Row(
            modifier = Modifier.fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.SpaceBetween
        ) {
            Row(
                modifier = Modifier.weight(1f),
                verticalAlignment = Alignment.CenterVertically
            ) {
                Spacer(modifier = Modifier.width(12.dp))
                Icon(
                    imageVector = Icons.Filled.Discount,
                    contentDescription = null,
                    modifier = Modifier.size(Scale.GLOBAL_ICON_SIZE_SMALL),
                    tint = Color.Black.copy(alpha = 0.8f)
                )
                Spacer(modifier = Modifier.width(8.dp))
                Text(
                    text = text,
                    color = Color.Black.copy(alpha = 0.8f),
                    style = MaterialTheme.typography.bodyMedium.copy(
                        fontWeight = FontWeight.Bold
                    )
                )
            }

            IconButton(
                modifier = Modifier.wrapContentWidth(),
                onClick = { onClick.invoke() }
            ) {
                Icon(
                    imageVector = Icons.Filled.Cancel,
                    contentDescription = null,
                    modifier = Modifier.size(Scale.GLOBAL_ICON_SIZE),
                    tint = Color.Black.copy(alpha = 0.8f)
                )
            }
        }
    }

}

@Composable
fun ApplyCouponButton(
    text: String = "Apply Coupon",
    onClick: () -> Unit
) {

    Box(
        modifier = Modifier
            .dottedBorder(
                strokeWidth = 1.dp,
                color = Color.Black.copy(alpha = 0.8f),
                cornerRadius = Scale.CORNER_SMALL
            )
            .background(Color.Transparent)
            .clickable(onClick = onClick),
    ) {
        Row(
            modifier = Modifier.padding(vertical = 6.dp, horizontal = 10.dp),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.Center
        ) {
            Icon(
                imageVector = Icons.Filled.Discount,
                contentDescription = null,
                modifier = Modifier.size(Scale.GLOBAL_ICON_SIZE_SMALL),
                tint = Color.Black.copy(alpha = 0.8f)
            )
            Spacer(modifier = Modifier.width(8.dp))
            Text(
                text = text,
                color = Color.Black.copy(alpha = 0.8f),
                style = MaterialTheme.typography.bodyMedium.copy(
                    fontWeight = FontWeight.Bold
                )
            )
        }
    }

}

fun Modifier.dottedBorder(
    color: Color,
    strokeWidth: Dp,
    cornerRadius: Dp = 0.dp,
    gap: Dp = 4.dp,
    dashLength: Dp = 5.dp
): Modifier = drawBehind {
    val strokePx = strokeWidth.toPx()
    val dashPx = dashLength.toPx()
    val gapPx = gap.toPx()
    val cornerRadiusPx = cornerRadius.toPx()

    val outlineRect = Rect(
        left = strokePx / 2,
        top = strokePx / 2,
        right = size.width - strokePx / 2,
        bottom = size.height - strokePx / 2
    )

    // Drawing the border using dashed lines
    drawRoundRect(
        color = color,
        topLeft = outlineRect.topLeft,
        size = outlineRect.size,
        cornerRadius = CornerRadius(cornerRadiusPx, cornerRadiusPx),
        style = Stroke(
            width = strokePx,
            pathEffect = PathEffect.dashPathEffect(floatArrayOf(dashPx, gapPx), phase = 0f)
        )
    )
}


// Email validation function
fun validateEmail(email: String): String? {
    val emailRegex = "^[A-Za-z0-9+_.-]+@[A-Za-z0-9.-]+$".toRegex()
    return if (email.isBlank()) {
        "Email cannot be empty."
    } else if (!email.matches(emailRegex)) {
        "Invalid email format."
    } else {
        null
    }
}


@Composable
fun SummaryRow(label: String, value: String, isHighlight: Boolean = false) {
    Row(
        modifier = Modifier.fillMaxWidth(), horizontalArrangement = Arrangement.SpaceBetween
    ) {
        Text(
            text = label,
            style = MaterialTheme.typography.bodyMedium,
            color = if (isHighlight) GreenDark20 else Color.Black
        )
        Text(
            text = value,
            style = MaterialTheme.typography.bodyMedium,
            fontWeight = FontWeight.Bold,
            color = if (isHighlight) GreenDark20 else Color.Black
        )
    }
}
