package tta.destinigo.talktoastro.feature_courses.data.request

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


/**
 * Data class representing a request to buy a course. This class is used to send necessary information
 * to the server for processing the course purchase.
 *
 * @param courseId The unique identifier of the course to be purchased.
 * @param userName The name of the user purchasing the course. This field is required if the user is a guest.
 * @param userEmail The email address of the user purchasing the course. This field is required if the user is a guest.
 * @param mobile The mobile number of the user purchasing the course. This field is required if the user is a guest.
 *
 * @see tta.destinigo.talktoastro.feature_courses.data.repository.CourseRepository.buyCourse
 */
@Serializable
data class CourseBuyRequest(
    @SerialName("course_id") val courseId: String,
    @SerialName("user_name") val userName: String,
    @SerialName("user_email") val userEmail: String,
    @SerialName("country_code") val countryCode: String,
    @SerialName("mobile") val mobile: String,
    @SerialName("coupon_code") val couponCode: String?
)
