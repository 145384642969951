package tta.destinigo.talktoastro.feature_post.persentation

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.core.FastOutSlowInEasing
import androidx.compose.animation.core.animateFloatAsState
import androidx.compose.animation.core.tween
import androidx.compose.animation.expandVertically
import androidx.compose.animation.fadeIn
import androidx.compose.animation.fadeOut
import androidx.compose.animation.shrinkVertically
import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Button
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.runtime.snapshotFlow
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.AnnotatedString
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.font.FontFamily
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.text.withStyle
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import coil3.compose.AsyncImage
import kotlinx.datetime.Clock
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.Font
import org.jetbrains.compose.resources.painterResource
import tta.destinigo.talktoastro.core.local.UserInfoProvider.getFullName
import tta.destinigo.talktoastro.core.local.UserInfoProvider.getId
import tta.destinigo.talktoastro.core.persentation.CommonTopAppBar
import tta.destinigo.talktoastro.core.persentation.HomePageLoadingProgressDialog
import tta.destinigo.talktoastro.core.persentation.showToast
import tta.destinigo.talktoastro.core.theme.Scale
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_COMPACT
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_EXPEND
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_MEDIUM
import tta.destinigo.talktoastro.core.theme.background_color
import tta.destinigo.talktoastro.core.theme.primaryColor
import tta.destinigo.talktoastro.feature_post.PostDetailsComponent
import tta.destinigo.talktoastro.feature_post.data.request.LikeRequest
import tta.destinigo.talktoastro.feature_post.data.request.SubmitCommentResponse
import tta.destinigo.talktoastro.feature_post.data.response.Comment
import tta.destinigo.talktoastro.feature_post.data.response.PostDetailsResponseData
import tta.destinigo.talktoastro.resources.NotoColorEmoji_Regular
import tta.destinigo.talktoastro.resources.NotoSansDevanagari_Regular
import tta.destinigo.talktoastro.resources.Res
import tta.destinigo.talktoastro.resources.baseline_add_comment_24
import tta.destinigo.talktoastro.resources.baseline_thumb_up_24
import tta.destinigo.talktoastro.resources.profile_icon_avtar
import tta.destinigo.talktoastro.resources.roboto_regular
import tta.destinigo.talktoastro.resources.share_icon_new

@OptIn(
    ExperimentalMaterial3WindowSizeClassApi::class,
    ExperimentalMaterial3Api::class,
    ExperimentalFoundationApi::class
)
@Composable
fun PostDetails(component: PostDetailsComponent) {
    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    val state by component.postDetailsState.collectAsState()
    val postCommentState by component.postCommentSuccess.collectAsState()
    val commentState by component.postCommentsState.collectAsState()
    val isCommentLoading by component.isCommentLoading.collectAsState()
    val isLoading by component.isLoading.collectAsState()

    val widthModifier = when (windowSizeClass) {
        WindowWidthSizeClass.Compact -> WINDOW_WIDTH_SIZE_COMPACT
        WindowWidthSizeClass.Medium -> WINDOW_WIDTH_SIZE_MEDIUM
        WindowWidthSizeClass.Expanded -> WINDOW_WIDTH_SIZE_EXPEND
        else -> WINDOW_WIDTH_SIZE_COMPACT
    }

    // Function to get the current date and time
    fun getCurrentDateTime(): Pair<String, String> {
        val currentMoment = Clock.System.now()
        val currentDateTime = currentMoment.toLocalDateTime(TimeZone.currentSystemDefault())
        val date = "${currentDateTime.year}-${currentDateTime.monthNumber}-${currentDateTime.dayOfMonth}"
        val time = "${currentDateTime.hour}:${currentDateTime.minute}:${currentDateTime.second}"
        return date to time
    }



    val lazyListState = rememberLazyListState()
    var isCommentFieldVisible by remember { mutableStateOf(true) } // Default to visible
    var previousScrollOffset by remember { mutableStateOf(0) }
    var newCommentText by remember { mutableStateOf("") }

    // Detect scrolling direction and toggle visibility
    LaunchedEffect(lazyListState) {
        snapshotFlow { lazyListState.firstVisibleItemScrollOffset }
            .collect { currentScrollOffset ->
                if (currentScrollOffset > previousScrollOffset) {
                    // Scrolling down
                    isCommentFieldVisible = false
                } else if (currentScrollOffset < previousScrollOffset) {
                    // Scrolling up
                    isCommentFieldVisible = true
                }
                previousScrollOffset = currentScrollOffset
            }
    }

    if (isLoading) {
        HomePageLoadingProgressDialog(
            onDismissRequest = { },
            backgroundColor = Color(0xFF2A2A2A),
            loadingColor = Color.White
        )
    }

    Box(
        modifier = Modifier
            .fillMaxSize()
            .background(background_color),
        contentAlignment = Alignment.Center
    ) {
        LazyColumn(
            modifier = widthModifier
                .fillMaxSize()
                .background(background_color),
            state = lazyListState
        ) {
            stickyHeader {
                CommonTopAppBar(
                    modifier = Modifier,
                    title = "Post Details",
                    onBackClick = { component.onNavigateBack() },
                    scrollBehavior = null
                )
            }

            item {
                state?.let { PostDetailsItem(post = it,component) }
            }

            item {
                Text(
                    "Comments",
                    style = MaterialTheme.typography.titleMedium,
                    modifier = Modifier.padding(12.dp)
                )
            }
            if(isCommentLoading){
                item {
                    HomePageLoadingProgressDialog(
                        onDismissRequest = { },
                        backgroundColor = Color(0xFF2A2A2A),
                        loadingColor = Color.White
                    )
                }
            }else{
                items(commentState.size) { commentInd ->
                    val commentData = commentState[commentInd]
                    CommentItem(comment = commentData, position = commentInd)
                    Spacer(Modifier.height(10.dp))
                }
            }
        }

        // AnimatedVisibility for comment field
        AnimatedVisibility(
            visible = isCommentFieldVisible,
            enter = fadeIn() + expandVertically(),
            exit = fadeOut() + shrinkVertically(),
            modifier = Modifier.align(Alignment.BottomCenter)
        ) {
            Row(
                modifier = widthModifier
                    .fillMaxWidth()
                    .padding(12.dp)
                    .background(MaterialTheme.colorScheme.surface, RoundedCornerShape(8.dp))
                    .padding(8.dp),
                verticalAlignment = Alignment.CenterVertically
            ) {
                OutlinedTextField(
                    value = newCommentText,
                    onValueChange = { newCommentText = it },
                    placeholder = { Text("Write a comment...") },
                    modifier = Modifier
                        .weight(1f)
                        .padding(end = 8.dp)
                )
                Button(
                    onClick = {
                        val (currentDate, currentTime) = getCurrentDateTime()
                        if (newCommentText.isNotBlank()) {
                            component.callSubmitComment(SubmitCommentResponse(postId = state?.id.toString(), comment = newCommentText, userId = getId(), userName = getFullName(), commentDate = currentDate, commentTime = currentTime))
                            newCommentText = "" // Clear the input field
                        }
                    },
                    shape = RoundedCornerShape(Scale.CORNER_SMALL)
                ) {
                    Text("Post")
                }
            }
        }
    }
}



@Composable
fun PostDetailsItem(post: PostDetailsResponseData, component: PostDetailsComponent) {
    Card {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .clickable {
                    //onClick.invoke(post)
                }
                .background(Color.White)
                .padding(vertical = 8.dp)
        ) {
            Row(
                modifier = Modifier.padding(horizontal = 16.dp),
                verticalAlignment = Alignment.CenterVertically
            ) {
                AsyncImage(
                    model = post.consultantProfile,
                    contentDescription = null,
                    modifier = Modifier
                        .size(30.dp)
                        .clip(CircleShape)
                        .background(Color.Gray)
                        .clickable { },
                    contentScale = ContentScale.Crop,
                    error = painterResource(Res.drawable.profile_icon_avtar)
                )

                Spacer(modifier = Modifier.width(8.dp))

                Column(modifier = Modifier.weight(1f)) {
                    Text(
                        text = post.expertName ?: "Unknown",
                        //style = MaterialTheme.typography.subtitle1,
                        color = Color.Black,
                        maxLines = 1,
                        overflow = TextOverflow.Ellipsis
                    )

                    if (!post.expertCategory.isNullOrEmpty()) {
                        Text(
                            text = post.expertCategory,
                            //style = MaterialTheme.typography.caption,
                            color = Color.Gray
                        )
                    }
                }

                Text(
                    text = "Subscribe",
                    style = MaterialTheme.typography.labelSmall,
                    // color = MaterialTheme.colors.primary,
                    modifier = Modifier
                        .clickable { }
                        .padding(8.dp)
                )
            }

            Spacer(modifier = Modifier.height(8.dp))

            Text(
                text = post.postCreationDate + " | " + post.postCreationTime,
                ///style = MaterialTheme.typography.caption,
                color = Color.Gray,
                modifier = Modifier.padding(horizontal = 16.dp)
            )
            Spacer(modifier = Modifier.height(8.dp))

            val hindiFont = FontFamily(Font(Res.font.NotoSansDevanagari_Regular))
            val englishFont = FontFamily(Font(Res.font.roboto_regular))
            val emojiFont = FontFamily(Font(Res.font.NotoColorEmoji_Regular)) // Replace with the actual emoji font resource if needed.

            val emojiRegex = Regex("[\\uD83C-\\uDBFF\\uDC00-\\uDFFF]+") // Matches emojis
            val hindiRegex = Regex("[\\u0900-\\u097F\\u1CD0-\\u1CFF\\uA8E0-\\uA8FF]+") // Matches Hindi characters

            // Function to determine the font for each word
            fun getFontForWord(word: String): FontFamily {
                return when {
                    word.contains(emojiRegex) -> emojiFont // Use emoji font if emojis are detected
                    word.contains(hindiRegex) -> hindiFont  // Use Hindi font if Hindi characters are detected
                    else -> englishFont // Default to English font
                }
            }

            // Function to create an AnnotatedString with proper font for each part of the text
            fun createStyledText(postDescription: String): AnnotatedString {
                val words = postDescription.split(" ")
                val annotatedString = buildAnnotatedString {
                    words.forEachIndexed { index, word ->
                        val fontFamily = getFontForWord(word)
                        withStyle(style = SpanStyle(fontFamily = fontFamily)) {
                            append(word)
                        }
                        if (index < words.size - 1) append(" ") // Add space between words
                    }
                }
                return annotatedString
            }

            val styledText = post.postDescription?.let { createStyledText(it) } ?: AnnotatedString("")
            Spacer(modifier = Modifier.height(8.dp))

            Text(
                text = styledText,
                style = MaterialTheme.typography.bodyMedium,
                fontSize = 16.sp,
                modifier = Modifier.padding(horizontal = 16.dp)
            )
            Spacer(modifier = Modifier.height(10.dp))
            if (!post.postContent.isNullOrEmpty()) {
                AsyncImage(
                    model = post.postContent,
                    contentDescription = null,
                    modifier = Modifier
                        .fillMaxWidth()
                        .height(250.dp)
                        .padding(horizontal = 16.dp)
                        .clip(RoundedCornerShape(4.dp))
                        .clickable { },
                    contentScale = ContentScale.Crop
                )
            }

            Spacer(modifier = Modifier.height(8.dp))
            // Divider line
            HorizontalDivider(
                modifier = Modifier.padding(vertical = 8.dp),
                thickness = 1.dp,
                color = Color.Gray
            )
            Spacer(modifier = Modifier.height(8.dp))
            PostDetailsCommentLikeSection(
                post = post,
                onCommentClicked = { showToast("OnCommentClicked Click") },
                onShareClicked = { showToast("OnShare Click") },
                onLikeClicked = { lickPost ->
                    component.onLick(LikeRequest(postId = lickPost.id.toString(), like = if(lickPost.isLikedByMe!!) 0 else 1))
                },
                modifier = Modifier.fillMaxWidth()
            )
        }
    }
}

@Composable
fun PostDetailsCommentLikeSection(
    post: PostDetailsResponseData,
    onLikeClicked: (PostDetailsResponseData) -> Unit,
    onCommentClicked: (PostDetailsResponseData) -> Unit,
    onShareClicked: (PostDetailsResponseData) -> Unit,
    modifier: Modifier = Modifier
) {
    val totalLikes = remember { mutableStateOf(post.totalLikes) }
    val totalComment = remember { mutableStateOf(post.totalComments?.toIntOrNull() ?: 0) }
    val totalShare = remember { mutableStateOf(post.totalShares?.toIntOrNull() ?: 0) }

    Row(
        modifier = modifier.fillMaxWidth(),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.SpaceEvenly
    ) {
        post.isLikedByMe?.let {
            PostDetailsActionSection(
                count = (totalLikes.value ?: "0").toString(),
                label = "Like",
                icon = Res.drawable.baseline_thumb_up_24,
                isChecked = it,
                onActionClicked = {
                    // Increment the total likes
                    totalLikes.value = totalLikes.value?.toIntOrNull()?.let { currentLikes ->
                        if (post.isLikedByMe!!) {
                            (currentLikes - 1).toString()
                        } else {
                            (currentLikes + 1).toString()
                        }
                    } ?: "0"

                    //totalLikes.value = (totalLikes.value.plus(1))

                    // Handle like action
                    onLikeClicked(post)
                }
                // iconColor = Color.Red
            )
        }

        PostDetailsActionSection(
            count = totalComment.value.toString() ?: "0",
            label = "Comment",
            icon = Res.drawable.baseline_add_comment_24,
            onActionClicked = {
                onCommentClicked(post)
                totalComment.value = totalComment.value.plus(1)
            },
            //iconColor = Color.Blue
        )

        PostDetailsActionSection(
            count = totalShare.value.toString() ?: "0",
            label = "Share",
            icon = Res.drawable.share_icon_new,
            onActionClicked = {
                onShareClicked(post)
                totalShare.value = totalShare.value.plus(1)
            },
            // iconColor = Color.Green
        )
    }

}

@Composable
fun PostDetailsActionSection(
    count: String,
    label: String,
    icon: DrawableResource,
    isChecked: Boolean = false,
    onActionClicked: () -> Unit,
    // iconColor: Color,
) {

    var isLiked by remember { mutableStateOf(isChecked) }
    Column(
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center
    ) {
        Text(
            text = "$count $label",
            // style = MaterialTheme.typography.body2.copy(fontWeight = FontWeight.Bold),
            modifier = Modifier.padding(bottom = 4.dp)
        )

        IconButton(onClick = {
            onActionClicked()
            if (label.equals("Like", true)) {
                isLiked = !isLiked
            }
        }) {
            Icon(
                painter = painterResource(icon),
                contentDescription = label,
                tint = if (isLiked) primaryColor else Color.Black
            )
        }

//        Text(
//            text = label,
//            // style = MaterialTheme.typography.body2.copy(color = Color.Gray)
//        )
    }
}

@Composable
fun CommentItem(
    comment: Comment?,
    position: Int,
) {
    Card(
        modifier = Modifier
            .fillMaxWidth()
            .padding(8.dp),
        elevation = CardDefaults.cardElevation(Scale.ELEVATION_SMALL),
        shape = RoundedCornerShape(Scale.CORNER_SMALL),
        colors = CardDefaults.cardColors(Color.White)
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .padding(12.dp)
        ) {
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier.fillMaxWidth()
            ) {
                AsyncImage(
                    model = comment?.userProfile,
                    contentDescription = null,
                    modifier = Modifier
                        .size(45.dp)
                        .clip(CircleShape)
                        .background(Color.Gray)
                        .border(1.dp, Color.Gray, CircleShape)
                        .clickable { },
                    contentScale = ContentScale.Crop,
                    error = painterResource(Res.drawable.profile_icon_avtar)
                )

                Spacer(modifier = Modifier.width(12.dp))

                Column(
                    modifier = Modifier.weight(1f)
                ) {
                    comment?.userName?.let {
                        Text(
                            text = it,
                            style = MaterialTheme.typography.titleMedium,
                            color = MaterialTheme.colorScheme.onSurface
                        )
                    }

                    comment?.commentDate?.let {
                        Text(
                            text = it,
                            style = MaterialTheme.typography.labelSmall,
                            color = MaterialTheme.colorScheme.onSurface.copy(alpha = 0.6f)
                        )
                    }
                    Spacer(modifier = Modifier.height(8.dp))

                    comment?.msg?.let {
                        Text(
                            text = it,
                            style = MaterialTheme.typography.bodyMedium,
                            color = MaterialTheme.colorScheme.onSurface,
                            modifier = Modifier.padding(horizontal = 8.dp)
                        )
                    }

                }
            }

            Spacer(modifier = Modifier.height(4.dp))

            Row(
                modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                TextButton(onClick = { showToast("Like Success") }) {
                    Text(text = "Like", fontSize = 14.sp, style = MaterialTheme.typography.labelMedium)
                }

                TextButton(onClick = { showToast("Reported") }) {
                    Text(text = "Report", fontSize = 14.sp, style = MaterialTheme.typography.labelMedium)
                }
            }
        }
    }
}

