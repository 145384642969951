package tta.destinigo.talktoastro.core.di


import org.koin.core.context.startKoin
import tta.destinigo.talktoastro.feature_article.di.postFeedModule
import tta.destinigo.talktoastro.feature_authentication.di.authorizationModule
import tta.destinigo.talktoastro.feature_call_consultation.di.callConsultationModule
import tta.destinigo.talktoastro.feature_chat_consultation_new.di.chatConsultationModule
import tta.destinigo.talktoastro.feature_common.di.homeCommonModule
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.chat_history.data.di.chatHistoryMode
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.edit_profile.di.editProfileCommonModule
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.purchase_history.data.di.PurchaseHistoryModel
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.refer_and_earn.di.ReferAndEarnModel
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.transaction_history.data.di.transactionHistoryModel
import tta.destinigo.talktoastro.feature_courses.di.landingPageModel
import tta.destinigo.talktoastro.feature_custom_order.di.CustomOrderModel
import tta.destinigo.talktoastro.feature_expert_consultation.di.expertDetailsCommonModule
import tta.destinigo.talktoastro.feature_expert_consultation.di.expertListCommonModule
import tta.destinigo.talktoastro.feature_expert_consultation.di.searchExpertCommonModule
import tta.destinigo.talktoastro.feature_post.di.PostModel
import tta.destinigo.talktoastro.feature_winyway_wallet.di.walletModule

fun initKoin() {
    startKoin {
       // androidContext(this@App)
        modules(
            authorizationModule,
            homeCommonModule,
            editProfileCommonModule,
            walletModule,
            searchExpertCommonModule,
            chatConsultationModule,
            callConsultationModule,
            expertDetailsCommonModule,
            expertListCommonModule,
            landingPageModel,
            transactionHistoryModel,
            chatHistoryMode,
            postFeedModule,
            CustomOrderModel,
            PurchaseHistoryModel,
            ReferAndEarnModel,
            PostModel
        )
    }
}