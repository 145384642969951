package tta.destinigo.talktoastro.feature_post.data.request

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class LikeRequest(
    @SerialName("post_id") val postId: String,
    @SerialName("like") val like: Int
)
