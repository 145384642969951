package tta.destinigo.talktoastro.feature_courses.persentation.course_page

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnCreate
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import tta.destinigo.talktoastro.core.razorpay.startPayment
import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.feature_courses.data.request.CourseBuyRequest
import tta.destinigo.talktoastro.feature_courses.data.request.CourseBuyVerifyRequest
import tta.destinigo.talktoastro.feature_courses.data.request.CourseDetailsSubmitForm
import tta.destinigo.talktoastro.feature_courses.domain.CourseRepo
import tta.destinigo.talktoastro.feature_courses.persentation.course_page.widget.CouponDailogState
import tta.destinigo.talktoastro.feature_winyway_wallet.persentation.razorpay_gateway_new.PaymentGatewayArguments

class CourseComponent(
    context: ComponentContext,
    slug: String,
) : ComponentContext by context, KoinComponent {

    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Default)
    private val api: CourseRepo by inject()

    private val _state = MutableStateFlow<Resources<CourseInfo>>(Resources.Loading(false))
    val state get() = _state.asStateFlow()

    private val _courses = MutableStateFlow<CourseInfo?>(null)
    val courses get() = _courses.asStateFlow()

    fun onBackPress() {}
    fun onCancelCoupon(course: CourseInfo) {
        coroutineScope.launch {
            api.removeCoupon(course).collectLatest {
                if (it.data != null) _courses.emit(it.data)
            }
        }

    }

    fun onSubmitForm(
        name: String, mobile: String, email: String, courseId: String, countryCode: String
    ) {
        coroutineScope.launch {
            postCourseDetails(
                CourseDetailsSubmitForm(
                    fullName = name,
                    mobile = mobile,
                    email = email,
                    courseId = courseId,
                    countryCode = countryCode
                )
            )
        }
    }

    fun onBuyCourse(
        name: String, mobile: String, email: String, courseId: String, countryCode: String, couponCode:String?
    ) {
        coroutineScope.launch {
            onCourseBuy(
                CourseBuyRequest(
                    courseId = courseId,
                    userName = name,
                    userEmail = email,
                    mobile = mobile,
                    countryCode = countryCode,
                    couponCode = couponCode
                )
            )
        }
    }

    init {

        lifecycle.doOnCreate {
            coroutineScope.launch {
                getCoursesByRemote(slug)
            }
        }
    }

    private suspend fun getCoursesByRemote(slug: String) {
        api.getCourse(slug = slug).collectLatest {
            when (it) {
                is Resources.Error -> {
                    printLog("Courses Response Error -> ${it.msg}")
                    _state.emit(Resources.Error(it.msg))
                }

                is Resources.Loading -> {
                    printLog("Courses Response Loading -> ${it.isLoading}")
                    _state.emit(Resources.Loading(it.isLoading))
                }

                is Resources.Success -> {
                    printLog("Courses Response Success -> ${it.data}")
                    _state.emit(Resources.Success(it.data))
                    it.data?.let { course ->
                        _courses.emit(course)
                    }
                }

            }
        }
    }

    private val _event = MutableStateFlow<RegistrationEvent>(RegistrationEvent.InitialEvent)
    val event get() = _event.asStateFlow()

    fun setForEventInitial() {
        coroutineScope.launch {
            _event.emit(RegistrationEvent.InitialEvent)
        }
    }

    private val _eventBuy = MutableStateFlow<RegistrationEvent>(RegistrationEvent.InitialEvent)
    val eventBuy get() = _eventBuy.asStateFlow()

    fun setForBuyEventInitial() {
        coroutineScope.launch {
            _eventBuy.emit(RegistrationEvent.InitialEvent)
        }
    }


    private suspend fun onCourseBuy(request: CourseBuyRequest) {
        api.onBuyCourse(request).collect {
            when (it) {
                is Resources.Error -> {
                    _eventBuy.emit(RegistrationEvent.OnError(it.msg ?: "Unexpected error"))
                }

                is Resources.Loading -> {
                    _eventBuy.emit(RegistrationEvent.IsLoading)
                }

                is Resources.Success -> {
                    val isPaymentRequired = it.data?.isPaymentRequired

                    if (isPaymentRequired == false) {
                        _eventBuy.emit(RegistrationEvent.OnSuccess)
                        return@collect
                    }


                    val paymentArg = PaymentGatewayArguments(
                        orderId = it.data?.orderId.toString(),
                    )


                    startPayment(
                        paymentGatewayArguments = paymentArg,
                        onPaymentSuccess = ::onSuccessPayment,
                        onPaymentFailed = ::onFailedPayment
                    )
                }
            }
        }
    }


    private suspend fun postCourseDetails(request: CourseDetailsSubmitForm) {
        api.submitCourseDetails(request).collect {
            when (it) {
                is Resources.Error -> {
                    _event.emit(RegistrationEvent.OnError(it.msg ?: "Something Wrong Happen"))
                    Logger.d { "postCourseDetails error ${it.msg}" }
                }

                is Resources.Loading -> {
                    _event.emit(RegistrationEvent.IsLoading)
                }

                is Resources.Success -> {
                    _event.emit(RegistrationEvent.OnSuccess)
                    Logger.d { "postCourseDetails success ${it.data}" }
                }
            }
        }
    }

    private fun onSuccessPayment(
        orderId: String, paymentId: String, signature: String
    ) {

        coroutineScope.launch {
            _eventBuy.emit(RegistrationEvent.OnSuccess)

            api.onVerifyPayment(
                CourseBuyVerifyRequest(
                    orderId = orderId,
                    paymentId = paymentId,
                    paymentSignature = signature
                )
            ).collectLatest {
                when (it) {
                    is Resources.Success -> {
                        Logger.d("onVerifyPayment success ${it.data}")
                    }

                    else -> Unit
                }
            }
        }

    }

    private fun onFailedPayment(exception: Throwable) {
        coroutineScope.launch {
            _eventBuy.emit(RegistrationEvent.OnError(exception.message ?: "Unexpected error"))
        }
    }

    private fun printLog(msg: String) {
        Logger.d("COURSE_LIST_PAGE - $msg")
    }

    private val _couponState = MutableStateFlow<CouponDailogState>(CouponDailogState.Message(null))
    val couponState get() = _couponState.asStateFlow()
    fun setCouponNull() {
        coroutineScope.launch {
            _couponState.emit(CouponDailogState.Message(null))
        }
    }

    fun validateCoupon(
        course: CourseInfo, coupon: String
    ) {
        coroutineScope.launch {
            api.validateCoupon(
                coupon = coupon, course
            ).collectLatest {
                when (it) {
                    is Resources.Error -> {
                        _couponState.emit(CouponDailogState.Message(it.msg ?: "Unexpected Error"))
                    }

                    is Resources.Loading -> {
                        if (it.isLoading) _couponState.emit(CouponDailogState.LoadingState)
                    }

                    is Resources.Success -> {
                        _couponState.emit(CouponDailogState.Success)
                        if (it.data != null) _courses.emit(it.data)

                    }
                }
            }
        }
    }

}