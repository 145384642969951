
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.platform.LocalLayoutDirection
import androidx.compose.ui.unit.Density
import androidx.compose.ui.unit.LayoutDirection
import androidx.compose.ui.window.CanvasBasedWindow
import com.arkivanov.decompose.DefaultComponentContext
import com.arkivanov.decompose.ExperimentalDecomposeApi
import com.arkivanov.decompose.router.stack.webhistory.DefaultWebHistoryController
import com.arkivanov.essenty.lifecycle.LifecycleRegistry
import com.arkivanov.essenty.lifecycle.resume
import com.arkivanov.essenty.lifecycle.stop
import tta.destinigo.talktoastro.core.navigation_decompose.DefaultRootComponent
import tta.destinigo.talktoastro.core.di.initKoin
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.skiko.wasm.onWasmReady
import org.w3c.dom.events.Event
import tta.destinigo.talktoastro.core.navigation_decompose.getPathAndSlug

@OptIn(ExperimentalComposeUiApi::class, ExperimentalDecomposeApi::class)
fun main() {

    fun updateMetaTags(title: String, description: String) {
        document.title = title
        val metaDescription = document.querySelector("meta[name='description']")
            ?: document.createElement("meta").apply {
                setAttribute("name", "description")
                document.head?.appendChild(this)
            }
        metaDescription.setAttribute("content", description)
    }

    updateMetaTags(
        title = "TalktoAstro - Online Astrology",
        description = "Unlock your future with a personalized astrology consultation. Talk to astrologer on relationships, career, and life path through birth chart analysis. Book now for clarity and cosmic guidance"
    )


    initKoin()
    val myLifecycleOwner = MyLifecycleOwner()
    val lifecycleRegistry = LifecycleRegistry()

    val root = DefaultRootComponent(
        componentContext = DefaultComponentContext(lifecycle = myLifecycleOwner.lifecycle),
        deepLink = DefaultRootComponent.DeepLink.Web(path = getPathAndSlug(window.location.href)),
        webHistoryController = DefaultWebHistoryController()
    )

    val prefersDarkTheme = window.matchMedia("(prefers-color-scheme: dark)").matches

    onWasmReady {

        lifecycleRegistry.attachToDocument()
        var density by mutableStateOf(Density(1.0f))

        fun updateDensity() {
            val dpr = window.devicePixelRatio.toFloat()
            density = Density(dpr)
        }

        updateDensity()

        window.addEventListener("resize", { updateDensity() })

        CanvasBasedWindow(title = "TalktoAstro - Online Astrology") {
            CompositionLocalProvider(
                LocalDensity provides density,
                LocalLayoutDirection provides LayoutDirection.Ltr,
            ) {
                App(
                    darkTheme = prefersDarkTheme,
                    dynamicColor = true,
                    rootComponent = root
                )
            }
        }
    }
}



private fun LifecycleRegistry.attachToDocument() {

    val visibilityState = document.asDynamic().visibilityState as String
    fun onVisibilityChanged() {
        if (visibilityState == "visible") {
            resume()
        } else {
            stop()
        }
    }

    onVisibilityChanged()
        document.addEventListener("visibilitychange", { _: Event ->
        onVisibilityChanged()
    })

}

//fun loadSkiko(): Promise<Unit> {
//    return Promise { resolve, reject ->
//        val script = document.createElement("script") as HTMLScriptElement
//        script.src = "skiko.js"
//        script.onload = { resolve(Unit) }
//        script.onerror = { _: dynamic, _: String, _: Int, _: Int, _: Any? -> reject(Exception("Failed to load skiko.js")) }
//        document.head?.appendChild(script)
//    }
//}

