package tta.destinigo.talktoastro.feature_post.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class PostLikeResponse(
    @SerialName("code") val code: Int, // 200
    @SerialName("data") val `data`: PostLikeData,
    @SerialName("message") val message: String, // Post Liked Successfully
    @SerialName("status") val status: Boolean // true
)
