package tta.destinigo.talktoastro.feature_post.persentation

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.text.BasicTextField
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Add
import androidx.compose.material3.*
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.input.TextFieldValue
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import org.jetbrains.compose.resources.painterResource
import tta.destinigo.talktoastro.core.date_time.getCurrentDateTime
import tta.destinigo.talktoastro.core.local.UserInfoProvider.getFullName
import tta.destinigo.talktoastro.core.theme.background_color
import tta.destinigo.talktoastro.resources.Res
import tta.destinigo.talktoastro.resources.profile_icon_avtar

//@Composable
//fun CreatePostScreen(modifier: Modifier) {
//    Column(
//        modifier = Modifier
//            .wrapContentHeight()
//            .background(background_color)
//            //.fillMaxSize()
//            .padding(16.dp),
//        verticalArrangement = Arrangement.Top
//    ) {
//        Row(
//            modifier = Modifier.fillMaxWidth(),
//            verticalAlignment = Alignment.CenterVertically
//        ) {
//            Image(
//                painter = painterResource(Res.drawable.profile_icon_avtar),
//                contentDescription = null,
//                modifier = Modifier
//                    .size(45.dp)
//                    .background(MaterialTheme.colorScheme.secondary, CircleShape)
//            )
//            Spacer(modifier = Modifier.width(12.dp))
//            val (currentDate, currentTime) = getCurrentDateTime()
//            Column(modifier = Modifier.weight(1f)) {
//                Text(
//                    text = getFullName(),
//                    style = MaterialTheme.typography.titleMedium,
//                    maxLines = 1,
//                    overflow = TextOverflow.Ellipsis
//                )
//
//                Text(
//                    text = currentDate,
//                    style = MaterialTheme.typography.bodySmall,
//                    color = MaterialTheme.colorScheme.onSurfaceVariant
//                )
//            }
//
//            Spacer(modifier = Modifier.width(4.dp))
//
//            Text(
//                text = "EXPERT",
//                modifier = Modifier
//                    .background(MaterialTheme.colorScheme.primary, MaterialTheme.shapes.small)
//                    .padding(horizontal = 8.dp, vertical = 2.dp),
//                color = Color.White,
//                fontSize = 10.sp
//            )
//        }
//
//        Spacer(modifier = Modifier.height(12.dp))
//
//        BasicTextField(
//            value = remember { TextFieldValue() },
//            onValueChange = {},
//            modifier = Modifier
//                .fillMaxWidth()
//                .height(180.dp)
//                .background(Color.White, MaterialTheme.shapes.medium)
//                .padding(8.dp),
//            textStyle = MaterialTheme.typography.bodyMedium.copy(color = Color.Black),
//            decorationBox = { innerTextField ->
//                Box(
//                    modifier = Modifier.fillMaxSize(),
//                    contentAlignment = Alignment.TopStart
//                ) {
//                    if (innerTextField.toString().isEmpty()) {
//                        Text(
//                            text = "What do you want to post about?",
//                            style = MaterialTheme.typography.bodyMedium,
//                            color = MaterialTheme.colorScheme.onSurfaceVariant
//                        )
//                    }
//                    innerTextField()
//                }
//            }
//        )
//
//        Spacer(modifier = Modifier.height(12.dp))
//
//        Row(modifier = Modifier.fillMaxWidth()) {
//            Box(
//                modifier = Modifier
//                    .weight(1f)
//                    .height(90.dp)
//                    .background(Color.White, MaterialTheme.shapes.medium)
//            )
//
//            Spacer(modifier = Modifier.width(16.dp))
//
//            Column(
//                modifier = Modifier
//                    .width(90.dp)
//                    .height(90.dp)
//                    .background(MaterialTheme.colorScheme.secondary.copy(alpha = 0.1f))
//            ) {
//                Icon(
//                    imageVector = Icons.Default.Add,
//                   // painter = painterResource(Res.drawable.baseline_add_a_photo_24),
//                    contentDescription = "Add Image",
//                    modifier = Modifier.align(Alignment.CenterHorizontally)
//                )
//                Spacer(modifier = Modifier.height(4.dp))
//                Text(
//                    text = "Add",
//                    modifier = Modifier.align(Alignment.CenterHorizontally),
//                    style = MaterialTheme.typography.bodySmall
//                )
//            }
//        }
//
//        Spacer(modifier = Modifier.height(18.dp))
//
//        Button(
//            onClick = {},
//            modifier = Modifier.fillMaxWidth()
//        ) {
//            Text(text = "Proceed")
//        }
//    }
//}


@Composable
fun CreatePostScreen(modifier: Modifier = Modifier) {
    Column(
        modifier = modifier
            .fillMaxWidth()
            .background(MaterialTheme.colorScheme.background)
            .padding(16.dp),
        verticalArrangement = Arrangement.Top
    ) {
        Row(
            modifier = Modifier.fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Image(
                painter = painterResource(Res.drawable.profile_icon_avtar),
                contentDescription = null,
                modifier = Modifier
                    .size(45.dp)
                    .background(MaterialTheme.colorScheme.secondary, CircleShape)
                    .padding(2.dp)
            )
            Spacer(modifier = Modifier.width(12.dp))
            val (currentDate, _) = getCurrentDateTime()
            Column(modifier = Modifier.weight(1f)) {
                Text(
                    text = getFullName(),
                    style = MaterialTheme.typography.titleMedium,
                    maxLines = 1,
                    overflow = TextOverflow.Ellipsis
                )

                Text(
                    text = currentDate,
                    style = MaterialTheme.typography.bodySmall,
                    color = MaterialTheme.colorScheme.onSurfaceVariant
                )
            }

            Spacer(modifier = Modifier.width(4.dp))

            Text(
                text = "EXPERT",
                modifier = Modifier
                    .background(MaterialTheme.colorScheme.primary, MaterialTheme.shapes.small)
                    .padding(horizontal = 8.dp, vertical = 4.dp),
                color = Color.White,
                style = MaterialTheme.typography.labelSmall
            )
        }

        Spacer(modifier = Modifier.height(16.dp))

        BasicTextField(
            value = remember { TextFieldValue() },
            onValueChange = {},
            modifier = Modifier
                .fillMaxWidth()
                .height(180.dp)
                .background(MaterialTheme.colorScheme.surface, MaterialTheme.shapes.medium)
                .padding(12.dp),
            textStyle = MaterialTheme.typography.bodyMedium.copy(color = MaterialTheme.colorScheme.onSurface),
            decorationBox = { innerTextField ->
                Box(
                    modifier = Modifier.fillMaxSize(),
                    contentAlignment = Alignment.TopStart
                ) {
                    if (innerTextField.toString().isEmpty()) {
                        Text(
                            text = "What do you want to post about?",
                            style = MaterialTheme.typography.bodyMedium,
                            color = MaterialTheme.colorScheme.onSurfaceVariant
                        )
                    }
                    innerTextField()
                }
            }
        )

        Spacer(modifier = Modifier.height(16.dp))

        Row(
            modifier = Modifier.fillMaxWidth(),
            horizontalArrangement = Arrangement.spacedBy(16.dp)
        ) {
            Box(
                modifier = Modifier
                    .weight(1f)
                    .height(100.dp)
                    .background(MaterialTheme.colorScheme.surface, MaterialTheme.shapes.medium),
                contentAlignment = Alignment.Center
            ) {
                Text(
                    text = "Preview",
                    style = MaterialTheme.typography.bodySmall,
                    color = MaterialTheme.colorScheme.onSurfaceVariant
                )
            }

            Column(
                modifier = Modifier
                    .size(100.dp)
                    .background(MaterialTheme.colorScheme.secondary.copy(alpha = 0.1f), MaterialTheme.shapes.medium),
                horizontalAlignment = Alignment.CenterHorizontally,
                verticalArrangement = Arrangement.Center
            ) {
                Icon(
                    imageVector = Icons.Default.Add,
                    contentDescription = "Add Image",
                    tint = MaterialTheme.colorScheme.primary,
                    modifier = Modifier.size(32.dp)
                )
                Spacer(modifier = Modifier.height(4.dp))
                Text(
                    text = "Add Image",
                    style = MaterialTheme.typography.bodySmall,
                    color = MaterialTheme.colorScheme.onSurfaceVariant
                )
            }
        }

        Spacer(modifier = Modifier.height(20.dp))

        Button(
            onClick = {},
            modifier = Modifier.fillMaxWidth(),
            shape = MaterialTheme.shapes.medium
        ) {
            Text(text = "Post", style = MaterialTheme.typography.labelLarge)
        }
    }
}
