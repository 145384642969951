package tta.destinigo.talktoastro.feature_post.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CommentResponse(
    @SerialName("Data") val comments: List<Comment>?,
    @SerialName("responsecode") val responsecode: String, // 200
    @SerialName("status") val status: String // success
)
