//import androidx.compose.runtime.Composable
//import androidx.compose.ui.Modifier
//
//pepackage tta.destinigo.talktoastro.core.persentation
//
//import com.russhwolf.settings.Settings
//import tta.destinigo.talktoastro.core.data.CryptoUtils
//import tta.destinigo.talktoastro.core.platform.PlatformNavigator
//import tta.destinigo.talktoastro.core.platform.PlatformType
//import tta.destinigo.talktoastro.core.shared.FileDownloader
//import tta.destinigo.talktoastro.feature_chat_consultation.data.response.ChatMessage
//import tta.destinigo.talktoastro.feature_chat_consultation_new.data.response.save_chat.SaveChatConversationRequest
//
//const val WINYWAY_IMAGE_URL =
//    "https://live.winyway.com/images/logo/winyway_logo_icon.png"
//
//expect fun getDeviceType(): DeviceType
//
//enum class DeviceType {
//    ANDROID, IOS, DESKTOP, NATIVE, WEB_HD, WEB_FULL_HD, WEB_2K, WEB_4K, WEB_UD
//}
//
//enum class BrowserType {
//    WEB_HD, WEB_FULL_HD, WEB_2K, WEB_4K, WEB_UD
//}
//
//expect fun showToast(message: String)
//expect fun openPlayStore(url: String)
//expect fun clearCaptchaData()
//expect fun refreshPage()
//
//expect fun clearSessionOrCache()
///*Shared Precedence save and retirve data*/
//expect val settings: Settings
//
//data class PaginationItems<ChatConversationData>(
//    val items: List<ChatConversationData> // List of items for the current page
//)
//
//expect abstract class BasePagingSource<Value : Any>() {
//    // Declare an expected function that platform-specific implementations must provide
//    abstract suspend fun fetchData(page: Int, limit: Int): PaginationItems<Value>
//}
//
//expect suspend fun saveChatMessage(url: String, chatId: String, list: List<ChatMessage>): String
//expect suspend fun saveChatMessage(url: String, chatId: String, list: List<ChatMessage>,chatType:String): String
//
//expect class UnloadHandler {
//    fun setupBeforeUnload(endChat: () -> Unit)
//}
//
//expect fun setupBeforeUnload(endChat: () -> Unit)
//expect fun clearData()
//
//fun onPlayStoreLinkClick(platformNavigator: PlatformNavigator) {
//    platformNavigator.openPlayStore()
//}
//
//fun onAppStoreLinkClick(platformNavigator: PlatformNavigator) {
//    platformNavigator.openAppStore()
//}
//
//expect fun getFileDownloader(): FileDownloader
//
//expect fun downloadFileFromUrl(fileUrl: String, fileName: String)
//expect fun playVideo(url: String)
//
//expect fun getPlatformType(): PlatformType
//
//expect fun displayImageInBrowser(imageData: ByteArray)
//
//expect suspend fun saveImageToFile(bytes: ByteArray, fileName: String)
//expect fun shareLink(link: String)
//
//
//
//// Shared module



package tta.destinigo.talktoastro.core.persentation

import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import com.russhwolf.settings.Settings
import tta.destinigo.talktoastro.core.data.CryptoUtils
import tta.destinigo.talktoastro.core.platform.PlatformNavigator
import tta.destinigo.talktoastro.core.platform.PlatformType
import tta.destinigo.talktoastro.core.shared.FileDownloader
import tta.destinigo.talktoastro.feature_chat_consultation.data.response.ChatMessage
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.response.save_chat.SaveChatConversationRequest

const val WINYWAY_IMAGE_URL =
    "https://live.winyway.com/images/logo/winyway_logo_icon.png"

expect fun getDeviceType(): DeviceType

enum class DeviceType {
    ANDROID, IOS, DESKTOP, NATIVE, WEB_HD, WEB_FULL_HD, WEB_2K, WEB_4K, WEB_UD
}

enum class BrowserType {
    WEB_HD, WEB_FULL_HD, WEB_2K, WEB_4K, WEB_UD
}

expect fun showToast(message: String)
expect fun openPlayStore(url: String)
expect fun clearCaptchaData()
expect fun refreshPage()

expect fun clearSessionOrCache()
/*Shared Precedence save and retirve data*/
expect val settings: Settings

data class PaginationItems<ChatConversationData>(
    val items: List<ChatConversationData> // List of items for the current page
)

expect abstract class BasePagingSource<Value : Any>() {
    // Declare an expected function that platform-specific implementations must provide
    abstract suspend fun fetchData(page: Int, limit: Int): PaginationItems<Value>
}

expect suspend fun saveChatMessage(url: String, chatId: String, list: List<ChatMessage>): String
expect suspend fun saveChatMessage(url: String, chatId: String, list: List<ChatMessage>,chatType:String): String

expect class UnloadHandler {
    fun setupBeforeUnload(endChat: () -> Unit)
}

expect fun setupBeforeUnload(endChat: () -> Unit)
expect fun clearData()

fun onPlayStoreLinkClick(platformNavigator: PlatformNavigator) {
    platformNavigator.openPlayStore()
}

fun onAppStoreLinkClick(platformNavigator: PlatformNavigator) {
    platformNavigator.openAppStore()
}

expect fun getFileDownloader(): FileDownloader

expect fun downloadFileFromUrl(fileUrl: String, fileName: String)
expect fun playVideo(url: String)

expect fun getPlatformType(): PlatformType

expect fun displayImageInBrowser(imageData: ByteArray)

expect suspend fun saveImageToFile(bytes: ByteArray, fileName: String)
expect fun shareLink(link: String)

@Composable
expect fun PlatformSpecificVideoView(
    instagramScript: String,
    thumbnail: String,
    modifier: Modifier
)
