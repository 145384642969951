package tta.destinigo.talktoastro.core.persentation


import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import com.russhwolf.settings.Settings
import com.russhwolf.settings.StorageSettings
import io.ktor.client.call.body
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.request.url
import io.ktor.http.ContentType
import io.ktor.http.contentType
import io.ktor.util.InternalAPI
import kotlinx.browser.document
import kotlinx.browser.localStorage
import kotlinx.browser.sessionStorage
import kotlinx.browser.window
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import kotlinx.dom.clear
import kotlinx.serialization.json.Json
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.backgroundImage
import org.jetbrains.compose.web.css.backgroundPosition
import org.jetbrains.compose.web.css.backgroundSize
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.left
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.top
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Iframe
import org.w3c.dom.HTMLAnchorElement
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLIFrameElement
import org.w3c.dom.HTMLImageElement
import org.w3c.dom.HTMLScriptElement
import org.w3c.dom.HTMLVideoElement
import org.w3c.dom.Text
import org.w3c.dom.url.URL
import org.w3c.files.Blob
import org.w3c.files.BlobPropertyBag
import tta.destinigo.talktoastro.core.agora.chat.jsObject
import tta.destinigo.talktoastro.core.data.CryptoUtils
import tta.destinigo.talktoastro.core.date_time.BASE_URL
import tta.destinigo.talktoastro.core.file_dounload.JSFileDownloader
import tta.destinigo.talktoastro.core.local.UserInfoProvider
import tta.destinigo.talktoastro.core.platform.PlatformType
import tta.destinigo.talktoastro.core.remote.KtorApi
import tta.destinigo.talktoastro.core.shared.FileDownloader
import tta.destinigo.talktoastro.feature_chat_consultation.data.response.ChatMessage
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.response.save_chat.SaveChatConversationRequest
import kotlin.js.json

actual fun getDeviceType(): DeviceType {

    val screenWidthPx = window.innerWidth

    return when {
        screenWidthPx <= 1280 -> DeviceType.WEB_HD // WEB_HD
        screenWidthPx <= 1920 -> DeviceType.WEB_FULL_HD // WEB_FULL_HD
        screenWidthPx <= 2560 -> DeviceType.WEB_2K // WEB_2K
        screenWidthPx <= 3840 -> DeviceType.WEB_4K // WEB_4K
        else -> DeviceType.WEB_UD  // WEB_UD
    }
}

actual fun showToast(message: String) {
    window.alert(message)
}

actual val settings: Settings
    get() = StorageSettings()

actual fun openPlayStore(url: String) {
    window.open(url, "_blank")
}

actual fun clearCaptchaData() {
}

actual fun clearSessionOrCache() {
    // Clear localStorage
    js("localStorage.clear()")

    // Clear sessionStorage
    js("sessionStorage.clear()")

    // Clear cookies (if session or cache is stored in cookies)
    js("document.cookie.split(';').forEach(function(c) { document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/'); });")
}

actual fun refreshPage() {
    window.document.cookie = ""
    window.sessionStorage.clear()
    window.localStorage.clear()
    window.location.href = window.location.href + "?refresh=" + js("Date.now()")
}

actual fun clearData() {
    console.log("Attempting to clear data...")
    if (js("typeof localStorage !== 'undefined'") as Boolean) {
        console.log("Clearing localStorage...")
        localStorage.clear()
    } else {
        console.log("localStorage is not available!")
    }

    if (js("typeof sessionStorage !== 'undefined'") as Boolean) {
        console.log("Clearing sessionStorage...")
        sessionStorage.clear()
    } else {
        console.log("sessionStorage is not available!")
    }
}

actual abstract class BasePagingSource<Value : Any> actual constructor() {
    actual abstract suspend fun fetchData(
        page: Int,
        limit: Int
    ): PaginationItems<Value>

    suspend fun load(page: Int, limit: Int): PaginationItems<Value> {
        return try {
            val response = fetchData(page, limit)
            response
        } catch (e: Exception) {
            throw e
        }
    }
}

// Assuming this function is outside the KtorApi class
actual suspend fun saveChatMessage(
    url: String,
    chatId: String,
    list: List<ChatMessage>
): String {
    // Create an instance of KtorApi
    val ktorApi = object : KtorApi() {}

    // Create the request object with the messages list and chatId
    val request = SaveChatConversationRequest(msg = list.toTypedArray(), chatId)

    // Debug log to check the serialized JSON
    val jsonBody = Json.encodeToString(SaveChatConversationRequest.serializer(), request)
    console.log("Serialized Request Body: $jsonBody")

    return try {
        // Perform POST request with JSON body using the KtorApi instance's client
        val response: String = ktorApi.client.post {
            url("${BASE_URL}$url") // Construct the full URL using BASE_URL
            contentType(ContentType.Application.Json)
            setBody(request)
        }.body()

        response
    } catch (e: Exception) {
        console.log("API call failed: ${e.message}")
        "Error: ${e.message}" // Return error message if something goes wrong
    }
}



// jsMain
actual class UnloadHandler {
    actual fun setupBeforeUnload(endChat: () -> Unit) {
        // Listen for when the tab or browser window is about to close (before unload)
        window.addEventListener("beforeunload", { event ->
            // Call the endChat function when the event is triggered
            endChat()

            // Show a confirmation dialog before closing
            val message = "Are you sure you want to leave this page? Your progress may not be saved."

            // Set the return value of the event, which triggers the browser's confirmation dialog
            event.asDynamic().returnValue = message // This works in most modern browsers

            // Optionally log the event to console for debugging
            console.log("beforeunload event triggered", event)

            // Return the message to show the confirmation alert
            message
        })

        // Listen for when the page is actually unloading (after confirmation)
        window.addEventListener("unload", { event ->
            // This event is triggered after the user confirms closing the tab/browser window
            console.log("unload event triggered", event)

            // Perform any cleanup or saving here if necessary
            // Example: Call an API to save the user's progress or data
            // myApiService.saveUserData()
        })
    }
}

actual fun setupBeforeUnload(endChat: () -> Unit) {
    // Listen for when the tab or browser window is about to close (before unload)
    window.addEventListener("beforeunload", { event ->
        // Call the endChat function when the event is triggered
        endChat()

        // Show a confirmation dialog before closing
        val message = "Are you sure you want to leave this page? Your progress may not be saved."

        // Set the return value of the event, which triggers the browser's confirmation dialog
        event.asDynamic().returnValue = message // This works in most modern browsers

        // Optionally log the event to console for debugging
        console.log("beforeunload event triggered", event)

        // Return the message to show the confirmation alert
        message
    })

    // Listen for when the page is actually unloading (after confirmation)
    window.addEventListener("unload", { event ->
        // This event is triggered after the user confirms closing the tab/browser window
        console.log("unload event triggered", event)

        // Perform any cleanup or saving here if necessary
        // Example: Call an API to save the user's progress or data
        // myApiService.saveUserData()
    })
}

actual fun getFileDownloader(): FileDownloader = JSFileDownloader()
//actual fun downloadFileFromUrl(fileUrl: String, fileName: String) {
//    // Create a temporary anchor element
//    val anchor = document.createElement("a") as HTMLAnchorElement
//    anchor.href = fileUrl
//    anchor.download = fileName // Suggested file name for the download
//
//    // Trigger the download by clicking the anchor
//    document.body?.appendChild(anchor) // Append to the DOM
//    anchor.click() // Simulate a click event
//    document.body?.removeChild(anchor) // Remove the anchor element
//}

actual fun downloadFileFromUrl(fileUrl: String, fileName: String) {
    // Create a temporary anchor element
    val anchor = document.createElement("a") as HTMLAnchorElement
    anchor.href = fileUrl
    anchor.download = fileName // Enforce download with a custom file name

    // Append anchor to the document body
    document.body?.appendChild(anchor)

    // Trigger the download
    anchor.click()

    // Remove the anchor element after use
    document.body?.removeChild(anchor)
}

actual fun playVideo(url: String) {
    val videoElement = document.createElement("video") as HTMLVideoElement
    videoElement.src = url
    videoElement.controls = true  // Show video controls (play, pause, etc.)
    document.body?.appendChild(videoElement)
    videoElement.play()
}

actual fun getPlatformType(): PlatformType = PlatformType.Web
@OptIn(DelicateCoroutinesApi::class)
actual fun displayImageInBrowser(imageData: ByteArray) {
    val base64Image = encodeToBase64(imageData)

    // Create an image element to display
    GlobalScope.launch {
        val imgElement = document.createElement("img") as HTMLImageElement
        imgElement.src = "data:image/png;base64,$base64Image"
        document.body?.appendChild(imgElement)

        // Create a Blob from the Base64 data
        val byteArray = base64ToByteArray(base64Image)
        val blob = Blob(arrayOf(byteArray), BlobPropertyBag(type = "image/png"))

        // Create a URL for the Blob
        val blobUrl = URL.createObjectURL(blob)

        // Create a link to trigger the download
        val downloadLink = document.createElement("a") as HTMLAnchorElement
        downloadLink.href = blobUrl
        downloadLink.download = "image.png"  // The file name
        downloadLink.text = "Download Image"
        document.body?.appendChild(downloadLink)
    }
}

// Helper function to encode ByteArray to Base64
fun encodeToBase64(data: ByteArray): String {
    // Convert ByteArray to binary string
    val binaryString = data.joinToString("") { it.toInt().toChar().toString() }
    return window.btoa(binaryString)  // Convert to Base64
}

// Helper function to decode Base64 back to ByteArray
fun base64ToByteArray(base64: String): ByteArray {
    val binaryString = window.atob(base64)
    return binaryString.map { it.toByte() }.toByteArray()
}

actual suspend fun saveImageToFile(bytes: ByteArray, fileName: String) {
    val blob = Blob(arrayOf(bytes), BlobPropertyBag(type = "application/octet-stream"))
    val url = URL.createObjectURL(blob)

    // Create a temporary anchor element
    val anchor = document.createElement("a") as org.w3c.dom.HTMLAnchorElement
    anchor.href = url
    anchor.download = fileName
    document.body?.appendChild(anchor)

    // Simulate click to trigger the download
    anchor.click()

    // Cleanup
    document.body?.removeChild(anchor)
    URL.revokeObjectURL(url)
}

actual fun shareLink(link: String) {
    val navigator = window.navigator.asDynamic()

    if (navigator.share != null) {
        // Create a dynamic object with the required properties
        val referCode = UserInfoProvider.getReferralCode()
        val link = "https://play.google.com/store/apps/details?id=tta.destinigo.talktoastro&referrer=utm_source%3D$referCode"
        val website = "https://www.talktoastro.com" // Replace with the actual website URL

        val shareData = json(
            "url" to link,
            "title" to "🎉 Download the TalkToAstro App now and earn coins to enjoy free consultations! 🌟",
            "text" to """
        Use my referral code: $referCode during registration to claim your rewards. 

        📲 Download the app here: $link
        🌐 Visit the website for more: $website

        Don't miss out on this amazing opportunity! 🚀
    """
        )

        navigator.share(shareData).catch { error ->
            console.error("Sharing failed: $error")
        }
    } else {
        // Fallback behavior when the Web Share API is not supported
        console.log("Web Share API not supported, providing fallback.")
        // Optionally, you can display the link to the user or copy it to the clipboard
    }
}

actual suspend fun saveChatMessage(
    url: String,
    chatId: String,
    list: List<ChatMessage>,
    chatType: String
): String {
    TODO("Not yet implemented")
}




//@Composable
//actual fun PlatformSpecificVideoView(
//    instagramScript: String,
//    thumbnail: String,
//    modifier: Modifier
//) {
//    val iframeId = "youtube-iframe-container"
//
//    // Create the div container
//    DivElement(id = iframeId)
//
//    // LaunchedEffect to insert iframe after the composable is rendered
//    LaunchedEffect(instagramScript) {
//        // Check if the div container exists before adding the iframe
//        val container = document.getElementById(iframeId)
//
//        if (container != null && container is HTMLElement) {
//            console.log("Container found with id '$iframeId'. Proceeding to create iframe.")
//
//            // Create iframe element
//            val iframe = document.createElement("iframe") as HTMLIFrameElement
//
//            // Set iframe attributes
//            iframe.width = "560"
//            iframe.height = "315"
//            iframe.src = instagramScript  // Use the dynamic script URL
//            iframe.title = "YouTube video player"
//            iframe.frameBorder = "0"
//            iframe.setAttribute("allow", "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share")
//            iframe.referrerPolicy = "strict-origin-when-cross-origin"
//            iframe.allowFullscreen = true
//
//            // Append iframe to the container
//            container.appendChild(iframe)
//            console.log("Iframe appended successfully to container.")
//        } else {
//            console.error("Container with id '$iframeId' not found!")
//        }
//    }
//
//    // Layout for the video view
//    Box(modifier = modifier) {
//        DivElement(iframeId)
//        Text("Video will load here")  // Fallback message
//    }
//}
//
//// DivElement for a container in the DOM (to be used as iframe holder)
//@Composable
//fun DivElement(id: String) {
//    // Creating a placeholder to append the iframe into
//    val divElement = document.createElement("div") as HTMLDivElement
//    divElement.id = id
//    divElement.style.height = "315px"  // Set height explicitly
//    divElement.style.width = "560px"   // Set width explicitly
//
//    // Append the div element to the body
//    document.body?.appendChild(divElement)
//
//    // Log div creation
//    console.log("Div element with id '$id' appended to body.")
//}


@Composable
actual fun PlatformSpecificVideoView(
    instagramScript: String,
    thumbnail: String,
    modifier: Modifier
) {
    val iframeId = "youtube-iframe-container"

    // Ensure the container div is created first
    DivElement(id = iframeId)

    LaunchedEffect(instagramScript) {
        // Wait for the iframe container to be created
        val container = document.getElementById(iframeId)

        if (container != null && container is HTMLElement) {
            val iframe = document.createElement("iframe") as HTMLIFrameElement
            iframe.width = "100%"
            iframe.height = "100%"
            iframe.src = "https://www.youtube.com/embed/$instagramScript?autoplay=1&controls=1&enablejsapi=1" // Added enablejsapi
            iframe.title = "Video Player"
            iframe.frameBorder = "0"
            iframe.setAttribute("allow", "autoplay; encrypted-media; picture-in-picture; fullscreen") // Added fullscreen
            iframe.setAttribute("referrerpolicy", "origin")
            iframe.allowFullscreen = true
            iframe.setAttribute("tabindex", "0") // Allow focus
            iframe.style.width = "100%"
            iframe.style.height = "100%"
            iframe.style.display = "block"
            iframe.style.position = "absolute"
            iframe.style.top = "0"
            iframe.style.left = "0"

            // Clear any previous content inside the container
            container.innerHTML = ""
            container.appendChild(iframe)

            console.log("Iframe appended successfully:", iframe)
        } else {
            console.error("Container not found or not HTMLElement")
        }
    }

    // Box container to apply full-screen behavior
    Box(
        modifier = modifier
            .fillMaxSize() // Ensures the Box fills the screen
    ) {
        DivElement(iframeId)
    }
}

@Composable
fun DivElement(id: String) {
    DisposableEffect(id) {
        // Create a new div element to hold the iframe
        val divElement = document.createElement("div") as HTMLDivElement
        divElement.id = id
        divElement.style.position = "absolute"
        divElement.style.top = "0"
        divElement.style.left = "0"
        divElement.style.height = "100%"
        divElement.style.width = "100%"
        divElement.style.display = "block"

        document.body?.appendChild(divElement)

        // Clean up the div when the effect is disposed
        onDispose {
            document.body?.removeChild(divElement)
        }
    }
}


//@Composable
//actual fun PlatformSpecificVideoView(
//    instagramScript: String,
//    thumbnail: String,
//    modifier: Modifier
//) {
//    val iframeContainerId = "youtube-iframe-container"
//
//    // Load the YouTube iframe API script only once
//    LaunchedEffect(Unit) {
//        val scriptId = "youtube-iframe-script"
//        val existingScript = document.getElementById(scriptId)
//        if (existingScript == null) {
//            val script = document.createElement("script")
//            script.id = scriptId
//            script.setAttribute("src", "https://www.youtube.com/iframe_api")
//            script.setAttribute("async", "true")
//            script.setAttribute("defer", "true")
//            document.body?.appendChild(script)
//        }
//    }
//
//    // Wait for the YouTube iframe API to be ready
//    LaunchedEffect(instagramScript) {
//        window.asDynamic().onYouTubeIframeAPIReady = {
//            val container = document.getElementById(iframeContainerId)
//
//            if (container != null && container is HTMLDivElement) {
//                // Clear any previous content
//                container.innerHTML = ""
//
//                // Initialize the YouTube Player
//                val player = window.asDynamic().YT.Player(iframeContainerId, jsObject {
//                    this.height = "100%"
//                    this.width = "100%"
//                    this.videoId = instagramScript
//                    this.events = jsObject {
//                        this.onReady = { event: dynamic ->
//                            console.log("Player is ready")
//                            onPlayerReady(event)
//                        }
//                        this.onError = { error: dynamic ->
//                            console.error("Error initializing YouTube Player:", error)
//                        }
//                    }
//                })
//
//                if (player == undefined) {
//                    console.error("Failed to initialize YouTube Player")
//                }
//            } else {
//                console.error("Container not found or not an HTMLDivElement")
//            }
//        }
//    }
//
//    // Box container to apply full-screen behavior
//    Box(
//        modifier = modifier
//            .fillMaxSize() // Ensures the Box fills the screen
//    ) {
//        DivElement(id = iframeContainerId)
//    }
//}
//
//@Composable
//fun DivElement(id: String) {
//    DisposableEffect(id) {
//        val divElement = document.createElement("div") as HTMLDivElement
//        divElement.id = id
//        divElement.style.position = "relative"
//        divElement.style.top = "0"
//        divElement.style.left = "0"
//        divElement.style.height = "100%"
//        divElement.style.width = "100%"
//        divElement.style.display = "block"
//
//        document.body?.appendChild(divElement)
//
//        onDispose {
//            document.body?.removeChild(divElement)
//        }
//    }
//}
//
//// JavaScript functions to control the video
//fun onPlayerReady(event: dynamic) {
//    val player = event.target
//
//    // Example controls:
//    window.asDynamic().pauseVideo = {
//        player.pauseVideo()
//    }
//
//    window.asDynamic().playVideo = {
//        player.playVideo()
//    }
//
//    window.asDynamic().seekTo = { seconds: Int ->
//        player.seekTo(seconds, true)
//    }
//}
//
//// Utility to create JavaScript objects
//fun jsObject(init: dynamic.() -> Unit): dynamic {
//    val obj = js("{}")
//    init(obj)
//    return obj
//}




//@JsModule("youtube-iframe-api") // Import YouTube iframe API module if needed
//@JsNonModule
//external object YT {
//    class Player(elementId: String, config: dynamic)
//}

//@Composable
//actual fun PlatformSpecificVideoView(
//    instagramScript: String,
//    thumbnail: String,
//    modifier: Modifier
//) {
//    val iframeId = "youtube-iframe-container"
//    var player: dynamic = null // Declare the player variable
//    val isScriptLoaded = remember { mutableStateOf(false) }
//
//    // Ensure the container div is created first
//    DivElement(id = iframeId)
//
//    // Ensure that the YouTube API script is loaded only once
//    LaunchedEffect(instagramScript) {
//        if (!isScriptLoaded.value) {
//            // Load the YouTube iframe API script dynamically
//            val script = document.createElement("script") as HTMLScriptElement
//            script.src = "https://www.youtube.com/iframe_api"
//            script.onload = {
//                isScriptLoaded.value = true // Mark the script as loaded
//                console.log("YouTube API script loaded.")
//            }
//            document.head?.appendChild(script)
//        }
//
//        // Create and embed the iframe only when the API script is loaded
//        if (isScriptLoaded.value) {
//            val container = document.getElementById(iframeId)
//
//            if (container != null && container is HTMLElement) {
//                val iframe = document.createElement("iframe") as HTMLIFrameElement
//                iframe.width = "100%"
//                iframe.height = "100%"
//                iframe.src = "https://www.youtube.com/embed/$instagramScript?enablejsapi=1&autoplay=1" // Ensure autoplay is set
//                iframe.title = "Video Player"
//                iframe.frameBorder = "0"
//                iframe.setAttribute("allow", "autoplay; encrypted-media; picture-in-picture; fullscreen")
//                iframe.setAttribute("referrerpolicy", "origin")
//                iframe.allowFullscreen = true
//                iframe.setAttribute("tabindex", "0")
//                iframe.style.width = "100%"
//                iframe.style.height = "100%"
//                iframe.style.display = "block"
//                iframe.style.position = "absolute"
//                iframe.style.top = "0"
//                iframe.style.left = "0"
//
//                container.innerHTML = "" // Clear the container
//                container.appendChild(iframe)
//
//                // Wait for the YouTube API to be ready
//                window.asDynamic().onYouTubeIframeAPIReady = {
//                    // Initialize the YouTube player
//                    player = window.asDynamic().YT.Player(iframeId, object {
//                        val events = object {
//                            val onReady = { _: dynamic ->
//                                console.log("Player is ready")
//                                player.playVideo() // Automatically start the video when ready
//                            }
//                            val onStateChange = { event: dynamic ->
//                                console.log("Player state changed: ${event.data}")
//                                // Log player state
//                                if (event.data == 1) {
//                                    console.log("Video is playing")
//                                } else if (event.data == 2) {
//                                    console.log("Video is paused")
//                                }
//                            }
//                        }
//                    })
//                }
//            } else {
//                console.error("Container not found or not HTMLElement")
//            }
//        }
//    }
//
//    // Box container to apply full-screen behavior
//    Box(
//        modifier = modifier
//            .fillMaxSize()
//    ) {
//        DivElement(iframeId)
//    }
//
//    // Control video player functions (call these methods from other places in your UI)
//    fun playVideo() {
//        if (player) {
//            player.playVideo()
//            console.log("Video play triggered.")
//        }
//    }
//
//    fun pauseVideo() {
//        if (player) {
//            player.pauseVideo()
//            console.log("Video pause triggered.")
//        }
//    }
//
//    fun seekTo(seconds: Int) {
//        if (player) {
//            player.seekTo(seconds)
//            console.log("Video seeked to $seconds seconds.")
//        }
//    }
//}
//
//@Composable
//fun DivElement(id: String) {
//    DisposableEffect(id) {
//        val divElement = document.createElement("div") as HTMLDivElement
//        divElement.id = id
//        divElement.style.position = "absolute"
//        divElement.style.top = "0"
//        divElement.style.left = "0"
//        divElement.style.height = "100%"
//        divElement.style.width = "100%"
//        divElement.style.display = "block"
//
//        document.body?.appendChild(divElement)
//
//        onDispose {
//            document.body?.removeChild(divElement)
//        }
//    }
//}












//@Composable
//actual fun PlatformSpecificVideoView(
//    instagramScript: String,
//    thumbnail: String,
//    modifier: Modifier
//) {
//    val iframeId = "youtube-iframe-container"
//
//    DivElement(id = iframeId)
//
//    LaunchedEffect(instagramScript) {
//        val container = document.getElementById(iframeId)
//
//        if (container != null && container is HTMLElement) {
//            val iframe = document.createElement("iframe") as HTMLIFrameElement
//
//            iframe.width = "100%"
//            iframe.height = "100%"
//            iframe.style.width = "100%"
//            iframe.style.height = "100%"
//            iframe.style.display = "block"
//            iframe.src = instagramScript
//            iframe.title = "Video Player"
//            iframe.frameBorder = "0"
//            iframe.setAttribute(
//                "allow",
//                "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//            )
//            iframe.referrerPolicy = "strict-origin-when-cross-origin"
//            iframe.allowFullscreen = true
//
//            container.innerHTML = "" // Clear any previous content
//            container.appendChild(iframe)
//
//            console.log("Iframe appended successfully:", iframe.src)  // Log iframe source URL
//        } else {
//            console.error("Container not found or not HTMLElement")
//        }
//    }
//
//
//    Box(modifier = modifier) { // Apply the modifier here
//        DivElement(iframeId)
//    }
//}
//
//
//@Composable
//fun DivElement(id: String) {
//    DisposableEffect(id) {
//        val divElement = document.createElement("div") as HTMLDivElement
//        divElement.id = id
//        divElement.style.height = "400px"  // Set a fixed height for debugging
//        divElement.style.width = "100%"
//        divElement.style.display = "block"
//
//        document.body?.appendChild(divElement)
//
//        onDispose {
//            document.body?.removeChild(divElement)
//        }
//    }
//}
