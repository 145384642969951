package tta.destinigo.talktoastro.feature_common.persentation.more_tab.refer_and_earn.widget

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.material3.Text
import androidx.compose.ui.draw.clip
import androidx.compose.ui.layout.ScaleFactor
import androidx.compose.ui.text.style.TextAlign
import coil3.compose.AsyncImage
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.painterResource
import tta.destinigo.talktoastro.core.theme.Scale
import tta.destinigo.talktoastro.core.theme.background_color
import tta.destinigo.talktoastro.core.theme.primaryColor
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.refer_and_earn.data.response.WeeklyWinners
import tta.destinigo.talktoastro.resources.Res
import tta.destinigo.talktoastro.resources.avatar_13
import tta.destinigo.talktoastro.resources.avatar_6
import tta.destinigo.talktoastro.resources.coin_balance
import tta.destinigo.talktoastro.resources.salesiq_gold_medal
import tta.destinigo.talktoastro.resources.salesiq_silver_medal

@Composable
fun WeeklyTopWinners(data: List<WeeklyWinners>) {
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .background(background_color)
            .padding(vertical = 22.dp),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        // Title
        Text(
            text = "Weekly Top Winners",
            fontSize = 16.sp,
            style = MaterialTheme.typography.bodyLarge.copy(
                color = Color.Black
            ),
            modifier = Modifier.padding(top = 20.dp)
        )

        // Top Winners Row
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .padding(top = 16.dp),
            horizontalArrangement = Arrangement.SpaceEvenly,
            verticalAlignment = Alignment.CenterVertically
        ) {
            val sortedAccendingWeeklyWinners = data.sortedByDescending { it.coin }
            val secondWeeklyWinners = sortedAccendingWeeklyWinners[1]
            WinnerItem(
                imageRes = secondWeeklyWinners.image.toString(),
                medalRes = Res.drawable.salesiq_silver_medal,
                userName = secondWeeklyWinners.name.toString(),
                coinsEarned = secondWeeklyWinners.coin.toString()
            )
            val firstWeeklyWinners = sortedAccendingWeeklyWinners.first()
            WinnerItem(
                imageRes = firstWeeklyWinners.image.toString(),
                medalRes = Res.drawable.salesiq_gold_medal,
                userName = firstWeeklyWinners.name.toString(),
                coinsEarned = firstWeeklyWinners.coin.toString()
            )
            val thiredWeeklyWinners = sortedAccendingWeeklyWinners[2]
            WinnerItem(
                imageRes = thiredWeeklyWinners.image.toString(),
                medalRes = Res.drawable.salesiq_silver_medal,
                userName = thiredWeeklyWinners.name.toString(),
                coinsEarned = thiredWeeklyWinners.coin.toString()
            )
        }

        // Congratulatory Message
        Text(
            text = "You Won!",
            fontSize = 20.sp,
            modifier = Modifier.padding(top = 16.dp),
            style = MaterialTheme.typography.titleLarge
        )
        Text(
            text = "Congratulations For Winning",
            fontSize = 14.sp,
            modifier = Modifier,
            style = MaterialTheme.typography.bodySmall
        )

        // Note
        Text(
            text = "Note - Winner will be selected on basis of maximum coins earned in a week",
            fontSize = 12.sp,
            modifier = Modifier
                .fillMaxWidth(),
               // .padding(horizontal = 24.dp, vertical = 8.dp),
            style = MaterialTheme.typography.bodySmall,
            color = Color.Gray,
            lineHeight = 16.sp,
            textAlign = TextAlign.Center
            //  textAlign = Alignment.CenterHorizontally.textAlign
        )
    }
}

@Composable
fun WinnerItem(
    imageRes: String,
    medalRes: DrawableResource?,
    userName: String,
    coinsEarned: String
) {
    Column(
        modifier = Modifier,
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center
        ) {
        Box(
            contentAlignment = Alignment.TopEnd,
            modifier = Modifier.wrapContentSize(),
        ) {
            // Winner Image

            AsyncImage(
                model = imageRes,
                contentDescription = "Winner",
                modifier = Modifier
                    .size(75.dp)
                    .clip(CircleShape)
                    .background(color = Color.LightGray),
                contentScale = ContentScale.Crop,
                error = painterResource(Res.drawable.avatar_13)
            )
            // Medal Overlay
            medalRes?.let { painterResource(it) }?.let {
                Image(
                    painter = it,
                    contentDescription = "Medal",
                    modifier = Modifier
                        .size(35.dp)
                        .padding(5.dp)
                )
            }
        }

        // User Name
        Text(
            text = userName,
            fontSize = 12.sp,
            modifier = Modifier.padding(top = 4.dp),
            overflow = TextOverflow.Ellipsis,
            maxLines = 1,
            style = MaterialTheme.typography.bodySmall
        )

        // Coins Earned
        Box(
            modifier = Modifier
                .padding(top = 4.dp)
                .background(
                    color = primaryColor,
                    shape = RoundedCornerShape(Scale.CORNER_LARGE)
                )
                .padding(horizontal = 6.dp, vertical = 3.dp),
            contentAlignment = Alignment.Center
        ) {
            Row(verticalAlignment = Alignment.CenterVertically) {
            Image(
                painter = painterResource(Res.drawable.coin_balance),
                contentDescription = "Coin",
                modifier = Modifier.size(12.dp)
            )
                Spacer(modifier = Modifier.width(4.dp))
                Text(
                    text = coinsEarned,
                    fontSize = 12.sp,
                    color = Color.White,
                    style = MaterialTheme.typography.bodySmall
                )
            }
        }
    }
}
