package tta.destinigo.talktoastro.feature_post.data.response



import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CommentData(
    @SerialName("comment") val comment: String, // testing 2 time
    @SerialName("comment_date") val commentDate: String, // 2025-01-02
    @SerialName("comment_time") val commentTime: String, // 17:17:39
    @SerialName("created_at") val createdAt: String, // 2025-01-02 17:17:39
    @SerialName("deleted_at") val deletedAt: String?, // null
    @SerialName("id") val id: Int, // 279
    @SerialName("like") val like: Int, // 0
    @SerialName("parent_id") val parentId: String?, // null
    @SerialName("post_id") val postId: Int, // 655
    @SerialName("updated_at") val updatedAt: String, // 2025-01-02 17:17:39
    @SerialName("user_id") val userId: Int, // 54000
    @SerialName("user_name") val userName: String // 11 12 payment testing
)