package tta.destinigo.talktoastro.feature_common.persentation.more_tab.refer_and_earn.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import tta.destinigo.talktoastro.core.data.CommonResponse

@Serializable
data class MyEarningResponse(
    @SerialName("data")
    val earningData: EarningData?
) : CommonResponse()

@Serializable
data class EarningData(
    @SerialName("total_coin")
    val totalCoin: Int?,
    @SerialName("total_balance")
    val totalBalance: Int?,
    @SerialName("refer_history")
    val referMembers: List<ReferMember>
)
