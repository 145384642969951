package tta.destinigo.talktoastro.core.navigation_decompose

import com.arkivanov.decompose.router.stack.ChildStack
import com.arkivanov.decompose.value.Value
import com.arkivanov.essenty.backhandler.BackHandlerOwner
import tta.destinigo.talktoastro.feature_article.persentation.post_page.ArticleComponent
import tta.destinigo.talktoastro.feature_article.persentation.posts_page.ArticlesComponent
import tta.destinigo.talktoastro.feature_authentication.persentation.login_page.LoginPageComponent
import tta.destinigo.talktoastro.feature_authentication.persentation.otp_validation_page.OtpValidationComponent
import tta.destinigo.talktoastro.feature_call_consultation.persentation.call_bottom_sheet_info.CallConsultationComponent
import tta.destinigo.talktoastro.feature_call_consultation.persentation.call_consultation_options.CallConsultationOptionsComponent
import tta.destinigo.talktoastro.feature_call_consultation.persentation.call_success_page.CallSuccessComponent
import tta.destinigo.talktoastro.feature_call_consultation.persentation.voip_call_page.VoipCallComponent
import tta.destinigo.talktoastro.feature_chat_consultation_new.persentation.chat_host.decompose.HostDefaultComponent
import tta.destinigo.talktoastro.feature_common.persentation.bottom_navigation_bar.MainScreenComponent
import tta.destinigo.talktoastro.feature_common.persentation.home_tab.HomeScreenTabComponent
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.component.ChatConversationComponent
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.component.ChatHistoryComponent
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.component.EditProfileComponent
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.component.MoreScreenTabComponent
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.component.PrivacyPolicyComponent
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.component.TermsAndConditionsComponent
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.purchase_history.PurchaseHistoryComponent
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.refer_and_earn.ReferAndEarnScreenComponent
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.transaction_history.persentation.TransactionHistoryComponent
import tta.destinigo.talktoastro.feature_courses.persentation.course_page.CourseComponent
import tta.destinigo.talktoastro.feature_courses.persentation.courses_page.CoursesComponent
import tta.destinigo.talktoastro.feature_courses.persentation.landing_page.LandingPageComponent
import tta.destinigo.talktoastro.feature_custom_order.CustomOrderComponent
import tta.destinigo.talktoastro.feature_custom_order.custom_order_details.CustomOrderDetailsComponent
import tta.destinigo.talktoastro.feature_custom_order.custom_order_details.CustomOrderLessonComponent
import tta.destinigo.talktoastro.feature_custom_order.custom_order_details.EnrolErrorBottomSheetComponent
import tta.destinigo.talktoastro.feature_custom_order.custom_order_details.EnrollCustomOrderConfirmationBottomSheetComponent
import tta.destinigo.talktoastro.feature_expert_consultation.presentation.expert_detail_page.ExpertDetailsPageComponent
import tta.destinigo.talktoastro.feature_expert_consultation.presentation.expert_list_page.ExpertListPageComponent
import tta.destinigo.talktoastro.feature_expert_consultation.presentation.expert_search_page.SearchScreenComponent
import tta.destinigo.talktoastro.feature_post.PostDetailsComponent
import tta.destinigo.talktoastro.feature_post.PostScreenComponent
import tta.destinigo.talktoastro.feature_winyway_wallet.persentation.all_coupons_page.AllCouponComponent
import tta.destinigo.talktoastro.feature_winyway_wallet.persentation.razorpay_gateway_new.StartGatewayComponent
import tta.destinigo.talktoastro.feature_winyway_wallet.persentation.recharge_checkout.RechargeCheckoutComponent
import tta.destinigo.talktoastro.feature_winyway_wallet.persentation.recharge_failure.RechargeFailureComponent
import tta.destinigo.talktoastro.feature_winyway_wallet.persentation.recharge_getway.PaymentGatewayComponent
import tta.destinigo.talktoastro.feature_winyway_wallet.persentation.recharge_pack.ChoseRechargePackComponent
import tta.destinigo.talktoastro.feature_winyway_wallet.persentation.recharge_payment_success.RechargeSuccessComponent
import tta.destinigo.talktoastro.user_feature_authentication.persentation.register_page.RegisterComponent

interface RootComponent : BackHandlerOwner {

    val stack: Value<ChildStack<*, Child>>

    fun onBackClicked()
    fun onBackClicked(toIndex: Int)

    sealed class Child {
        data class LoginPage(val component: LoginPageComponent) : Child()
        data class OtpValidationPage(val component: OtpValidationComponent) : Child()
        data class RegistrationPage(val component: RegisterComponent) : Child()

        data class ExpertListPage(val component: ExpertListPageComponent) : Child()
        data class ExpertDetailsPage(val component: ExpertDetailsPageComponent) : Child()
        data class ChoseRechargePage(val component: ChoseRechargePackComponent) : Child()

        data class MoreScreenTab(val component: MoreScreenTabComponent) : Child()
        data class HomeScreenTab(val component: HomeScreenTabComponent) : Child()
        data class MainScreen(val component: MainScreenComponent) : Child()
        data class PrivacyPolicyPage(val component: PrivacyPolicyComponent) : Child()
        data class TermsAndConditions(val component: TermsAndConditionsComponent) : Child()
        data class EditProfileScreen(val component: EditProfileComponent) : Child()

        //feature_winyway_wallet
        data class RechargeCheckout(val component: RechargeCheckoutComponent,val secondComponent: AllCouponComponent) : Child()
        data class PaymentGateway(val component: PaymentGatewayComponent) : Child()
        data class RechargeSuccess(val component: RechargeSuccessComponent) : Child()
        data class RechargeFailure(val component: RechargeFailureComponent) : Child()
        data class StartGatewayChild(val component: StartGatewayComponent) : Child()

        data class LandingPage(val component: LandingPageComponent, val homeComponent: HomeScreenTabComponent) : Child()

        // feature_expert_search
        data class DisplaySearchExpertList(val component: SearchScreenComponent) : Child()

        data class ChatHostChild(
            val component: HostDefaultComponent
        ) : Child()

        data class CallConsultationOptions(
            val component: CallConsultationOptionsComponent
        ): Child()

        data class CallConsultationInfo(
            val component: CallConsultationComponent
        ): Child()

        data class CallPlaceSuccess(
            val component: CallSuccessComponent
        ): Child()

        data class CallWaitingSuccess(
            val component: CallSuccessComponent
        ):Child()

        /*VoIP Call*/
        data class VoIPCall(
            val component: VoipCallComponent
        ):Child()

        //TransactionHistory
        data class TransactionHistory(val component: TransactionHistoryComponent):Child()

        // All Coupon Screen
        data class DisplayAllCouponCode(val component: AllCouponComponent):Child()

        // Chat History Screen
        data class ChatHistory(val component: ChatHistoryComponent,val expertDetailsPageComponent: ExpertDetailsPageComponent):Child()

        // Chat Conversation Screen
        data class ChatConversationScreen(val component: ChatConversationComponent):Child()

        // Article Host Screen
        data class ArticleHost(val component: ArticlesComponent):Child()

        // Article Detail Page
        data class ArticleDetailPage(val component:ArticleComponent):Child()

        // Custom Order
        data class CustomOrderPage(val component: CustomOrderComponent) : Child()

        //  Custom Order Details
        data class CustomOrderDetails(val component:CustomOrderDetailsComponent):Child()

        // Courses
        data class Courses(val component: CoursesComponent) : Child()
        data class Course(val component: CourseComponent) : Child()

        // Custom Order Confirmation
        data class CustomOrderConfirmation(val component: EnrollCustomOrderConfirmationBottomSheetComponent):Child()

        data class CustomOrderError(val component: EnrolErrorBottomSheetComponent):Child()

        data class CustomOrderLesson(val component:CustomOrderLessonComponent):Child()

        // Purchase History
        data class PurchaseHistory(val component:PurchaseHistoryComponent):Child()

        // Refer and Earn
        data class ReferAndEarn(val component:ReferAndEarnScreenComponent):Child()

        //Post
        data class PostScreen(val component: PostScreenComponent): Child()

        data class PostDetailsScreenChild(val component: PostDetailsComponent): Child()
    }
}