package tta.destinigo.talktoastro.feature_post.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class PostDetailsResponse(
    @SerialName("code") val code: Int, // 200
    @SerialName("data") val `data`: PostDetailsResponseData,
    @SerialName("message") val message: String, // Post data fetched Successfully
    @SerialName("status") val status: Boolean // true
)
