package tta.destinigo.talktoastro.feature_authentication.data.request

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class RequestOtpRequest(
    @SerialName("mobile")val mobile: String,
    @SerialName("type")val type: String,
    @SerialName("whatsapp_updates")val whatsappUpdate: Int,
    @SerialName("mobile_code")val mobileCode: String,
    @SerialName("domain")val domainName: String,
)
//domain = talktoastro.com