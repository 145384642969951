package tta.destinigo.talktoastro.feature_post.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class PostLikeData(
    @SerialName("created_at") val createdAt: String, // 2024-01-12 16:39:19
    @SerialName("deleted_at") val deletedAt: String?, // null
    @SerialName("expert_category") val expertCategory: String?, // null
    @SerialName("expert_id") val expertId: Int, // 69
    @SerialName("expert_name") val expertName: String, // Jyoti
    @SerialName("id") val id: Int, // 613
    @SerialName("is_approved") val isApproved: Int, // 0
    @SerialName("is_delete") val isDelete: Int, // 0
    @SerialName("post_content") val postContent: String, // http://localhost/winywayapi/public/post_flies/1705057759.jpg
    @SerialName("post_content_type") val postContentType: String, // image
    @SerialName("post_creation_date") val postCreationDate: String, // 2024-01-12
    @SerialName("post_creation_time") val postCreationTime: String, // 16:39:19
    @SerialName("post_description") val postDescription: String, // test
    @SerialName("updated_at") val updatedAt: String, // 2024-01-12 16:39:19
    @SerialName("user_id") val userId: String? // null
)
