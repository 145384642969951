package tta.destinigo.talktoastro.feature_post

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.heightIn
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Share
import androidx.compose.material3.Button
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.AnnotatedString
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.font.FontFamily
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.text.withStyle
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import coil3.compose.AsyncImage
import org.jetbrains.compose.resources.Font
import org.jetbrains.compose.resources.painterResource
import tta.destinigo.talktoastro.core.persentation.showToast
import tta.destinigo.talktoastro.core.theme.Scale
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_COMPACT
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_EXPEND
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_MEDIUM
import tta.destinigo.talktoastro.core.theme.primaryColor
import tta.destinigo.talktoastro.feature_common.persentation.home_tab.HomeScreenTabComponent
import tta.destinigo.talktoastro.feature_post.data.response.PostDataNew
import tta.destinigo.talktoastro.resources.NotoColorEmoji_Regular
import tta.destinigo.talktoastro.resources.NotoEmoji_Regular
import tta.destinigo.talktoastro.resources.NotoSansDevanagari_Regular
import tta.destinigo.talktoastro.resources.Res
import tta.destinigo.talktoastro.resources.post_banner
import tta.destinigo.talktoastro.resources.profile_icon_avtar
import tta.destinigo.talktoastro.resources.roboto_regular
import tta.destinigo.talktoastro.resources.share_icon_new


@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun PostCardList(modifier: Modifier, component: HomeScreenTabComponent, onSeeAllClick: () -> Unit,onReadPostClick: (PostDataNew) -> Unit) {
    val state by component.postState.collectAsState()

    Box(
        modifier = Modifier.fillMaxWidth(),
        contentAlignment = Alignment.Center
    ) {
        Column(
            modifier = modifier.padding(horizontal = 16.dp)
        ) {
            Row(
                modifier = Modifier,
                horizontalArrangement = Arrangement.SpaceBetween,
                verticalAlignment = Alignment.CenterVertically
            ) {
                Text(
                    text = "Top Post",
                    textAlign = TextAlign.Start,
                    style = MaterialTheme.typography.titleMedium,
                    modifier = Modifier.padding(bottom = 16.dp)
                )
                Spacer(modifier = Modifier.weight(1f))
                Text(
                    text = "See All",
                    color = primaryColor,
                    style = MaterialTheme.typography.titleMedium,
                    textAlign = TextAlign.End,
                    modifier = Modifier.padding(bottom = 16.dp)
                        .clickable { onSeeAllClick.invoke() }
                )
            }

            LazyRow(
                modifier = Modifier.fillMaxWidth(),
                contentPadding = PaddingValues(horizontal = 8.dp),
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
//                items(7) { // Replace 4 with your dynamic list size
//                    BlogCard()
//                }

                item {
                    state.forEach { post ->
                        BlogCard(post, onReadPostClick = { clickPost ->
                            onReadPostClick.invoke(clickPost)
                        })
                        Spacer(modifier = Modifier.width(10.dp))
                    }
                }
            }
        }
    }
}

@Composable
fun BlogCard(post: PostDataNew,onReadPostClick:(PostDataNew) -> Unit) {
    Card(
        modifier = Modifier.clickable { onReadPostClick.invoke(post) }
            .width(250.dp)
            .heightIn(min = 300.dp, max = 300.dp),
        shape = RoundedCornerShape(8.dp),
        colors = CardDefaults.cardColors(Color.White),
        elevation = CardDefaults.cardElevation(Scale.ELEVATION_SMALL)
    ) {
        Column(
            modifier = Modifier.padding(16.dp)
        ) {
            if (post.postContent?.isNotEmpty() == true) {
                AsyncImage(
                    model = post.postContent,
                    contentDescription = "Post Image",
                    modifier = Modifier
                        .height(120.dp)
                        .fillMaxWidth(),
                    contentScale = ContentScale.Crop,
                    error = painterResource(Res.drawable.post_banner)
                )
            }
            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(vertical = 8.dp),
                verticalAlignment = Alignment.CenterVertically // Align items vertically in the center
            ) {
                AsyncImage(
                    model = post.consultantProfile,
                    contentDescription = "Author Image",
                    modifier = Modifier
                        .size(25.dp) // Constrain both height and width to 50.dp for a square image
                        .clip(CircleShape) // Optional: Make the image circular
                        .background(Color.Gray), // Optional: Add a background color for loading
                    contentScale = ContentScale.Crop,
                    error = painterResource(Res.drawable.profile_icon_avtar)
                )
                Spacer(modifier = Modifier.width(8.dp)) // Add space between the image and text
                Text(
                    text = post.expertName.orEmpty(),
                    style = MaterialTheme.typography.bodySmall,
                    modifier = Modifier.weight(1f) // Allow the text to take remaining space
                )
            }

            val hindiFont = FontFamily(Font(Res.font.NotoSansDevanagari_Regular))
            val englishFont = FontFamily(Font(Res.font.roboto_regular))
            val emojiFont = FontFamily(Font(Res.font.NotoColorEmoji_Regular)) // Replace with the actual emoji font resource if needed.

            val emojiRegex = Regex("[\\uD83C-\\uDBFF\\uDC00-\\uDFFF]+") // Matches emojis
            val hindiRegex = Regex("[\\u0900-\\u097F\\u1CD0-\\u1CFF\\uA8E0-\\uA8FF]+")  // Matches Hindi characters

            // Function to determine the font for each word
            fun getFontForWord(word: String): FontFamily {
                return when {
                    word.contains(emojiRegex) -> emojiFont // Use emoji font if emojis are detected
                    word.contains(hindiRegex) -> hindiFont  // Use Hindi font if Hindi characters are detected
                    else -> englishFont // Default to English font
                }
            }

            // Function to create an AnnotatedString with proper font for each part of the text
            fun createStyledText(postDescription: String): AnnotatedString {
                val words = postDescription.split(" ")
                val annotatedString = buildAnnotatedString {
                    words.forEachIndexed { index, word ->
                        val fontFamily = getFontForWord(word)
                        withStyle(style = SpanStyle(fontFamily = fontFamily)) {
                            append(word)
                        }
                        if (index < words.size - 1) append(" ") // Add space between words
                    }
                }
                return annotatedString
            }

            val styledText = post.postDescription?.let { createStyledText(it) } ?: AnnotatedString("")
            Spacer(modifier = Modifier.height(8.dp))

            Text(
                text = styledText,
                fontSize = 16.sp,
                style = MaterialTheme.typography.bodySmall,
                maxLines = 2,
                overflow = TextOverflow.Ellipsis,
                modifier = Modifier.weight(1f)
            )

            Spacer(modifier = Modifier.height(8.dp))
            Row(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.SpaceBetween,
                verticalAlignment = Alignment.CenterVertically
            ) {
                Button(
                    onClick = { onReadPostClick.invoke(post) },
                    shape = RoundedCornerShape(Scale.CORNER_MEDIUM)
                ) {
                    Text("Read Post")
                }
                IconButton(onClick = { showToast("Coming Soon") }) {
                    Icon(
                        painter = painterResource(Res.drawable.share_icon_new),
                        //imageVector = Icons.Default.Share,
                        contentDescription = "Share"
                    )
                }
            }
        }
    }
}


//@Composable
//fun BlogCard(post: PostDataNew) {
//    Card(
//        modifier = Modifier
//            .width(250.dp), // Adjust width as needed,
//            //.wrapContentHeight(),
//        shape = RoundedCornerShape(8.dp),
//        colors = CardDefaults.cardColors(Color.White),
//        elevation = CardDefaults.cardElevation(Scale.ELEVATION_SMALL)
//    ) {
//        Column(
//            modifier = Modifier.padding(16.dp)
//        ) {
//            if(post.postContent?.isNotEmpty() == true){
//                AsyncImage(
//                    model = post.postContent,
//                    contentDescription = "Post Image",
//                    modifier = Modifier.height(120.dp).fillMaxWidth(),
//                    contentScale = ContentScale.Crop,
//                    error = painterResource(Res.drawable.post_banner)
//                )
//            }
//            Text(
//                text = post.expertName.toString(),
//                style = MaterialTheme.typography.bodySmall,
//               // modifier = Modifier.padding(bottom = 8.dp)
//            )
////            Image(
////                painter = painterResource(Res.drawable.post_banner), // Replace with your image
////                contentDescription = "Blog Image",
////                contentScale = ContentScale.Crop,
////                modifier = Modifier
////                    .fillMaxWidth()
////                    .height(120.dp)
////            )
//            Spacer(modifier = Modifier.height(8.dp))
////            Text(
////                text = "Lorem Ipsum",
////                style = MaterialTheme.typography.headlineSmall,
////                modifier = Modifier.padding(bottom = 8.dp)
////            )
//            Text(
//                text = post.postDescription.toString(),
//                style = MaterialTheme.typography.bodySmall,
//                maxLines = 3,
//                overflow = TextOverflow.Ellipsis,
//                modifier = Modifier.padding(bottom = 16.dp)
//            )
//            Row(
//                modifier = Modifier.fillMaxWidth(),
//                horizontalArrangement = Arrangement.SpaceBetween,
//                verticalAlignment = Alignment.CenterVertically
//            ) {
//                Button(
//                    onClick = { /* Handle Read Blog */ },
//                    shape = RoundedCornerShape(Scale.CORNER_MEDIUM)
//                ) {
//                    Text("Read Post")
//                }
//                IconButton(onClick = { /* Handle Share */ }) {
//                    Icon(
//                        imageVector = Icons.Default.Share,
//                        contentDescription = "Share"
//                    )
//                }
//            }
//        }
//    }
//}

