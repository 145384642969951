package tta.destinigo.talktoastro.feature_chat_consultation_new.persentation.chat_room_view

import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.background
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.WindowInsets
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.heightIn
import androidx.compose.foundation.layout.ime
import androidx.compose.foundation.layout.imePadding
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.safeDrawing
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.windowInsetsPadding
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.LazyItemScope
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.CornerSize
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.Chat
import androidx.compose.material.icons.filled.Chat
import androidx.compose.material.icons.filled.Close
import androidx.compose.material.icons.filled.Send
import androidx.compose.material.icons.filled.Timer
import androidx.compose.material3.Divider
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.TextField
import androidx.compose.material3.TextFieldDefaults
import androidx.compose.material3.lightColorScheme
import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.SideEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberUpdatedState
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.setValue
import androidx.compose.runtime.snapshotFlow
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.clipToBounds
import androidx.compose.ui.draw.shadow
import androidx.compose.ui.focus.FocusRequester
import androidx.compose.ui.focus.focusRequester
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalFocusManager
import androidx.compose.ui.platform.LocalSoftwareKeyboardController
import androidx.compose.ui.text.AnnotatedString
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.font.FontFamily
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.input.OffsetMapping
import androidx.compose.ui.text.input.TextFieldValue
import androidx.compose.ui.text.input.TransformedText
import androidx.compose.ui.text.input.VisualTransformation
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.withStyle
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import co.touchlab.kermit.Logger
import com.arkivanov.essenty.backhandler.BackCallback
import com.arkivanov.essenty.backhandler.BackHandler
import org.jetbrains.compose.resources.Font
import tta.destinigo.talktoastro.core.persentation.CommonButton
import tta.destinigo.talktoastro.core.persentation.ExpertProfileIcon
import tta.destinigo.talktoastro.core.persentation.LoadingProgressDialog
import tta.destinigo.talktoastro.core.persentation.showToast
import tta.destinigo.talktoastro.core.theme.Scale
import tta.destinigo.talktoastro.core.theme.background_color
import tta.destinigo.talktoastro.core.theme.grey_700
import tta.destinigo.talktoastro.core.theme.primaryColor
import tta.destinigo.talktoastro.core.timer.CountdownTimer
import tta.destinigo.talktoastro.core.timer.formatTime
import tta.destinigo.talktoastro.feature_chat_consultation.domain.Sender
import tta.destinigo.talktoastro.feature_chat_consultation.data.response.ChatMessage
import tta.destinigo.talktoastro.feature_chat_consultation.persentation.chat_room_view.ChatRoomMsgState
import tta.destinigo.talktoastro.feature_chat_consultation.persentation.chat_room_view.ChatRoomState
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.response.ChatConcernItem
import tta.destinigo.talktoastro.feature_chat_consultation_new.persentation.chat_room_view.widget.ChatEndedDialog
import tta.destinigo.talktoastro.feature_chat_consultation_new.persentation.chat_room_view.widget.EndChatConfirmationDialog
import tta.destinigo.talktoastro.resources.NotoColorEmoji_Regular
import tta.destinigo.talktoastro.resources.NotoSansDevanagari_Regular
import tta.destinigo.talktoastro.resources.Res
import tta.destinigo.talktoastro.resources.roboto_regular

@Composable
fun ChatRoomScreen(
    component: ChatRoomComponent
) {

    val state by component.state.collectAsState()
    var isDialogOpen by remember { mutableStateOf(false) }
    var isChatEndedDialogOpen by remember { mutableStateOf(false) }

    if (state is ChatRoomState.OnLoading) {
        val isLoading = (state as ChatRoomState.OnLoading).isLoading
        if (isLoading) LoadingProgressDialog { }
    }

    Logger.d("BackHandler -> ${component.backHandler}")
    BackHandler(backHandler = component.backHandler) {
        Logger.d("BackHandler - INIT")
        isDialogOpen = true
    }

    if (state is ChatRoomState.OnEndChatSuccess) {
        isChatEndedDialogOpen = true
    }

    ChatEndedDialog(
        onGoToDashboard = {
            isChatEndedDialogOpen = false
            component.onGoToDashBoard()
        }, isDialogOpen = isChatEndedDialogOpen
    )

    EndChatConfirmationDialog(onConfirm = {
        component.onEndChat()
        // Handle end chat logic
        isDialogOpen = false
    }, onDismiss = {
        // Handle dismiss logic
        isDialogOpen = false
    }, isDialogOpen = isDialogOpen
    )


    MaterialTheme(
        colorScheme = lightColorScheme()
    ) {

        if (state is ChatRoomState.OnChatStart) {

            val data = (state as ChatRoomState.OnChatStart).chatData

            Column(
                modifier = Modifier.fillMaxSize().background(background_color)
            ) {
                ChatScreenExpert(
                    expertName = component.expertName,
                    expertImage = component.expertImage,
                    chatDuration = data.totalDurationToChat,
                    onEndChat = {
                        isDialogOpen = true
                    },
                    viewModel = component
                )
                ChatScreen(component)
            }
        }
    }

}


@Composable
fun ChatScreenExpert(
    expertName: String,
    expertImage: String,
    chatDuration: Int,
    onEndChat: () -> Unit,
    viewModel: ChatRoomComponent
) {
    val isExpertEnded by viewModel.endedByExpert.collectAsState()
    val countdownTimer =
        rememberSaveable { CountdownTimer(durationMillis = (chatDuration.times(1000).toLong())) }
    countdownTimer.start()
    countdownTimer.onFinish?.let {
        onEndChat.invoke()
    }

    val timeLeft by countdownTimer.timeLeft.collectAsState(initial = 0L)

    if (isExpertEnded) {
        countdownTimer.pause()
    }

    DisposableEffect(Unit) {
        onDispose {
            countdownTimer.cleanup()
        }
    }

    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = Modifier.fillMaxWidth().background(color = Color.White).padding(12.dp),
        horizontalArrangement = Arrangement.SpaceBetween // Align items at the ends of the row
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically
        ) {
            ExpertProfileIcon(
                profileImageUrl = expertImage, modifier = Modifier.size(38.dp)
            )
            Spacer(modifier = Modifier.width(12.dp))
            Column(

                modifier = Modifier.wrapContentHeight()
            ) {
                Text(
                    text = expertName, maxLines = 1, style = MaterialTheme.typography.titleSmall
                )
                Row {
                    Icon(
                        imageVector = Icons.Default.Timer,
                        contentDescription = null,
                        tint = grey_700.copy(alpha = 0.7f),
                        modifier = Modifier.size(16.dp)
                    )
                    Spacer(modifier = Modifier.width(4.dp))
                    Text(
                        text = formatTime(timeLeft),
                        maxLines = 1,
                        style = MaterialTheme.typography.labelSmall
                    )
                }

            }
        }

        // This Row contains "Chat Again" button and icon
        CommonButton(buttonText = "End Chat", modifier = Modifier.height(35.dp), onClick = {
            countdownTimer.pause()
            onEndChat.invoke()
        })
    }
}

@OptIn(ExperimentalFoundationApi::class)
@Composable
fun ChatScreen(viewModel: ChatRoomComponent) {
    val msgList = viewModel.chatMsg
    val state by viewModel.msgState.collectAsState()
    val typingState by viewModel.typingState.collectAsState()
    val listState = rememberLazyListState()
    val isExpertEnded by viewModel.endedByExpert.collectAsState()
    val concernQuestionState by viewModel.state.collectAsState()



    Column(
        modifier = Modifier.fillMaxSize()
            .windowInsetsPadding(WindowInsets.safeDrawing)// Ensures the column takes up the full screen
    ) {




        // Chat messages
        LaunchedEffect(msgList.size) {

            if (state is ChatRoomMsgState.MsgUpdated) {
                listState.animateScrollToItem(index = msgList.lastIndex)
            }
        }

        LazyColumn(
            verticalArrangement = Arrangement.Bottom,
            modifier = Modifier.weight(1f).imePadding(),
            state = listState,
            contentPadding = PaddingValues(vertical = 4.dp, horizontal = 8.dp)
        ) {

            item {
                when(val currentState = concernQuestionState){
                    ChatRoomState.InitialState -> TODO()
                    is ChatRoomState.OnChatStart -> {
                        currentState.chatData.chatConcernItem?.let { ChatConcernQuestion(chatConcernItems = it) }
                    }
                    ChatRoomState.OnEndChat -> TODO()
                    ChatRoomState.OnEndChatSuccess -> TODO()
                    is ChatRoomState.OnErrorState -> TODO()
                    is ChatRoomState.OnLoading -> TODO()
                }
            }

            if (state is ChatRoomMsgState.MsgUpdated) {
                items(msgList.size) { index ->
                    val message = msgList[index]
//                    if (message.sender == Sender.USER) {
//                        UserMessage(
//                            message = message, modifier = Modifier.animateItemPlacement()
//                        )
//                    } else {
//                        ExpertMessage(
//                            message = message, modifier = Modifier.animateItemPlacement()
//                        )
//                    }

                    if (message.senderEnum == Sender.USER) {
                        UserMessage(
                            message = message, modifier = Modifier.animateItemPlacement()
                        )
                    } else {
                        ExpertMessage(
                            message = message, modifier = Modifier.animateItemPlacement()
                        )
                    }
                }
            }

            if (typingState) {
                item {
                    ExpertMessage(
                        message = ChatMessage(
                            id = null,
                            msg = "Typing...",
                           // sender = Sender.EXPERT,
                            sender = 0,
                            date = "",
                            msgType =null,
                            isAttachmentUploaded = false,
                            userConcern = null

                        ), modifier = Modifier.animateItemPlacement()
                    )

                }
            }

            if (isExpertEnded) {
                item {
                    ExpertIndicator()
                }
            }

        }

        ChatInputLayout(onSendChat = { msg ->
            if (isExpertEnded){
                showToast("Chat Already Ended!")
                return@ChatInputLayout
            }
            viewModel.onSendMsg(msg)

        }, onTyping = { isTyping ->
            viewModel.onTypingStatus(isTyping)
        }, modifier = Modifier.padding(bottom = 16.dp, start = 16.dp, end = 16.dp).fillMaxWidth()
            .windowInsetsPadding(WindowInsets.ime)
        )
    }
}


//@OptIn(ExperimentalFoundationApi::class)
//@Composable
//fun ChatScreen(viewModel: ChatRoomComponent) {
//    val msgList = viewModel.chatMsg
//    val state by viewModel.msgState.collectAsState()
//    val typingState by viewModel.typingState.collectAsState()
//    val listState = rememberLazyListState()
//    val isExpertEnded by viewModel.endedByExpert.collectAsState()
//    val concernQuestionState by viewModel.state.collectAsState()
//
//    var autoScrollEnabled by remember { mutableStateOf(true) }
//
//    Column(
//        modifier = Modifier
//            .fillMaxSize()
//            .windowInsetsPadding(WindowInsets.safeDrawing)
//    ) {
//
//        // Render different states at the start of the chat
//        when (val currentState = concernQuestionState) {
//            ChatRoomState.InitialState -> TODO()
//            is ChatRoomState.OnChatStart -> {
//                currentState.chatData.chatConcernItem?.let {
//                    ChatConcernQuestion(chatConcernItems = it)
//                }
//            }
//            ChatRoomState.OnEndChat -> TODO()
//            ChatRoomState.OnEndChatSuccess -> TODO()
//            is ChatRoomState.OnErrorState -> TODO()
//            is ChatRoomState.OnLoading -> TODO()
//        }
//
//        // Auto-scroll when new messages arrive, unless user scrolled up
//        LaunchedEffect(listState) {
//            snapshotFlow { msgList.size }
//                .collect { newSize ->
//                    if (autoScrollEnabled && state is ChatRoomMsgState.MsgUpdated) {
//                        listState.animateScrollToItem(index = newSize - 1)
//                    }
//                }
//        }
//
//        // Track user scrolling up to disable auto-scroll
//        LaunchedEffect(listState) {
//            snapshotFlow { listState.firstVisibleItemIndex }
//                .collect { index ->
//                    autoScrollEnabled = index == msgList.lastIndex
//                }
//        }
//
//        LazyColumn(
//            verticalArrangement = Arrangement.Bottom,
//            modifier = Modifier
//                .weight(1f)
//                .imePadding(),
//            state = listState,
//            contentPadding = PaddingValues(vertical = 4.dp, horizontal = 8.dp)
//        ) {
//
//            // Display chat messages
//            if (state is ChatRoomMsgState.MsgUpdated) {
//                items(msgList.size) { index ->
//                    val message = msgList[index]
//                    if (message.senderEnum == Sender.USER) {
//                        UserMessage(
//                            message = message,
//                            modifier = Modifier.animateItemPlacement()
//                        )
//                    } else {
//                        ExpertMessage(
//                            message = message,
//                            modifier = Modifier.animateItemPlacement()
//                        )
//                    }
//                }
//            }
//
//            // Show typing indicator
//            if (typingState) {
//                item {
//                    ExpertMessage(
//                        message = ChatMessage(
//                            id = null,
//                            msg = "Typing...",
//                            sender = 0, // Expert sender
//                            date = "",
//                            msgType = null,
//                            isAttachmentUploaded = false,
//                            userConcern = null
//                        ),
//                        modifier = Modifier.animateItemPlacement()
//                    )
//                }
//            }
//
//            // Show end-of-chat indicator if the expert ended the chat
//            if (isExpertEnded) {
//                item {
//                    ExpertIndicator()
//                }
//            }
//        }
//
//        // Chat input layout with send and typing actions
//        ChatInputLayout(
//            onSendChat = { msg ->
//                if (isExpertEnded) {
//                    showToast("Chat Already Ended!")
//                    return@ChatInputLayout
//                }
//                viewModel.onSendMsg(msg)
//                autoScrollEnabled = true // Enable auto-scroll when user sends a new message
//            },
//            onTyping = { isTyping ->
//                viewModel.onTypingStatus(isTyping)
//            },
//            modifier = Modifier
//                .padding(bottom = 16.dp, start = 16.dp, end = 16.dp)
//                .fillMaxWidth()
//                .windowInsetsPadding(WindowInsets.ime)
//        )
//    }
//}

//@OptIn(ExperimentalFoundationApi::class)
//@Composable
//fun ChatScreen(viewModel: ChatRoomComponent) {
//    val msgList = viewModel.chatMsg
//    val state by viewModel.msgState.collectAsState()
//    val typingState by viewModel.typingState.collectAsState()
//    val listState = rememberLazyListState()
//    val isExpertEnded by viewModel.endedByExpert.collectAsState()
//    val concernQuestionState by viewModel.state.collectAsState()
//
//    var autoScrollEnabled by remember { mutableStateOf(true) }
//
//    Column(
//        modifier = Modifier
//            .fillMaxSize()
//            .windowInsetsPadding(WindowInsets.safeDrawing)
//    ) {
//
//        // Render different states at the start of the chat
//        when (val currentState = concernQuestionState) {
//            ChatRoomState.InitialState -> TODO()
//            is ChatRoomState.OnChatStart -> {
//                currentState.chatData.chatConcernItem?.let {
//                    ChatConcernQuestion(chatConcernItems = it)
//                }
//            }
//
//            ChatRoomState.OnEndChat -> TODO()
//            ChatRoomState.OnEndChatSuccess -> TODO()
//            is ChatRoomState.OnErrorState -> TODO()
//            is ChatRoomState.OnLoading -> TODO()
//        }
//
//        // Auto-scroll when new messages arrive
//        LaunchedEffect(msgList.size) {
//            if (autoScrollEnabled) {
//                listState.animateScrollToItem(index = msgList.lastIndex)
//            }
//        }
//
//        // Track user scrolling to manage auto-scroll state
//        LaunchedEffect(listState) {
//            snapshotFlow { listState.isScrollInProgress }
//                .collect { isScrolling ->
//                    autoScrollEnabled = !isScrolling || listState.firstVisibleItemIndex == msgList.size - 1
//                }
//        }
//
//        LazyColumn(
//            verticalArrangement = Arrangement.Bottom,
//            modifier = Modifier
//                .weight(1f)
//                .imePadding(),
//            state = listState,
//            contentPadding = PaddingValues(vertical = 4.dp, horizontal = 8.dp)
//        ) {
//            // Display chat messages
//            if (state is ChatRoomMsgState.MsgUpdated) {
//                items(msgList.size) { index ->
//                    val message = msgList[index]
//                    if (message.senderEnum == Sender.USER) {
//                        UserMessage(
//                            message = message,
//                            modifier = Modifier.animateItemPlacement()
//                        )
//                    } else {
//                        ExpertMessage(
//                            message = message,
//                            modifier = Modifier.animateItemPlacement()
//                        )
//                    }
//                }
//            }
//
//            // Show typing indicator
//            if (typingState) {
//                item {
//                    ExpertMessage(
//                        message = ChatMessage(
//                            id = null,
//                            msg = "Typing...",
//                            sender = 0,
//                            date = "",
//                            msgType = null,
//                            isAttachmentUploaded = false,
//                            userConcern = null
//                        ),
//                        modifier = Modifier.animateItemPlacement()
//                    )
//                }
//            }
//
//            // Show end-of-chat indicator if the expert ended the chat
//            if (isExpertEnded) {
//                item {
//                    ExpertIndicator()
//                }
//            }
//        }
//
//        // Chat input layout with send and typing actions
//        ChatInputLayout(
//            onSendChat = { msg ->
//                if (isExpertEnded) {
//                    showToast("Chat Already Ended!")
//                    return@ChatInputLayout
//                }
//                viewModel.onSendMsg(msg)
//                autoScrollEnabled = true
//            },
//            onTyping = { isTyping ->
//                viewModel.onTypingStatus(isTyping)
//            },
//            modifier = Modifier
//                .padding(bottom = 16.dp, start = 16.dp, end = 16.dp)
//                .fillMaxWidth()
//                .windowInsetsPadding(WindowInsets.ime)
//        )
//    }
//}




@Composable
fun ChatConcernQuestion(chatConcernItems: List<ChatConcernItem>) {
    Row(
        modifier = Modifier.fillMaxWidth(),
        horizontalArrangement = Arrangement.End
    ) {
        Box(
            modifier = Modifier
                .wrapContentWidth()
                .padding(8.dp)
                .clip(RoundedCornerShape(8.dp))
                .background(color = MaterialTheme.colorScheme.surface)
                .shadow(elevation = 4.dp, shape = RoundedCornerShape(8.dp)),

            ) {
            Column(
                modifier = Modifier
                    .padding(16.dp) // Padding for internal content spacing
            ) {
                chatConcernItems.forEach { item ->
                    Text(
                        text = item.question.toString(),
                        style = MaterialTheme.typography.titleMedium,
                        color = MaterialTheme.colorScheme.onSurface
                    )
                    Spacer(modifier = Modifier.height(4.dp))
                    Text(
                        text = item.answer.toString(),
                        style = MaterialTheme.typography.bodyMedium,
                        color = MaterialTheme.colorScheme.onSurfaceVariant
                    )
                    Spacer(modifier = Modifier.height(16.dp)) // Spacing between entries
                }
            }
        }
    }
}


//@Composable
//fun ChatConcernQuestion(chatConcernItems: List<ChatConcernItem>) {
//    LazyColumn(
//        modifier = Modifier.fillMaxWidth()
//    ) {
//        items(chatConcernItems.size) { chatConcernItemIndex ->
//            val item = chatConcernItems[chatConcernItemIndex]
//            Column(modifier = Modifier.padding(8.dp)) {
//                Text(text = item.question.toString(), style = MaterialTheme.typography.titleMedium)
//                Spacer(modifier = Modifier.height(4.dp))
//                Text(text = item.answer.toString(), style = MaterialTheme.typography.titleSmall)
//                Divider(modifier = Modifier.padding(vertical = 8.dp))
//            }
//        }
//    }
//}


@Composable
fun ExpertMessage(
    message: ChatMessage,
    modifier: Modifier,
) {

    val hindiFont = FontFamily(Font(Res.font.NotoSansDevanagari_Regular))
    val englishFont = FontFamily(Font(Res.font.roboto_regular))
    val emojiFont = FontFamily(Font(Res.font.NotoColorEmoji_Regular)) // Replace with the actual emoji font resource if needed.

    val emojiRegex = Regex("[\\uD83C-\\uDBFF\\uDC00-\\uDFFF]+") // Matches emojis
    val hindiRegex = Regex("[\\u0900-\\u097F\\u1CD0-\\u1CFF\\uA8E0-\\uA8FF]+") // Matches Hindi characters

    // Function to determine the font for each word
    fun getFontForWord(word: String): FontFamily {
        return when {
            word.contains(emojiRegex) -> emojiFont // Use emoji font if emojis are detected
            word.contains(hindiRegex) -> hindiFont  // Use Hindi font if Hindi characters are detected
            else -> englishFont // Default to English font
        }
    }

    // Function to create an AnnotatedString with proper font for each part of the text
    fun createStyledText(postDescription: String): AnnotatedString {
        val words = postDescription.split(" ")
        val annotatedString = buildAnnotatedString {
            words.forEachIndexed { index, word ->
                val fontFamily = getFontForWord(word)
                withStyle(style = SpanStyle(fontFamily = fontFamily)) {
                    append(word)
                }
                if (index < words.size - 1) append(" ") // Add space between words
            }
        }
        return annotatedString
    }

    val styledText = message.msg.let { createStyledText(it) } ?: AnnotatedString("")


    Row(
        modifier = modifier.fillMaxWidth().padding(horizontal = 8.dp, vertical = 4.dp),
        horizontalArrangement = Arrangement.Start
    ) {
        Box(
            modifier = Modifier.background(
                primaryColor.copy(alpha = 0.15F), shape = RoundedCornerShape(
                    topStart = CornerSize(percent = 8),
                    topEnd = CornerSize(percent = 8),
                    bottomStart = CornerSize(0.dp),
                    bottomEnd = CornerSize(percent = 8)
                )
            ).padding(horizontal = 12.dp, vertical = 8.dp)
        ) {

            Text(
                text = styledText, fontSize = 14.sp, // Adjust text size as needed
                textAlign = TextAlign.Start, modifier = Modifier.align(Alignment.Center)
            )
//            Text(
//                text = message.msg, fontSize = 14.sp, // Adjust text size as needed
//                textAlign = TextAlign.Start, modifier = Modifier.align(Alignment.Center)
//            )
        }
        // Add any other UI components related to the expert message
    }
}

@Composable
fun UserMessage(
    message: ChatMessage, modifier: Modifier
) {

    val hindiFont = FontFamily(Font(Res.font.NotoSansDevanagari_Regular))
    val englishFont = FontFamily(Font(Res.font.roboto_regular))
    val emojiFont = FontFamily(Font(Res.font.NotoColorEmoji_Regular)) // Replace with the actual emoji font resource if needed.

    val emojiRegex = Regex("[\\uD83C-\\uDBFF\\uDC00-\\uDFFF]+") // Matches emojis
    val hindiRegex = Regex("[\\u0900-\\u097F\\u1CD0-\\u1CFF\\uA8E0-\\uA8FF]+") // Matches Hindi characters

    // Function to determine the font for each word
    fun getFontForWord(word: String): FontFamily {
        return when {
            word.contains(emojiRegex) -> emojiFont // Use emoji font if emojis are detected
            word.contains(hindiRegex) -> hindiFont  // Use Hindi font if Hindi characters are detected
            else -> englishFont // Default to English font
        }
    }

    // Function to create an AnnotatedString with proper font for each part of the text
    fun createStyledText(postDescription: String): AnnotatedString {
        val words = postDescription.split(" ")
        val annotatedString = buildAnnotatedString {
            words.forEachIndexed { index, word ->
                val fontFamily = getFontForWord(word)
                withStyle(style = SpanStyle(fontFamily = fontFamily)) {
                    append(word)
                }
                if (index < words.size - 1) append(" ") // Add space between words
            }
        }
        return annotatedString
    }

    val styledText = message.msg.let { createStyledText(it) } ?: AnnotatedString("")


    Row(
        modifier = modifier.fillMaxWidth().padding(horizontal = 8.dp, vertical = 4.dp),
        horizontalArrangement = Arrangement.End
    ) {
        Box(
            modifier = Modifier.background(
                primaryColor, shape = RoundedCornerShape(
                    topStart = CornerSize(percent = 8),
                    topEnd = CornerSize(percent = 8),
                    bottomStart = CornerSize(percent = 8),
                    bottomEnd = CornerSize(0.dp)
                )
            ).padding(horizontal = 12.dp, vertical = 8.dp)
        ) {
//            Text(
//                text = message.msg,
//                color = Color.White,
//                fontSize = 14.sp, // Adjust text size as needed
//                textAlign = TextAlign.Start,
//                modifier = Modifier.align(Alignment.Center)
//            )


            Text(
                text = styledText,
                color = Color.White,
                fontSize = 14.sp, // Adjust text size as needed
                textAlign = TextAlign.Start,
                modifier = Modifier.align(Alignment.Center)
            )

        }
        // Add any other UI components related to the user message
    }
}


@Composable
fun ExpertIndicator() {
    Column(
        modifier = Modifier.padding(horizontal = 8.dp)
    ) {
        Spacer(modifier = Modifier.height(8.dp))
        HorizontalDivider(thickness = 1.dp, color = grey_700.copy(alpha = 0.4F))
        Spacer(modifier = Modifier.height(8.dp))
        Text(
            text = "Expert Ended the Chat", fontSize = 12.sp, textAlign = TextAlign.Center
        )
    }
}

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun ChatInputLayout(
    modifier: Modifier = Modifier,
    onSendChat: (String) -> Unit,
    onTyping: (Boolean) -> Unit
) {


    var message by remember { mutableStateOf("") }
    var isTyping by remember { mutableStateOf(false) }
    val scrollState = rememberScrollState()

    val hindiFont = FontFamily(Font(Res.font.NotoSansDevanagari_Regular))
    val englishFont = FontFamily(Font(Res.font.roboto_regular))
    val emojiFont = FontFamily(Font(Res.font.NotoColorEmoji_Regular)) // Replace with the actual emoji font resource if needed.

    val emojiRegex = Regex("[\\uD83C-\\uDBFF\\uDC00-\\uDFFF]+") // Matches emojis
    val hindiRegex = Regex("[\\u0900-\\u097F\\u1CD0-\\u1CFF\\uA8E0-\\uA8FF]+") // Matches Hindi characters

    // Function to determine the font for each word
    fun getFontForWord(word: String): FontFamily {
        return when {
            word.contains(emojiRegex) -> emojiFont // Use emoji font if emojis are detected
            word.contains(hindiRegex) -> hindiFont  // Use Hindi font if Hindi characters are detected
            else -> englishFont // Default to English font
        }
    }

    // Function to create an AnnotatedString with proper font for each part of the text
    fun createStyledText(postDescription: String): AnnotatedString {
        val words = postDescription.split(" ")
        val annotatedString = buildAnnotatedString {
            words.forEachIndexed { index, word ->
                val fontFamily = getFontForWord(word)
                withStyle(style = SpanStyle(fontFamily = fontFamily)) {
                    append(word)
                }
                if (index < words.size - 1) append(" ") // Add space between words
            }
        }
        return annotatedString
    }

    val styledText = message.let { createStyledText(it) } ?: AnnotatedString("")

    // Detect typing and stopping behavior
    LaunchedEffect(message) {
        if (message.isNotBlank()) {
            if (!isTyping) {
                isTyping = true
                onTyping(true)
            }
            // Wait for user to stop typing (1s delay)
            kotlinx.coroutines.delay(1000L)
            if (message.isNotBlank()) {
                isTyping = false
                onTyping(false)
            }
        } else {
            isTyping = false
            onTyping(false)
        }
    }

    Row(
        modifier = modifier,
        verticalAlignment = Alignment.CenterVertically
    ) {
        Box(
            modifier = Modifier
                .weight(1f)
                .heightIn(min = 50.dp, max = 200.dp)
                .verticalScroll(scrollState)
        ) {


//            TextField(
//                value = styledText.toString(),
//                //value = message,
//                onValueChange = { newMessage ->
//                    message = newMessage
//                },
//                modifier = Modifier
//                    .fillMaxWidth()
//                    .padding(end = 8.dp)
//                    .clipToBounds(),
//                textStyle = MaterialTheme.typography.bodyLarge,
//                placeholder = { Text("Type your message...") },
//                maxLines = Int.MAX_VALUE,
//                singleLine = false,
//                shape = RoundedCornerShape(8.dp),
//                colors = TextFieldDefaults.textFieldColors(
//                    containerColor = Color.White,
//                    cursorColor = MaterialTheme.colorScheme.primary,
//                    focusedIndicatorColor = Color.Transparent,
//                    unfocusedIndicatorColor = Color.Transparent
//                ),
//                keyboardOptions = KeyboardOptions.Default.copy(imeAction = ImeAction.Default),
//                keyboardActions = KeyboardActions(onAny = {
//                    if (message.isNotBlank()) {
//                        onSendChat(message.trim())
//                        message = ""
//                    }
//                }),
//                leadingIcon = {
//                    Icon(
//                        imageVector = Icons.Default.Chat,
//                        contentDescription = null,
//                        modifier = Modifier
//                            .padding(start = 8.dp, end = 4.dp)
//                            .size(24.dp),
//                        tint = MaterialTheme.colorScheme.onSurfaceVariant
//                    )
//                }
//            )

            val interactionSource = remember { MutableInteractionSource() } // Tracks interactions

            TextField(
                value = message,
                onValueChange = { newMessage ->
                    // Update the state directly for smooth cursor handling
                    message = newMessage
                },
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(end = 8.dp)
                    .clipToBounds(),
                textStyle = TextStyle(
                    fontFamily = getFontForWord(message), // Dynamically set the font for the entire TextField
                    fontSize = MaterialTheme.typography.bodyLarge.fontSize
                ),
               // textStyle = MaterialTheme.typography.bodyLarge,
                placeholder = {
                    Text(
                        text = "Type your message...",
                        style = TextStyle(fontFamily = englishFont) // Placeholder with default font
                    )
                },
                maxLines = Int.MAX_VALUE,
                singleLine = false,
                shape = RoundedCornerShape(8.dp),
                colors = TextFieldDefaults.textFieldColors(
                    containerColor = Color.White,
                    cursorColor = MaterialTheme.colorScheme.primary,
                    focusedIndicatorColor = Color.Transparent,
                    unfocusedIndicatorColor = Color.Transparent
                ),
                keyboardOptions = KeyboardOptions.Default.copy(
                    imeAction = ImeAction.Default,
                    keyboardType = KeyboardType.Text
                ),
                keyboardActions = KeyboardActions(onAny = {
                    if (message.isNotBlank()) {
                        onSendChat(message.trim())
                        message = "" // Clear the message after sending
                    }
                }),
                interactionSource = interactionSource, // Enable better control over interactions
                leadingIcon = {
                    Icon(
                        imageVector = Icons.Default.Chat,
                        contentDescription = null,
                        modifier = Modifier
                            .padding(start = 8.dp, end = 4.dp)
                            .size(24.dp),
                        tint = MaterialTheme.colorScheme.onSurfaceVariant
                    )
                }
            )

        }

        Spacer(modifier = Modifier.width(12.dp))

        // Send Button
        CommonButton(
            buttonText = "Send",
            modifier = Modifier,
            onClick = {
                val finalMsg = message.trim()
                if (finalMsg.isNotBlank()) {
                    onSendChat(finalMsg)
                    message = ""
                }
            },
            icon = Icons.Default.Send,
            isTextVisible = false
        )
    }
}


//@OptIn(ExperimentalMaterial3Api::class)
//@Composable
//fun ChatInputLayout(
//    modifier: Modifier,
//    onSendChat: (String) -> Unit,
//    onTyping: (Boolean) -> Unit // Callback to indicate if the user is typing or stopped
//) {
//    var message by remember { mutableStateOf("") }
//    var isTyping by remember { mutableStateOf(false) }
//
//    // A coroutine to detect when the user has stopped typing
//    LaunchedEffect(message) {
//        if (message.isNotEmpty()) {
//            // If the user is typing, invoke the typing callback
//            if (!isTyping) {
//                isTyping = true
//                onTyping(true)
//            }
//            // Delay to detect when the user stops typing
//            kotlinx.coroutines.delay(1000L) // 1 second delay
//            if (message.isNotEmpty()) {
//                isTyping = false
//                onTyping(false) // User stopped typing
//            }
//        } else {
//            isTyping = false
//            onTyping(false)
//        }
//    }
//
//    Row(
//        modifier = modifier, verticalAlignment = Alignment.CenterVertically
//    ) {
//
//        val scrollState = rememberScrollState()
//
//
//        TextField(
//            value = message,
//            onValueChange = { newMessage ->
//                message = newMessage
//            },
//            modifier = Modifier
//                .weight(1f)
//                .fillMaxWidth()
//                .heightIn(min = 50.dp, max = 200.dp) // Allows dynamic resizing within limits
//                .verticalScroll(scrollState) // Enable vertical scrolling
//                .padding(end = 8.dp) // Adjusts space around the text
//                .clipToBounds(), // Prevent overflow
//            textStyle = MaterialTheme.typography.titleSmall,
//            placeholder = { Text("Type your message...") },
//            maxLines = Int.MAX_VALUE, // Let it grow without limiting to fixed lines
//            singleLine = false,
//            shape = RoundedCornerShape(Scale.CORNER_SMALL),
//            colors = TextFieldDefaults.textFieldColors(
//                containerColor = Color.White,
//                cursorColor = primaryColor,
//                focusedIndicatorColor = Color.Transparent,
//                unfocusedIndicatorColor = Color.Transparent
//            ),
//            keyboardOptions = KeyboardOptions.Default.copy(imeAction = ImeAction.Default), // Avoids limitation of ImeAction in multiline
//            keyboardActions = KeyboardActions(onAny = {
//                val finalMsg = message.trim()
//                if (finalMsg.isNotEmpty()) {
//                    onSendChat.invoke(finalMsg)
//                }
//                message = ""
//            }),
//            leadingIcon = {
//                Icon(
//                    imageVector = Icons.AutoMirrored.Filled.Chat,
//                    contentDescription = null,
//                    tint = grey_700.copy(alpha = 0.4f),
//                    modifier = Modifier
//                        .padding(start = 8.dp, end = 4.dp) // Adjust icon padding
//                        .size(Scale.GLOBAL_ICON_SIZE)
//                )
//            }
//        )
//
//
//        Spacer(modifier = Modifier.width(12.dp))
//
//        CommonButton(
//            buttonText = "Send",
//            modifier = Modifier,
//            onClick = {
//                val finalMsg = message.trim()
//                if (finalMsg.isNotEmpty()) {
//                    onSendChat.invoke(finalMsg)
//                }
//                message = ""
//            },
//            icon = Icons.Default.Send,
//            isTextVisible = false
//        )
//
//    }
//}

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun MultilineTextField(
    modifier: Modifier = Modifier,
    initialMessage: String = "",
    onSendChat: (String) -> Unit
) {
    var message by remember { mutableStateOf(initialMessage) }
    val scrollState = rememberScrollState()

    Box(
        modifier = modifier
            .padding(8.dp)
            .heightIn(min = 50.dp, max = 200.dp)
            .verticalScroll(scrollState)
    ) {
        TextField(
            value = message,
            onValueChange = {
                message = it
            },
            modifier = Modifier
                .fillMaxWidth()
                .padding(end = 8.dp)
                .clipToBounds(),
            textStyle = MaterialTheme.typography.bodyLarge.copy(fontSize = 16.sp),
            placeholder = { Text("Type your message...") },
            maxLines = Int.MAX_VALUE, // Unlimited lines for auto-expansion
            singleLine = false,
            shape = RoundedCornerShape(8.dp),
            colors = TextFieldDefaults.textFieldColors(
                containerColor = Color.White,
                cursorColor = Color.Blue,
                focusedIndicatorColor = Color.Transparent,
                unfocusedIndicatorColor = Color.Transparent
            ),
            keyboardOptions = KeyboardOptions.Default.copy(imeAction = ImeAction.Done),
            keyboardActions = KeyboardActions(onDone = {
                val finalMsg = message.trim()
                if (finalMsg.isNotEmpty()) {
                    onSendChat(finalMsg)
                    message = ""
                }
            }),
            leadingIcon = {
                Icon(
                    imageVector = Icons.Default.Chat,
                    contentDescription = null,
                    modifier = Modifier
                        .padding(start = 8.dp, end = 4.dp)
                        .size(24.dp)
                )
            }
        )
    }
}


@Composable
fun BackHandler(backHandler: BackHandler, isEnabled: Boolean = true, onBack: () -> Unit) {
    val currentOnBack by rememberUpdatedState(onBack)

    val callback = remember {
        BackCallback(isEnabled = isEnabled) {
            currentOnBack()
        }
    }

    SideEffect { callback.isEnabled = isEnabled }

    DisposableEffect(backHandler) {
        backHandler.register(callback)
        onDispose { backHandler.unregister(callback) }
    }
}

//@Composable
//fun TypingIndicator(
//    modifier: Modifier
//) {
//    Row(
//        modifier = modifier.padding(vertical = 8.dp, horizontal = 8.dp).fillMaxWidth(),
//        verticalAlignment = Alignment.CenterVertically
//    ) {
//        CircularProgressIndicator(
//            modifier = Modifier.size(16.dp),
//            color = primaryColor.copy(alpha = 0.55F),
//            strokeWidth = 2.dp
//        )
//        Spacer(modifier = Modifier.width(8.dp))
//        Text(
//            text = "Typing...",
//        )
//    }
//}
