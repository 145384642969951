package tta.destinigo.talktoastro.feature_post.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class PostDataNew(
    @SerialName("consultant_email") val consultantEmail: String?, // dr.sanjayrout@gmail.com
    @SerialName("consultant_profile") val consultantProfile: String?, // https://live.winyway.com/images/UserImages/profileR0JTID05-51-52.jpg
    @SerialName("expert_category") val expertCategory: String?, // Astrologer
    @SerialName("expert_id") val expertId: Int?, // 20302
    @SerialName("expert_name") val expertName: String?, // Astro Sannjay
    @SerialName("post_content") val postContent: String?, // https://live.winyway.com/post_flies/1706025099.jpg
    @SerialName("post_content_type") val postContentType: String?, // image
    @SerialName("post_creation_date") val postCreationDate: String?, // 2024-01-23
    @SerialName("post_creation_time") val postCreationTime: String?, // 21:21:39
    @SerialName("post_description") val postDescription: String?, // Know your future through AstrologyGreetings everyone,As an astrologer, I implore you to take a moment to discover the power of astrology and its ability to transform your life. Astrology
    @SerialName("table_id") val tableId: Int?, // 676
    @SerialName("totalComments") val totalComments: String?, // 0
    @SerialName("totalLikes") var totalLikes: String?, // 0
    @SerialName("totalShares") val totalShares: String?,// 0
    @SerialName("is_liked_by_me") var _isLikedByMe: Int?, // 0
    @SerialName("post_by") val postBy: String?, // 0
    @SerialName("skills") val skills: String?, // 0
){

    fun isLikedByMe():Boolean = _isLikedByMe ==1
}
