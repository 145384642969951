package tta.destinigo.talktoastro.feature_common.persentation.more_tab.refer_and_earn.di

import org.koin.dsl.module
import tta.destinigo.talktoastro.core.shared.ShareService
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.refer_and_earn.data.ReferAndEarnApiService
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.refer_and_earn.domain.ReferAndEarnRepo


val ReferAndEarnModel = module {
    factory { ReferAndEarnApiService() }
    factory { ReferAndEarnRepo(get()) }
}


