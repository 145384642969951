package tta.destinigo.talktoastro.feature_common.persentation.more_tab.refer_and_earn.widget

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.draw.alpha
import androidx.compose.ui.draw.clip
import androidx.compose.ui.layout.ScaleFactor
import androidx.compose.ui.platform.ClipboardManager
import androidx.compose.ui.platform.LocalClipboardManager
import androidx.compose.ui.text.AnnotatedString
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.vectorResource
import tta.destinigo.talktoastro.core.extenstion.openWhatsAppAndRefer
import tta.destinigo.talktoastro.core.local.UserInfoProvider.getReferralCode
import tta.destinigo.talktoastro.core.persentation.shareLink
import tta.destinigo.talktoastro.core.persentation.showToast
import tta.destinigo.talktoastro.core.theme.Scale
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_COMPACT
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_EXPEND
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_MEDIUM
import tta.destinigo.talktoastro.core.theme.background_color
import tta.destinigo.talktoastro.core.theme.primaryColor
import tta.destinigo.talktoastro.core.theme.user_app_play_store_url
import tta.destinigo.talktoastro.core.theme.website_url
import tta.destinigo.talktoastro.feature_common.persentation.favourite_tab.TextWithImageAsButton
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.refer_and_earn.ReferAndEarnScreenComponent
import tta.destinigo.talktoastro.resources.Res
import tta.destinigo.talktoastro.resources.avatar_13
import tta.destinigo.talktoastro.resources.avatar_6
import tta.destinigo.talktoastro.resources.coin_balance
import tta.destinigo.talktoastro.resources.ic_copy_clipboard
import tta.destinigo.talktoastro.resources.salesiq_gold_medal
import tta.destinigo.talktoastro.resources.salesiq_silver_medal
import tta.destinigo.talktoastro.resources.share_post
import tta.destinigo.talktoastro.resources.sms_icon
import tta.destinigo.talktoastro.resources.whats_app_share

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun ShareCodeScreen(component: ReferAndEarnScreenComponent) {
    val windowSizeClass = calculateWindowSizeClass().widthSizeClass

    val widthModifier = when (windowSizeClass) {
        WindowWidthSizeClass.Compact -> WINDOW_WIDTH_SIZE_COMPACT
        WindowWidthSizeClass.Medium -> WINDOW_WIDTH_SIZE_MEDIUM
        WindowWidthSizeClass.Expanded -> WINDOW_WIDTH_SIZE_EXPEND
        else -> WINDOW_WIDTH_SIZE_COMPACT
    }

    val primaryDark = MaterialTheme.colorScheme.primary
    val blackOverlay10 = Color(0x1A000000) // Replace with your color resource
    val white = Color.White
    val image = component.referAndEarnImageState.collectAsState()
    Box(
        modifier = Modifier
            .fillMaxWidth()
            .background(primaryColor.copy(alpha = 0.7f))
            .padding(vertical = 16.dp, horizontal = 16.dp),
        contentAlignment = Alignment.Center
    ) {
        Column(modifier = widthModifier.fillMaxWidth()) {

            // Header LinearLayout replacement
            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    //.background(blackOverlay10)
                    .height(45.dp),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Center
            ) {
                Text(
                    text = "Share your code:",
                    color = white,
                    style = MaterialTheme.typography.bodyMedium
                )

                Spacer(modifier = Modifier.width(6.dp))

                Row(
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    val scope = rememberCoroutineScope()
                    val referralCode = getReferralCode()
                    val clipboardManager = LocalClipboardManager.current
                    Text(
                        text = referralCode, // Replace with dynamic text
                        color = white,
                        style = MaterialTheme.typography.bodyMedium,
                        fontWeight = FontWeight.Bold
                    )

                    Spacer(modifier = Modifier.width(6.dp))

                    Icon(
                        modifier = Modifier.clickable {
                            showToast("Copy Successful")
                            copyToClipboard(clipboardManager, referralCode)
                        },
                        painter = painterResource(Res.drawable.ic_copy_clipboard),
                        contentDescription = "Copy Icon",
                        tint = white
                    )
                }
            }

            Spacer(modifier = Modifier.height(16.dp))

            // Buttons Row
            Row(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.SpaceAround,
                verticalAlignment = Alignment.CenterVertically
            ) {
                IconButton(
                    onClick = {
                        showToast("Unexpected Error")
                    },
                    modifier = Modifier
                        .size(45.dp)
                        .background(
                            color = Color.White, // Replace with @drawable/square_box_primary_light_50 equivalent
                            shape = RoundedCornerShape(4.dp)
                        )
                        .padding(10.dp)
                ) {
                    Icon(
                        painter = painterResource(Res.drawable.sms_icon),
                        contentDescription = "SMS Share",
                        tint = primaryDark
                    )
                }

                IconButton(
                    onClick = {
                        //val img = "https://images.pexels.com/photos/244206/pexels-photo-244206.jpeg?cs=srgb&dl=lights-car-vehicle-244206.jpg&fm=jpg"
                       // openWhatsAppAndRefer(image = img, appUrl = user_app_play_store_url, website = website_url, referCode = getReferralCode())
                        if(image.value.isNotEmpty()){
                            openWhatsAppAndRefer(image = image.value, appUrl = user_app_play_store_url, website = website_url, referCode = getReferralCode()) }

                    },
                    modifier = Modifier
                        .size(45.dp)
                        .background(
                            color = Color.White, // Replace with @drawable/square_box_primary_light_50 equivalent
                            shape = RoundedCornerShape(4.dp)
                        )
                ) {
                    Image(
                        painter = painterResource(Res.drawable.whats_app_share),
                        contentDescription = "WhatsApp Share",
                        modifier = Modifier.fillMaxSize()
                    )
                }

                TextButton(
                    onClick = { shareLink(website_url) },
                    modifier = Modifier
                        .background(
                            color = Color.White,
                            shape = RoundedCornerShape(4.dp)
                        )
                        .padding(horizontal = 16.dp)
                        .height(45.dp),
                ) {
                    Icon(
                        painter = painterResource(Res.drawable.share_post),
                        contentDescription = "Share Icon",
                        tint = primaryDark
                    )

                    Spacer(modifier = Modifier.width(8.dp))

                    Text(
                        text = "Invite Friends",
                        color = primaryDark,
                        fontWeight = FontWeight.Bold
                    )
                }
            }
        }
    }
}


fun copyToClipboard(clipboardManager: ClipboardManager, code: String) {
    clipboardManager.setText(AnnotatedString(code))
}


