package tta.destinigo.talktoastro.feature_common.data.response.coin

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import tta.destinigo.talktoastro.feature_common.data.response.wallet_balance.Data


@Serializable
data class CoinDetailsResponse(
    @SerialName("code")
    val code: Int,
    @SerialName("data")
    val data:CoinDetailData?,
    @SerialName("message")
    val message: String,
    @SerialName("status")
    val status: Boolean
)


@Serializable
data class CoinDetailData(
    @SerialName("total_earnings_coin")
    val totalCoin:Int,
    @SerialName("total_balance")
    val totalBalance:Int,
    @SerialName("redeem_percentage")
    val redeemPercentage:Int
)
