package tta.destinigo.talktoastro.feature_post.data.post_api_service

import io.ktor.client.call.body
import io.ktor.client.plugins.ClientRequestException
import io.ktor.client.plugins.ServerResponseException
import io.ktor.client.request.get
import io.ktor.client.request.parameter
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.request.url
import io.ktor.http.headers
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext
import tta.destinigo.talktoastro.core.data.CommonResponse
import tta.destinigo.talktoastro.core.remote.KtorApi
import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.feature_common.data.remote.HomeApiService
import tta.destinigo.talktoastro.feature_common.data.remote.HomeApiService.Companion
import tta.destinigo.talktoastro.feature_post.data.PostPagingResponse
import tta.destinigo.talktoastro.feature_post.data.request.GetAllCommentRequest
import tta.destinigo.talktoastro.feature_post.data.request.LikeRequest
import tta.destinigo.talktoastro.feature_post.data.request.PostRequest
import tta.destinigo.talktoastro.feature_post.data.request.SubmitCommentResponse
import tta.destinigo.talktoastro.feature_post.data.response.Comment
import tta.destinigo.talktoastro.feature_post.data.response.CommentResponse
import tta.destinigo.talktoastro.feature_post.data.response.CommentSubmitResponse
import tta.destinigo.talktoastro.feature_post.data.response.PostDetailsResponse
import tta.destinigo.talktoastro.feature_post.data.response.PostDetailsResponseData
import tta.destinigo.talktoastro.feature_post.data.response.PostLikeData
import tta.destinigo.talktoastro.feature_post.data.response.PostLikeResponse
import tta.destinigo.talktoastro.feature_post.data.response.PostPagingData

class PostApiService : KtorApi() {

    companion object {
        const val GET_ALL_POST = "getallpost"
        const val GET_POST_LIKE = "post-like"
        const val GET_POST_DETAILS = "post-details"
        const val GET_ALL_COMMENT = "getallcomment"
        const val USER_COMMENT = "usercomment"
        const val SET_POST_LIKE = "post-share"
    }

    private suspend fun getAllPost(request: PostRequest) = client.get {
        url(GET_ALL_POST)
        parameter("page", request.page)
        headers{
            append("Accept", "application/json")
        }
    }.body<PostPagingResponse>()


    private suspend fun addLickRequest(request: LikeRequest) = client.post {
        url(GET_POST_LIKE)
        setBody(request)
    }.body<PostLikeResponse>()

    private suspend fun getPostDetailsApi(id:String) = client.post {
        url(GET_POST_DETAILS)
        parameter("post_id",id)
    }.body<PostDetailsResponse>()


    private suspend fun getAllCommentApi(request: GetAllCommentRequest) = client.post {
        url(GET_ALL_COMMENT)
        setBody(request)
    }.body<CommentResponse>()


    private suspend fun submitCommentApi(request: SubmitCommentResponse) = client.post {
        url(USER_COMMENT)
        setBody(request)
    }.body<CommentSubmitResponse>()


    // @POST(ConstantsHelper.GET_ALL_COMMENT)
    //    suspend fun addShare(
    //        @Query("post_id")postId: String
    //    ): Response<CommentResponse>


    private suspend fun addShare(request: GetAllCommentRequest) = client.post {
        url(SET_POST_LIKE)
        setBody(request)
    }.body<PostLikeResponse>()

    suspend fun requestGetAllPost(request: PostRequest): Resources<PostPagingData> {
        return withContext(Dispatchers.Default) {
            try {
                val response = getAllPost(request)
                if (!response.status.equals("success", true)) {
                    return@withContext Resources.Error(
                        response.status ?: "Unexpected Error"
                    )
                }
                return@withContext Resources.Success(response.postPagingData)
            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }

    suspend fun addLick(request: LikeRequest) : Resources<PostLikeData> {
        return withContext(Dispatchers.Default) {
            try {
                val response = addLickRequest(request)
                if (response.status.not()) {
                    return@withContext Resources.Error(
                        "Unexpected Error"
                    )
                }
                return@withContext Resources.Success(response.data)
            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }

    suspend fun getPostDetails(id:String) : Resources<PostDetailsResponseData> {
        return withContext(Dispatchers.Default){
            try{
                val response = getPostDetailsApi(id)
                if (response.status.not()) {
                    return@withContext Resources.Error(
                        "Unexpected Error"
                    )
                }
                return@withContext Resources.Success(response.data)
            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }


    suspend fun getAllComment(id: String) : Resources<List<Comment>>{
        return withContext(Dispatchers.Default){
            try{
                val response = getAllCommentApi(GetAllCommentRequest(postId = id))
                if (!response.status.equals("success",true)) {
                    return@withContext Resources.Error(
                        response.status ?: "Unexpected Error"
                    )
                }
                return@withContext Resources.Success(response.comments)
            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }


    suspend fun submitComment(request: SubmitCommentResponse) : Resources<Boolean>{
        return withContext(Dispatchers.Default){
            try{
                val response = submitCommentApi(request)
                if (!response.status.equals("success",true)) {
                    return@withContext Resources.Error(
                        "Unexpected Error"
                    )
                }
                return@withContext Resources.Success(true)
            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }

    suspend fun submitShare(request: GetAllCommentRequest) : Resources<PostLikeData> {
        return withContext(Dispatchers.Default) {
            try {
                val response = addShare(request)
                if (response.status.not()) {
                    return@withContext Resources.Error(
                        "Unexpected Error"
                    )
                }
                return@withContext Resources.Success(response.data)
            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }

}