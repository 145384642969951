package tta.destinigo.talktoastro.feature_post.data.request

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@Serializable
data class SubmitCommentResponse(
    @SerialName("post_id") val postId: String,
    @SerialName("comment") val comment: String,
    @SerialName("user_id") val userId: String,
    @SerialName("user_name") val userName: String,
    @SerialName("comment_date") val commentDate: String,
    @SerialName("comment_time") val commentTime: String,
)
